import React from 'react';
import { connect } from 'react-redux';
import store, * as storeOps from 'src/utils/store';
import ui from 'src/utils/ui';
import Card from 'src/widgets/Card';

import api2 from '@/utils/api2';
import theme from '@/utils/theme';

import { NewContractButton } from './contract/NewContractButton';

class IndexPage extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      merchantName: null,
      name: null,
      cell: null,
      mail: null,
      count: 1,
      toSignCount: 0,
      riskPlan: null,
      contractPlan: null,
    };
  }
  async init() {
    try {
      const result = await api2.get('cloud-svr/back/merchant/getCurMerchantAccount');
      store.dispatch(storeOps.loadMerchantAccount(result.data.merchantAccount));
      this.setState({
        merchantName: result.data.merchantAccount?.merchantName,
        name: result.data.merchantAccount?.name,
        cell: result.data.merchantAccount?.cell,
        mail: result.data.merchantAccount?.mail,
      });
      const toSignCount = (await api2.get('cloud-svr/back/contract/queryToSignContractCount'))?.data
        ?.res;
      this.setState({ toSignCount: toSignCount || 0 });
      const riskPlan = (await api2.get('cloud-svr/back/riskQuery/queryRiskPlanDetail'))?.data?.res;
      const contractPlan = (await api2.get('cloud-svr/back/contract/queryContractPlanDetail'))?.data
        ?.res;
      this.setState({
        riskPlan,
        contractPlan,
      });
    } catch (ex) {
      console.warn('AuthenticationPage.checkRiskQueryCode:', ex);
      ui.toastError(ex);
      if (ex.message === '未登录') {
        this.props.history.replace('/m/Login');
      }
    }
  }

  componentDidMount() {
    if (!store.getState().persisted.token) {
      this.props.history.replace('/m/Login');
      return;
    }
    this.init();
  }

  render() {
    const { merchantName, name, cell, mail } = this.state;
    const u = navigator.userAgent;
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    return (
      <div
        style={{
          margin: '0 auto',
          maxWidth: 600,
          minHeight: isIOS ? document.body.clientHeight : '100vh',
          background: 'rgba(68,120,245,0.05)',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
        onScroll={() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }}
      >
        <div
          style={{
            ...theme.fonts.medium(17),
            color: 'white',
            height: 134,
            display: 'flex',
            padding: '0 15px',
            background: '#4377F5',
          }}
        >
          <div style={{ marginTop: 68 }}>{merchantName}</div>
        </div>
        <div
          style={{
            position: 'absolute',
            top: 113,
            left: 15,
            right: 15,
          }}
        >
          <Card
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '20px 15px',
              margin: 0,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ ...theme.fonts.medium(15) }}>{name}</div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  color: '#4377F5',
                }}
                onClick={() => {
                  store.dispatch(storeOps.logout());
                  this.props.history.replace('/m/Login');
                }}
              >
                <div style={{ ...theme.fonts.medium(13), marginRight: 8 }}>退出登录</div>
                <div
                  style={{
                    width: 4,
                    height: 7,
                    backgroundColor: '#4377F5',
                    WebkitMask: `url(${require('src/images/icon_chevron_right.svg')}) no-repeat center`,
                    mask: `url(${require('src/images/icon_chevron_right.svg')}) no-repeat center`,
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 14,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',

                  alignItems: 'center',
                }}
              >
                <img
                  style={{ width: 8, height: 8 }}
                  alt=""
                  src={require('src/images/icon_cellphone@2x.png')}
                />

                <div
                  style={{ ...theme.fonts.regular(12), marginLeft: 4, color: 'rgba(0,0,0,0.6)' }}
                >
                  {cell}
                </div>
              </div>
              {mail && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',

                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{ width: 8, height: 8 }}
                    alt=""
                    src={require('src/images/icon_email@2x.png')}
                  />

                  <div
                    style={{ ...theme.fonts.regular(12), marginLeft: 4, color: 'rgba(0,0,0,0.6)' }}
                  >
                    {mail}
                  </div>
                </div>
              )}
            </div>
          </Card>
          <Card
            style={{
              padding: '20px 15px',
              margin: '13px 0 0 0',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ ...theme.fonts.medium(15), color: 'rgba(0,0,0,0.45)' }}>风控管理</div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 12,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  if (!this.state.riskPlan) {
                    ui.toastError('该功能需先进行主体资质认证，请联系对接人提交相关资质信息');
                    return;
                  }
                  this.toRiskQuery();
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 106,
                    height: 60,
                    borderRadius: 8,
                    color: 'rgba(68,120,245)',
                    backgroundColor: 'rgba(68,120,245,0.1)',
                    ...theme.fonts.medium(15),
                  }}
                >
                  风控查询
                </div>
              </div>
              <div
                style={{
                  marginLeft: 13,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 106,
                  height: 60,
                  borderRadius: 8,
                  color: 'rgba(68,120,245)',
                  backgroundColor: 'rgba(68,120,245,0.1)',
                  ...theme.fonts.medium(15),
                }}
                onClick={() => {
                  if (!this.state.riskPlan) {
                    ui.toastError('该功能需先进行主体资质认证，请联系对接人提交相关资质信息');
                    return;
                  }
                  this.toRiskQueryRecord();
                }}
              >
                风控记录
              </div>
            </div>
          </Card>
          <Card
            style={{
              margin: '13px 0 0 0',
              padding: '20px 15px 20px 15px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ ...theme.fonts.medium(15), color: 'rgba(0,0,0,0.45)' }}>电子合同</div>
              <NewContractButton className="flex-row align-center">
                <div style={{ marginRight: 8, ...theme.fonts.medium(13), color: '#4377F5' }}>
                  新增合同
                </div>
                <div
                  style={{
                    width: 4,
                    height: 7,
                    backgroundColor: '#4377F5',
                    WebkitMask: `url(${require('src/images/icon_chevron_right.svg')}) no-repeat center`,
                    mask: `url(${require('src/images/icon_chevron_right.svg')}) no-repeat center`,
                  }}
                />
              </NewContractButton>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 12,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}
                onClick={() => {
                  if (!this.state.contractPlan) {
                    ui.toastError('该功能需先进行主体资质认证，请联系对接人提交相关资质信息');
                    return;
                  }
                  store.dispatch(
                    storeOps.setContractRecord({
                      status: 'TO_SIGN',
                    }),
                  );
                  this.toContractRecord();
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 106,
                    height: 60,
                    borderRadius: 8,
                    color: 'rgba(68,120,245)',
                    backgroundColor: 'rgba(68,120,245,0.1)',
                    ...theme.fonts.medium(15),
                  }}
                >
                  待签署合同
                </div>
                {this.state.toSignCount > 0 && (
                  <div
                    className="flex-row justify-center align-center"
                    style={{
                      ...theme.fonts.medium(10),
                      color: '#FFFFFF',
                      position: 'absolute',
                      background: '#FF0000',
                      width:
                        this.state.toSignCount < 10 ? 20 : this.state.toSignCount > 99 ? 26 : 23,
                      height: 20,
                      borderRadius: 10,
                      top: -4,
                      right: 4,
                    }}
                  >
                    {this.state.toSignCount > 99 ? '99+' : this.state.toSignCount}
                  </div>
                )}
              </div>
              <div
                style={{
                  marginLeft: 13,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 106,
                  height: 60,
                  borderRadius: 8,
                  color: 'rgba(68,120,245)',
                  backgroundColor: 'rgba(68,120,245,0.1)',
                  ...theme.fonts.medium(15),
                }}
                onClick={() => {
                  if (!this.state.contractPlan) {
                    ui.toastError('该功能需先进行主体资质认证，请联系对接人提交相关资质信息');
                    return;
                  }
                  store.dispatch(
                    storeOps.setContractRecord({
                      status: 'ALL',
                    }),
                  );
                  this.toContractRecord();
                }}
              >
                签署记录
              </div>
            </div>
          </Card>
        </div>
        <div style={{ height: 350 }} />
        <div style={{ flex: 1 }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '20px 42px 20px 42px',
            margin: '0 auto',
          }}
        >
          <img
            alt=""
            src={require('src/images/bottom.png')}
            style={{ width: '100%', height: 'auto', maxWidth: 400, display: 'block' }}
          />
        </div>
      </div>
    );
  }

  toRiskQuery() {
    this.props.history.push('/m/RiskQuery');
  }
  toRiskQueryRecord() {
    this.props.history.push('/m/RiskQueryRecord');
  }

  toContractRecord() {
    this.props.history.push('/m/ContractRecord');
  }
}

export default connect((state) => ({
  token: state.persisted.token,
}))(IndexPage);
