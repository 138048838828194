import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import QueryString from 'qs';
import config from 'src/etc/config';
import constants from 'src/utils/constants';
import store from 'src/utils/store';

import format from '@/wagons-clientlib/format';

let api = axios.create({
  baseURL: `${config.serverEndpoint}/base/customerApp`,
  timeout: 30000,
  headers: {
    Accept: 'application/json; charset=UTF-8',
  },
  // 解决 axios 默认不编码中括号，导致 spring 报 400 的问题
  paramsSerializer(params) {
    return format.query(params, { arrayFormat: 'brackets' });
  },
});

api.interceptors.request.use((request) => {
  request.params = {
    token: store.getState().persisted.token,
    ...request.params,
  };
  return request;
});

if (process.env.DEBUG_API) {
  api.interceptors.request.use((request) => {
    // 其实 request 不需要输出的，response 里面已经带了相关信息了
    // console.log('axios request:', request);
    return request;
  });
  api.interceptors.response.use((response) => {
    console.log('axios response:', response);
    return response;
  });
}

const addressList = [
  {
    id: 1,
    province: '广东省',
    city: '深圳市',
    district: '南山区',
    address: '科技园7号',
    name: '张三',
    cell: '13800138000',
    isDefault: true,
  },
  {
    id: 2,
    province: '江苏省',
    city: '南京市',
    district: '鼓楼区',
    address: '中山北路7号',
    name: '李四',
    cell: '13800138001',
    isDefault: false,
  },
];

const mock = new MockAdapter(api, { delayResponse: 1000 });
mock.onGet('/mock/customer/getAddressList').reply(200, { success: true, data: { addressList } });
mock.onGet('/mock/customer/getAddress').reply((config) => [
  200,
  {
    success: true,
    data: {
      address: addressList.find((a) =>
        config.params.addressId ? a.id == config.params.addressId : a.isDefault,
      ),
    },
  },
]);
mock.onPost('/mock/customer/saveAddress').reply((config) => {
  const address = JSON.parse(QueryString.parse(config.data).addressStr);
  const index = addressList.findIndex((a) => a.id == address.id);
  if (index >= 0) {
    addressList[index] = address;
  } else {
    addressList.push(address);
  }
  console.log('addressList:', config, addressList);
  return [200, { success: true }];
});
mock.onAny().passThrough();

export default api;
