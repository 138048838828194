import classNames from 'classnames';
import React from 'react';

export default function Card({ onClick, disabled, className, style, children }) {
  return (
    <div
      onClick={!disabled && onClick}
      className={className}
      style={{
        display: 'flex',
        position: 'relative',
        margin: '10px 10px 0 10px',
        // boxShadow: '0 2px 10px rgba(51, 51, 51, 0.12)',
        borderRadius: 8,
        background: 'white',
        padding: 10,
        ...style,
      }}
    >
      {children}
    </div>
  );
}

export function CardApp({ onClick, disabled, className, style, children }) {
  return (
    <div
      onClick={!disabled && onClick}
      className={classNames('flex-column', className)}
      style={{
        display: 'flex',
        position: 'relative',
        margin: '10px 15px 0 15px',
        borderRadius: 8,
        background: 'white',
        padding: 15,
        ...style,
      }}
    >
      {children}
    </div>
  );
}
