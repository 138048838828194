import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ui from 'src/utils/ui';

export function PageTitle({ title }) {
  useEffect(() => {
    switch (ui.env()) {
      case 'frontapp':
        setTimeout(() => {
          window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'title', title }));
        }, 500);
        break;
    }
  }, [title]);
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
}
