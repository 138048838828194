import { useEffect, useRef } from 'react';

/** 抄自这里： https://usehooks.com/usePrevious/ */
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function usePreviousNotNull(value) {
  const ref = useRef();
  useEffect(() => {
    if (value != null) {
      ref.current = value;
    }
  }, [value]);
  return ref.current;
}
