import moment from 'moment';

const CHINESE_NUMBERS = '零一二三四五六七八九';
const CHINESE_WEEKDAYS = '日一二三四五六日';

export default {
  date: time => (time ? moment(time).format('YYYY-MM-DD') : null),
  time: time => (time ? moment(time).format('YYYY-MM-DD HH:mm') : null),
  timeFull: time => (time ? moment(time).format('YYYY-MM-DD HH:mm:ss') : null),
  week: time => (time ? CHINESE_WEEKDAYS[moment(time).day()] : null),

  chineseNumber(num) {
    return CHINESE_NUMBERS[num];
  },

  chineseWeekday(num) {
    return CHINESE_WEEKDAYS[num];
  },

  httpImage(url) {
    return url ? url.replace(/^http:/, 'https:') : null;
  },

  orderStatus(statusDesc) {
    switch (statusDesc) {
      case '已支付':
      case '确认中':
      case '整备中':
        return '等待用车';
      case '同行订单确认中':
      case '意向订单':
        return '接单确认中';
      case '同行订单待支付':
        return '待支付';
      default:
        return statusDesc;
    }
  },
};
