import config from 'src/etc/config';
import api from 'src/utils/api';
import format from 'src/wagons-clientlib/format';

const api2 = {};

api2.request = async (method, url, params, options) => {
  const beginTime = new Date().valueOf();

  try {
    const strippedParams = format.stripObject(params);

    const axiosConfig = {
      // 之前的代码都是基于「服务端只有一个 `/base` 服务」的前提；
      // 以后会有其他服务端点了，这些新的服务端点都要记得加到这个判断里面。
      ...(/^\/?(auth|base|hotel|search-vehicle|product|cloud-svr)\//.test(url)
        ? { baseURL: config.serverEndpoint.replace(/\/base\/customerApp$/, '') }
        : {}),
      ...options?.axios,
    };

    const response = (() => {
      switch (method) {
        case 'post':
          return api.post(
            url,
            params instanceof FormData
              ? params
              : format.query(
                  options?.jsonRequest
                    ? { paramsStr: JSON.stringify(strippedParams) }
                    : strippedParams,
                ),
            axiosConfig,
          );

        default:
          return api[method](url, {
            params: options?.jsonRequest
              ? { paramsStr: JSON.stringify(strippedParams) }
              : strippedParams,
            ...axiosConfig,
          });
      }
    })();
    const result = (await response).data;

    if (options?.log !== false) {
      console.log(method.toUpperCase(), url, new Date().valueOf() - beginTime, params, result);
    }

    if (!/\/oss\//.test(url) && !result?.success) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    console.warn(
      method.toUpperCase(),
      url,
      new Date().valueOf() - beginTime,
      params,
      error.response,
      error,
    );
    if (error.response?.data?.message) throw new Error(error.response.data.message);
    throw error;
  }
};

api2.get = async (url, params, options) => {
  return api2.request('get', url, params, options);
};

api2.getByJson = async (url, params, options) => {
  return api2.request('get', url, params, { ...options, jsonRequest: true });
};

api2.post = async (url, params, options) => {
  return api2.request('post', url, params, options);
};

api2.postByJson = async (url, params, options) => {
  return api2.request('post', url, params, { ...options, jsonRequest: true });
};

export default api2;
