import 'antd/dist/antd.css';

import { Upload } from 'antd';
import colorAlpha from 'color-alpha';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useBeforeunload } from 'react-beforeunload';
// import { Prompt } from 'react-router-dom';
import useConstant from 'use-constant';
import { proxy, ref } from 'valtio';

import CommonModal from '@/components/common/CommonModal';
import api2 from '@/utils/api2';
import { useElementSize } from '@/utils/hooks';
import theme from '@/utils/theme';
import ui, { useQueryParams } from '@/utils/ui';
import { useData_contract_getContractStatus } from '@/wagons-clientlib/data/front/cloud/contract';
import format from '@/wagons-clientlib/format';
import { usePrevious } from '@/wagons-clientlib/hooks';
import utils, { useLocalStoreContext } from '@/wagons-clientlib/utils';
import AssetImage from '@/wagons-clientlib/widgets/AssetImage';
import MyHeader from '@/widgets/MyHeader';
import { PageLoading } from '@/widgets/PageLoading';
import { PageTitle } from '@/widgets/PageTitle';
import { PdfViewer } from '@/widgets/PdfViewer';

const LocalStoreContext = React.createContext({});

class LocalStore {
  navprops = {};

  contract = {};
  contractSigner = {};
  contractPdfUrl = null;

  isLoading = true;
  isUploading = false;
  isSubmitting = false;
  commonModal = null;

  isGoingBackByCode = false;

  async init() {
    try {
      if (!this.navprops.contractId) {
        this.contract = {
          contractStatus: 'NEW', // NEW 是前端加的一个状态，表示新建合同页面
          contractSignerId: this.navprops.contractSignerId,
        };
        this.contractSigner = (
          await api2.get('/cloud-svr/back/contract/getContractSignerModel', {
            contractSignerId: this.contract.contractSignerId || this.navprops.contractSignerId,
          })
        ).data.res;
        return;
      }

      this.contract = (
        await api2.get('/cloud-svr/back/contract/getContractDetailModel', {
          contractId: this.navprops.contractId,
        })
      ).data.res;
      this.contractSigner = (
        await api2.get('/cloud-svr/back/contract/getContractSignerModel', {
          contractSignerId: this.contract.contractSignerId || this.navprops.contractSignerId,
        })
      ).data.res;

      if (this.contract.contractStatus === 'SIGNED') {
        this.contractPdfUrl = (
          await api2.get('/cloud-svr/back/contract/getContractSignedFile', {
            contractId: this.navprops.contractId,
          })
        ).data.res;
      } else if (!['NEW', 'TO_GENERATE'].includes(this.contract.contractStatus)) {
        this.contractPdfUrl = (
          await api2.get('/cloud-svr/back/contract/getContractToSignFile', {
            contractId: this.navprops.contractId,
          })
        ).data.res;
      }
    } catch (ex) {
      console.warn('ContractEditorPage.init error:', ex);
      ui.toastError(ex);
    } finally {
      this.isLoading = false;
    }
  }

  /** 不会被 beforeUnload 阻止的后退接口 */
  goBack() {
    this.isGoingBackByCode = true;
    window.history.back();
  }

  async createToGenerateContract() {
    try {
      this.isSubmitting = true;

      await api2.post('/cloud-svr/back/contract/createToGenerateContract', {
        fileUrl: this.contract.originFile,
        fileName: this.contract.contractName,
        contractSignerId: this.navprops.contractSignerId,
      });

      this.goBack();
    } catch (ex) {
      console.warn('ContractEditorPage.createToGenerateContract error:', ex);
      ui.toastError(ex);
    } finally {
      this.isSubmitting = false;
    }
  }

  async generateContract() {
    try {
      this.isSubmitting = true;

      const result = await api2.post('/cloud-svr/back/contract/generateContract', {
        fileUrl: this.contract.originFile,
        fileName: this.contract.contractName,
        contractId: this.contract.contractId,
        contractSignerId: this.contract.contractSignerId || this.navprops.contractSignerId,
      });
      this.contract = result.data.res;

      window.history.replaceState(null, '', `/m/ContractEditor/${this.contract.contractId}`);
      window.location.reload();
    } catch (ex) {
      console.warn('ContractEditorPage.generateContract error:', ex);
      ui.toastError(ex);
    } finally {
      this.isSubmitting = false;
    }
  }

  async cancelContract() {
    try {
      this.isSubmitting = true;

      await api2.post('/cloud-svr/back/contract/cancelContract', {
        contractId: this.contract.contractId,
      });

      window.location.reload();
    } catch (ex) {
      console.warn('ContractEditorPage.cancelContract error:', ex);
      ui.toastError(ex);
    } finally {
      this.isSubmitting = false;
    }
  }

  async revokeContract() {
    try {
      this.isSubmitting = true;

      await api2.post('/cloud-svr/back/contract/revokeContract', {
        contractId: this.contract.contractId,
      });

      window.location.reload();
    } catch (ex) {
      console.warn('ContractEditorPage.revokeContract error:', ex);
      ui.toastError(ex);
    } finally {
      this.isSubmitting = false;
    }
  }

  async signA() {
    try {
      this.isSubmitting = true;

      const result = await api2.get('/cloud-svr/back/contract/getSignerAUrl', {
        contractId: this.contract.contractId,
      });
      window.location.href = result.data.res;
    } catch (ex) {
      console.warn('ContractEditorPage.signA error:', ex);
      ui.toastError(ex);
    } finally {
      this.isSubmitting = false;
    }
  }

  async signB() {
    try {
      this.isSubmitting = true;

      const result = await api2.get('/cloud-svr/back/contract/getSignerBUrlQrCode', {
        contractId: this.contract.contractId,
      });
      this.commonModal = {
        isVisible: true,
        title: '邀请乙方签署',
        content: ref(
          <Fragment>
            <div>签署方扫描二维码进行合同签署</div>
            <img
              alt=""
              src={result.data.res}
              style={{ marginBottom: 20, width: 160, height: 160 }}
            />
          </Fragment>,
        ),
        isButtonsVisible: false,
      };
    } catch (ex) {
      console.warn('ContractEditorPage.signB error:', ex);
      ui.toastError(ex);
    } finally {
      this.isSubmitting = false;
    }
  }
}

export function ContractEditorPage({ match }) {
  const navprops = match.params;
  const query = useQueryParams();

  const localStore = useConstant(() => proxy(new LocalStore()));

  const prevNavprops = usePrevious(navprops);
  const prevQuery = usePrevious(query);
  useEffect(() => {
    if (navprops !== prevNavprops || query !== prevQuery) {
      localStore.navprops = { ...navprops, ...query };
      localStore.init();
    }
  }, [navprops, prevNavprops, query, prevQuery, localStore]);

  return (
    <LocalStoreContext.Provider value={localStore}>
      <PageContent />
    </LocalStoreContext.Provider>
  );
}

function PageContent() {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);
  const { contract } = snap;

  if (snap.isLoading) return <PageLoading />;

  return (
    <div
      className="flex-column"
      style={{
        margin: '0 auto',
        maxWidth: 600,
        height: utils.isiOS() ? document.body.clientHeight : '100vh',
        background: 'white',
      }}
    >
      <Title />
      <BasicInfo />
      {contract.originFile ? <ContractViewer /> : <ContractUploader />}
      <ContractSigningArea />
      <BottomButtons />

      <CommonModal
        title="确认"
        button="确定"
        leftButton="取消"
        onClickLeftButton={() => (localStore.commonModal = null)}
        {...snap.commonModal}
        onClose={() => (localStore.commonModal = null)}
      />

      {!!snap.isSubmitting && <PageLoading image={require('@/images/loading.gif')} />}
    </div>
  );
}

function Title() {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);
  const { contract } = snap;

  const title = useMemo(
    () => (!contract.contractId ? '新增合同' : '查看合同'),
    [contract.contractId],
  );

  const onGoBack = useCallback(() => {
    if (!(contract.contractStatus === 'NEW' && contract.originFile)) {
      localStore.goBack();
      return;
    }

    localStore.commonModal = {
      isVisible: true,
      content: '是否将已上传的合同保存到系统？',
      onClick: () => localStore.createToGenerateContract(),
      onClickLeftButton: () => localStore.goBack(),
    };
  }, [contract.contractStatus, contract.originFile, localStore]);

  return (
    <Fragment>
      <PageTitle title={title} />

      <MyHeader title={title} onGoBack={onGoBack} />
    </Fragment>
  );
}

function BasicInfo() {
  const { contractStatusEnum } = useData_contract_getContractStatus();

  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);
  const { contract } = snap;

  const { statusColor, hint } = useMemo(() => {
    return (
      {
        NEW: { hint: '请上传完整内容的word版合同！' },
        TO_GENERATE: { statusColor: '#F7C84C', hint: '请上传完整内容的word版合同！' },
        TO_SIGN: { statusColor: '#F7C84C', hint: '若需重新上传合同请先操作「撤销合同」' },
        SIGNED: { statusColor: '#39A579' },
        REVOKED: {
          statusColor: '#F7C84C',
          hint: '上传已填写完且不含签署页的合同内容后可操作「生成合同」',
        },
        CANCELED: { statusColor: '#E32727' },
        FAILED: { statusColor: '#E32727' },
        REJECTED: { statusColor: '#E32727' },
      }[contract.contractStatus] || {}
    );
  }, [contract.contractStatus]);

  return (
    <Fragment>
      {!!contract.unFinishRemark && (
        <div
          className="flex-row justify-center align-center"
          style={{ height: 60, background: '#E32727', ...theme.fonts.medium(20), color: 'white' }}
        >
          {contract.unFinishRemark}
        </div>
      )}

      <div style={{ margin: '0 15px' }}>
        {!!contract.contractId && (
          <div
            className="flex-column"
            style={{ borderBottom: `0.3px solid ${colorAlpha('black', 0.3)}`, paddingBottom: 8 }}
          >
            <div
              className="flex-row align-center"
              style={{ marginTop: 8, justifyContent: 'space-between' }}
            >
              <div style={{ ...theme.fonts.medium(11), color: 'black' }}>
                创建时间：{format.time(contract.createdDate)}
              </div>

              {!!contract.signedTime && (
                <div style={{ ...theme.fonts.medium(11), color: 'black' }}>
                  签署完成：{format.time(contract.signedTime)}
                </div>
              )}
              {!!contract.canceledTime && (
                <div style={{ ...theme.fonts.medium(11), color: 'black' }}>
                  取消时间：{format.time(contract.canceledTime)}
                </div>
              )}
            </div>
          </div>
        )}

        <div
          className="flex-row align-center"
          style={{ marginTop: 12, justifyContent: 'space-between' }}
        >
          <div style={{ ...theme.fonts.medium(16), color: 'black' }}>合同内容</div>

          {!!contract.contractId && (
            <div
              className="flex-row justify-center align-center"
              style={{
                width: 80,
                height: 20,
                borderRadius: 1.33,
                background: statusColor,
                ...theme.fonts.regular(11),
                color: 'white',
              }}
            >
              {contractStatusEnum.map[contract.contractStatus]}
            </div>
          )}
        </div>

        {!!hint && (
          <div
            style={{ marginTop: 14, ...theme.fonts.medium(13), color: theme.colors.cloudPrimary }}
          >
            {hint}
          </div>
        )}
      </div>
    </Fragment>
  );
}

function ContractViewer() {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);
  const { contract } = snap;

  const viewedUrl = useMemo(
    () => snap.contractPdfUrl || contract.originFile,
    [snap.contractPdfUrl, contract.originFile],
  );

  const [ref, size] = useElementSize();

  useBeforeunload(
    !snap.isGoingBackByCode && contract.contractStatus === 'NEW'
      ? (e) => {
          e.preventDefault();
          e.returnValue = '是否将已上传的合同保存到系统？';
          return '是否将已上传的合同保存到系统？';
        }
      : null,
  );

  return (
    <Fragment>
      {/*
      这种方式目前有一个问题，就是当确认弹框弹出的时候，已上传的合同文件会被清空掉，导致该确认框变的毫无意义了
      <Prompt when={contract.contractStatus === 'NEW'} message="是否将已上传的合同保存到系统？" />
      */}

      <div className="flex-row align-center" style={{ margin: '16px 15px -4px 15px' }}>
        <AssetImage
          src={require('@/images/icon_word@3x.png')}
          scale={3}
          style={{ display: 'inline-block' }}
        />
        <div
          style={{
            flex: 1,
            marginRight: 12,
            ...theme.styles.textOneline,
            ...theme.fonts.medium(14),
            color: 'black',
          }}
        >
          《{contract.contractName}》
        </div>

        {['NEW', 'TO_GENERATE', 'REVOKED'].includes(contract.contractStatus) &&
          !!contract.originFile && (
            <Uploader style={{ flexDirection: 'row' }}>
              <button
                className="flex-row align-center"
                style={{ border: 'none', outline: 'none', background: 'transparent' }}
              >
                <div style={{ ...theme.fonts.medium(13), color: theme.colors.cloudPrimary }}>
                  重新上传
                </div>
                <div
                  style={{
                    display: 'inline-block',
                    marginLeft: 8,
                    width: 4,
                    height: 7,
                    backgroundColor: theme.colors.cloudPrimary,
                    WebkitMask: `url(${require('src/images/icon_chevron_right.svg')}) no-repeat center`,
                    mask: `url(${require('src/images/icon_chevron_right.svg')}) no-repeat center`,
                  }}
                />
              </button>
            </Uploader>
          )}
      </div>

      <div
        ref={ref}
        className="flex-column"
        style={{
          flex: 1,
          margin: '12px 15px 0 15px',
          borderRadius: 8,
          border: `1px solid ${colorAlpha('black', 0.05)}`,
          background: colorAlpha('black', 0.05),
          overflowX: 'hidden',
          overflowY: 'auto',
          scrollbarWidth: 'none',
        }}
      >
        {!/esign\.cn/.test(viewedUrl) ? (
          // FIX 同一页面多次上传后，后退按钮会只后退微软的 iframe
          !snap.isUploading && (
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?${format.query({
                src: viewedUrl,
              })}`}
              width={`${size?.width}px`}
              height={`${size?.height}px`}
              frameBorder="0"
            >
              这是嵌入{' '}
              <a href="https://office.com" target="_blank" rel="noreferrer noopener">
                Microsoft Office
              </a>{' '}
              文档，由{' '}
              <a href="https://office.com/webapps" target="_blank" rel="noreferrer noopener">
                Office
              </a>{' '}
              提供支持。
            </iframe>
          )
        ) : (
          <PdfViewer url={viewedUrl} />
        )}
      </div>
    </Fragment>
  );
}

function ContractUploader() {
  return (
    <div style={{ margin: '20px 15px 0 15px' }}>
      <Uploader>
        <button
          className="flex-column justify-center align-center"
          style={{
            width: '100%',
            height: 160,
            borderRadius: 8,
            border: 'none',
            outline: 'none',
            background: colorAlpha('black', 0.05),
            ...theme.fonts.medium(14),
            color: theme.colors.cloudPrimary,
          }}
        >
          <div className="flex-row align-center">
            <AssetImage
              src={require('@/images/icon_plus@3x.png')}
              scale={3}
              style={{ display: 'inline-block', marginRight: 8 }}
            />
            <div>上传合同</div>
          </div>
          <div style={{ marginTop: 12 }}>支持格式：word</div>
        </button>
      </Uploader>
    </div>
  );
}

function Uploader({ style, children }) {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);

  const uploadWordFile = useCallback(
    async (info) => {
      try {
        const { status } = info.file;
        if (status !== 'done') return;

        localStore.isUploading = true;

        const file = info.file.originFileObj;

        const formData = new FormData();
        formData.append('file', file);
        localStore.contract.originFile = (
          await api2.post('/cloud-svr/front/media/upload', formData, { timeout: 600000 })
        ).data.url;
        localStore.contract.contractName = file.name;
        localStore.contractPdfUrl = null;
      } catch (ex) {
        console.warn('ContractUploader.uploadWordFile error:', ex);
        ui.toastError(ex);
      } finally {
        localStore.isUploading = false;
      }
    },
    [localStore],
  );

  return (
    <Upload
      accept=".doc,.docx"
      onChange={uploadWordFile}
      action="/apiproxy/cloud-svr/front/media/upload"
      itemRender={() => null}
      className="flex-column"
      style={style}
    >
      {children}
    </Upload>
  );
}

function ContractSigningArea() {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);
  const { contract } = snap;

  return (
    <div
      className="flex-column"
      style={{
        margin: '13px 15px 0 15px',
        height: 80,
        borderRadius: 8,
        background: colorAlpha('black', 0.05),
      }}
    >
      <div
        className="flex-row justify-center align-center"
        style={{
          marginTop: 10,
          ...theme.fonts.medium(14),
          color: colorAlpha('black', 0.2),
        }}
      >
        合同签署页
      </div>

      <div
        className="flex-row"
        style={{
          position: 'absolute',
          bottom: 10,
          left: 30,
          right: 30,
          alignItems: 'baseline',
          ...theme.fonts.medium(14),
          color: 'black',
        }}
      >
        <div style={{ alignSelf: 'flex-end' }}>甲方：</div>
        <div style={{ display: 'inline-block', width: 'auto', height: 60 }}>
          {(contract.contractStatus === 'SIGNED' ||
            (contract.contractStatus === 'TO_SIGN' && !contract.canSignA)) && (
            <img
              alt=""
              src={contract.sealA}
              style={{ display: 'inline-block', width: 'auto', height: 60 }}
            />
          )}
        </div>

        <div style={{ flex: 1 }} />

        <div style={{ alignSelf: 'flex-end' }}>乙方：</div>
        <div style={{ display: 'inline-block', alignSelf: 'flex-end', minWidth: 40, height: 60 }}>
          {(contract.contractStatus === 'SIGNED' ||
            (contract.contractStatus === 'TO_SIGN' && !contract.canSignB)) && (
            <img
              alt=""
              src={contract.sealB}
              style={{ display: 'inline-block', minWidth: 40, width: 'auto', height: 60 }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function BottomButtons() {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);
  const { contract } = snap;

  return (
    <div className="flex-row align-center" style={{ margin: '20px 15px 16px 15px' }}>
      {contract.contractStatus === 'NEW' && <Button_GenerateContract />}
      {contract.contractStatus === 'TO_GENERATE' && <BottomButtons_ToGenerate />}
      {contract.contractStatus === 'TO_SIGN' && <BottomButtons_ToSign />}
      {contract.contractStatus === 'SIGNED' && <Button_DownloadContract />}
      {contract.contractStatus === 'REVOKED' && <BottomButtons_Revoked />}
    </div>
  );
}

function Button_GenerateContract({ title, style }) {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);
  const { contract, contractSigner } = snap;

  const submit = useCallback(() => {
    if (['NEW', 'REVOKED'].includes(contract.contractStatus)) {
      localStore.commonModal = {
        isVisible: true,
        style: { minWidth: '90vw' },
        content: ref(
          <div style={{}}>
            <div>本次合同生成将消耗套餐包，请务必上传完整合同并确认下方乙方信息正确无误！</div>

            {contractSigner.signerType === 'PERSONAL' && (
              <Fragment>
                <EditorField
                  title={
                    <Fragment>
                      姓<span style={{ color: 'transparent' }}>占位</span>名
                    </Fragment>
                  }
                  value={contractSigner.name}
                />
                <EditorField title="手机号码" value={contractSigner.cell} />
                <EditorField title="身份证号" value={contractSigner.idNumber} />
              </Fragment>
            )}

            {contractSigner.signerType === 'ORGANIZATION' && (
              <Fragment>
                <EditorField
                  title="公司名称"
                  value={contractSigner.orgName}
                  titleStyle={{ width: '5em' }}
                />
                <EditorField
                  title="公司税号"
                  value={contractSigner.uscc}
                  titleStyle={{ width: '5em' }}
                />
                <EditorField
                  title="签署人姓名"
                  value={contractSigner.name}
                  titleStyle={{ width: '5em' }}
                />
                <EditorField
                  title="手机号码"
                  value={contractSigner.cell}
                  titleStyle={{ width: '5em' }}
                />
                <EditorField
                  title="身份证号"
                  value={contractSigner.idNumber}
                  titleStyle={{ width: '5em' }}
                />
              </Fragment>
            )}
          </div>,
        ),
        contentStyle: { textAlign: 'left' },
        onClick: () => {
          localStore.commonModal = null;
          localStore.generateContract();
        },
      };
      return;
    }

    localStore.commonModal = {
      isVisible: true,
      content: '本次合同生成将消耗套餐包，请务必上传完整合同！',
      onClick: () => {
        localStore.commonModal = null;
        localStore.generateContract();
      },
    };
  }, [contract.contractStatus, contractSigner, localStore]);

  const isDisabled = useMemo(
    () => !contract.originFile || snap.isSubmitting,
    [contract.originFile, snap.isSubmitting],
  );

  return (
    <button
      onClick={submit}
      disabled={isDisabled}
      style={{
        margin: '0 auto',
        width: 330,
        height: 40,
        borderRadius: 88,
        border: 'none',
        outline: 'none',
        background: colorAlpha(theme.colors.cloudPrimary, !isDisabled ? 1 : 0.2),
        ...theme.fonts.regular(14),
        color: 'white',
        ...style,
      }}
    >
      {title || '生成合同'}
    </button>
  );
}

function BottomButtons_ToGenerate() {
  return (
    <Fragment>
      <Button_CancelContract />
      <div style={{ flex: 1 }} />
      <Button_GenerateContract style={{ margin: 'none', width: 108 }} />
    </Fragment>
  );
}

function Button_CancelContract() {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);
  const { contract } = snap;

  const submit = useCallback(() => {
    localStore.commonModal = {
      isVisible: true,
      content: {
        TO_GENERATE: '取消签署将无法保存已上传的合同内容',
        REVOKED: '确认后再次签署需重新上传生成新合同',
      }[contract.contractStatus],
      onClick: () => {
        localStore.commonModal = null;
        localStore.cancelContract();
      },
    };
  }, [contract.contractStatus, localStore]);

  return (
    <button
      onClick={submit}
      disabled={snap.isSubmitting}
      style={{
        width: 108,
        height: 40,
        borderRadius: 88,
        border: `1px solid ${theme.colors.cloudPrimary}`,
        outline: 'none',
        background: 'white',
        opacity: snap.isSubmitting ? 0.2 : 1,
        ...theme.fonts.regular(14),
        color: theme.colors.cloudPrimary,
      }}
    >
      不再签署
    </button>
  );
}

function BottomButtons_ToSign() {
  return (
    <Fragment>
      <Button_RevokeContract />
      <div style={{ flex: 1 }} />
      <Button_SignA />
      <Button_SignB />
    </Fragment>
  );
}

function Button_RevokeContract() {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);

  const submit = useCallback(() => {
    localStore.commonModal = {
      isVisible: true,
      content: '撤销后合同将无法签署！',
      onClick: () => {
        localStore.commonModal = null;
        localStore.revokeContract();
      },
    };
  }, [localStore]);

  return (
    <button
      onClick={submit}
      disabled={snap.isSubmitting}
      style={{
        width: 108,
        height: 40,
        borderRadius: 88,
        border: `1px solid ${theme.colors.cloudPrimary}`,
        outline: 'none',
        background: 'white',
        opacity: snap.isSubmitting ? 0.2 : 1,
        ...theme.fonts.regular(14),
        color: theme.colors.cloudPrimary,
      }}
    >
      撤销合同
    </button>
  );
}

function Button_SignA() {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);
  const { contract } = snap;

  const isDisabled = useMemo(
    () => !contract.canSignA || snap.isSubmitting,
    [contract.canSignA, snap.isSubmitting],
  );

  return (
    <button
      onClick={() => localStore.signA()}
      disabled={isDisabled}
      style={{
        marginLeft: 13,
        width: 108,
        height: 40,
        borderRadius: 88,
        border: 'none',
        outline: 'none',
        background: !isDisabled ? theme.colors.cloudPrimary : colorAlpha('black', 0.15),
        ...theme.fonts.regular(14),
        color: 'white',
      }}
    >
      甲方{contract.canSignA ? '' : '已'}签署
    </button>
  );
}

function Button_SignB() {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);
  const { contract } = snap;

  const isDisabled = useMemo(
    () => !contract.canSignB || snap.isSubmitting,
    [contract.canSignB, snap.isSubmitting],
  );

  return (
    <button
      onClick={() => localStore.signB()}
      disabled={isDisabled}
      style={{
        marginLeft: 13,
        width: 108,
        height: 40,
        borderRadius: 88,
        border: 'none',
        outline: 'none',
        background: !isDisabled ? theme.colors.cloudPrimary : colorAlpha('black', 0.15),
        ...theme.fonts.regular(14),
        color: 'white',
      }}
    >
      乙方{contract.canSignB ? '' : '已'}签署
    </button>
  );
}

function BottomButtons_Revoked() {
  return (
    <Fragment>
      <Button_CancelContract />
      <div style={{ flex: 1 }} />
      <Button_GenerateContract title="重新生成合同" style={{ margin: 'none', width: 140 }} />
    </Fragment>
  );
}

function Button_DownloadContract({ title, style }) {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);
  const { contract } = snap;

  const isDisabled = useMemo(
    () => !snap.contractPdfUrl || snap.isSubmitting,
    [snap.contractPdfUrl, snap.isSubmitting],
  );

  return (
    <button
      onClick={() => {
        window.location.href = localStore.contractPdfUrl;
      }}
      disabled={isDisabled}
      style={{
        margin: '0 auto',
        width: 330,
        height: 40,
        borderRadius: 88,
        border: 'none',
        outline: 'none',
        background: colorAlpha(theme.colors.cloudPrimary, !isDisabled ? 1 : 0.2),
        ...theme.fonts.regular(14),
        color: 'white',
        ...style,
      }}
    >
      {title || '下载合同PDF'}
    </button>
  );
}

function EditorField({ title, titleStyle, value }) {
  return (
    <div className={'flex-row align-center'} style={{ marginTop: 14 }}>
      <div style={{ ...theme.fonts.medium(14), color: theme.colors.newBlack, ...titleStyle }}>
        {title}
      </div>

      <div
        className="flex-row align-center"
        style={{
          flex: 1,
          marginLeft: 12,
          height: 48,
          borderRadius: 8,
          border: 'none',
          outline: 'none',
          background: colorAlpha(theme.colors.cloudPrimary, 0.05),
          padding: '0 15px',
          ...theme.fonts.regular(14),
          color: theme.colors.newBlack,
        }}
      >
        {value}
      </div>
    </div>
  );
}
