import React, { useRef } from 'react';
import ui from 'src/utils/ui';

import AssetImage from './AssetImage';

export function PageLoading({ image }) {
  const beginRef = useRef(+new Date());
  const now = ui.useNow(200);

  if (!(+now - beginRef.current > 500)) return null;

  return (
    <div
      className="flex-row justify-center align-center"
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        background: 'rgba(255, 255, 255, 0.7)',
      }}
    >
      <AssetImage src={image || require('src/images/indicator_submitting@3x.gif')} scale={3} />
    </div>
  );
}
