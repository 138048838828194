import React from 'react';
import autoBind from 'react-autobind';

/**
 * @typedef {object} Props
 * @prop {number} scale
 * @extends {React.Component<Props>}
 */
export default class AssetImage extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  state = {
    width: undefined,
    height: undefined,
  };

  render() {
    return (
      <img
        alt=""
        src={this.props.src}
        onLoad={this.onLoad}
        width={this.state.width}
        height={this.state.height}
        style={{
          display: 'flex',
          margin: 0,
          ...this.props.style,
        }}
      />
    );
  }

  onLoad({ target: image }) {
    const scale = this.props.scale || 2;
    this.setState({
      width: image.naturalWidth / scale,
      height: image.naturalHeight / scale,
    });
  }
}
