import 'mdn-polyfills/Array.from';
import 'mdn-polyfills/Array.prototype.fill';
import 'mdn-polyfills/Array.prototype.forEach';
import 'mdn-polyfills/Array.prototype.includes';
import 'mdn-polyfills/MouseEvent';
import 'mdn-polyfills/String.prototype.padStart';
import './index.css';

import dayjs from 'dayjs';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import App from 'src/App';
import config from 'src/etc/config';
import { unregister } from 'src/registerServiceWorker';
import api from 'src/utils/api';
import store, * as storeOps from 'src/utils/store';
import clientlib from 'src/wagons-clientlib/clientlib';

import { theQueryClient } from './utils/queryHelpers';

if (process.env.NODE_ENV !== 'development' && config.buildType !== 'test') {
  window.console.log = () => null;
}

dayjs.extend(require('dayjs/plugin/duration'));
dayjs.extend(require('dayjs/plugin/isSameOrAfter'));
dayjs.extend(require('dayjs/plugin/isSameOrBefore'));
dayjs.extend(require('dayjs/plugin/localeData'));

dayjs.locale('zh-cn');

clientlib.init({ api, toast });

ReactDOM.render(
  <Fragment>
    <Provider store={store}>
      <PersistGate loading={null} persistor={storeOps.persistor}>
        <QueryClientProvider client={theQueryClient}>
          <BrowserRouter
            getUserConfirmation={(message, callback) => {
              store.dispatch(
                storeOps.setCommonModal({
                  isVisible: true,
                  title: '确认',
                  content: message,
                  button: '确定',
                  onClick: () => {
                    store.dispatch(storeOps.setCommonModal(null));
                    callback(true);
                  },
                  leftButton: '取消',
                  onClickLeftButton: () => {
                    store.dispatch(storeOps.setCommonModal(null));
                    callback(false);
                  },
                }),
              );
            }}
          >
            <App />
          </BrowserRouter>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </Fragment>,
  document.getElementById('root'),
);

// service worker 会导致在微信登录过程中，本来要跳转到 wagons-service 的操作被内部处理了，
// 根本就没有到达 web server，也就无法被 nginx 转发给后端来处理了。
// 暂时先禁掉，后续再研究下怎么对这个功能进行配置吧（如果需要的话，其实我们也不太需要这个）。
// registerServiceWorker();
unregister();
