/**
 * @file 各种用来格式化输出的辅助函数（也可能有一些其他相关或类似的函数）
 *
 * NOTICE 目前小程序还在用 taro2，所以不支持 ?. 和 ?? 运算符，请一定注意！！！
 */

import dayjs from 'dayjs';
import { diff } from 'deep-diff';
import { toJS } from 'mobx';
import numeral from 'numeral';
import qs from 'qs';
import store from 'src/utils/store';

const CHINESE_NUMBERS = '零一二三四五六七八九';
const CHINESE_MONTH = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二'];
const CHINESE_WEEKDAYS = '日一二三四五六日';

const format = {};

/**
 * 其实应该叫 EPSILON，在数学极限中代表一个很小的正数
 *
 * 主要用于对由前端代码算出来的各种实数结果进行比较，以避免 JS 中的计算精度问题。
 * 可以参考这个： https://www.programminghunter.com/article/323680015/
 *
 * 至于为啥不直接用 `Number.EPSILON`？感觉那个太小了，自己定义的这个能防止一定程度的累计误差？
 * （其实是当初不知道有那个，然后自己一拍脑袋（
 * （而且其实也有其他人认为 `Number.EPSILON` 太小了，应根据自己的实际情况来选择： https://stackoverflow.com/a/56967003/233844
 *
 * 浮点数比较示例（这里用的排版格式是 markdown 表格）：
 * （网上搜了半天，也没搜到一篇现成的惯用法示例，那就先自己写一个吧）
 *
 * | 语义 | 实际应写为： | 解释 |
 * | :---: | :---: | :--- |
 * | a === b | Math.abs(a - b) < format.ZERO | |
 * | a > b | a - format.ZERO > b | a，即使减掉了计算误差，仍然大于 b |
 * | a >= b | a + format.ZERO >= b | 0.999999999 应该认为是 >= 1 |
 * | a < b | a + format.ZERO < b | a，即使加上了计算误差，仍然小于 b |
 * | a <= b | a - format.ZERO <= b | 1.000000001 应该认为是 <= 1 |
 */
format.ZERO = 1e-7;

/**
 * JS Error 对象不能直接序列化输出，因为它的成员都不是 enumerable 的
 *
 * @todo 新依赖 serialize-error 会导致 wagons-frontweb 工程报错，暂未搞定，先挪出公共库了。
 */
// format.error = (error) => (error instanceof Error ? serializeError(error) : error);

/** 对象深度比较，但忽略 null 和 undefined 间的差别 */
format.diff = (lhs, rhs) =>
  diff(lhs, rhs, {
    normalize: (_, __, l, r) => {
      if (l == null) {
        l = null;
      }
      if (r == null) {
        r = null;
      }
      return [l, r];
    },
  });

/**
 * 数组求和
 *
 * 有三种使用方式：
 * - format.sum(array): 数字数组，不需要传第二个参数
 * - format.sum(array, 'fieldName'): 对象数组，求所有对象中指定字段的和
 * - format.sum(array, elem => elem.xx.yy): 其他所有情况，数组中每个元素先通过第二个参数的函数
 *                                          转换成一个数字，再求和（注意处理 elem 为空的情况）
 */
format.sum = (arr, field) => {
  return (arr || []).reduce((acc, e) => {
    let value = 0;
    if (!field) {
      value = e;
    } else if (typeof field === 'function') {
      value = field(e);
    } else {
      value = (e || {})[field];
    }
    return acc + (value || 0);
  }, 0);
};

/**
 * 格式化浮点数
 *
 * @param {number | null | undefined} num 待格式化的数字
 * @param {number} count 小数点后最多显示几位数字
 */
format.fixed = (num, count = 2) => {
  let result = null;
  if (Number.isFinite(num)) {
    // TODO numeral 有 bug: https://github.com/adamwdraper/Numeral-js/issues/682
    result =
      Math.abs(num) < format.ZERO
        ? '0'
        : numeral(num).format(`0${count > 0 ? `.[${'0'.repeat(count)}]` : ''}`);
  }
  return result;
};

/** 格式化金额类字段：最多显示 2 位小数 */
format.fee = (fee) => {
  const result = format.fixed(fee, 2);
  return result == null ? '' : result;
};

/** 格式化积分数值：不显示小数部分，千分位逗号分隔 */
format.points = (points) => (Number.isFinite(points) ? numeral(points).format('0,0') : '');

/** 格式化折扣值 */
format.discount = (discount) => {
  const result = format.fixed(discount * 10, 1);
  return result == null ? '' : result;
};

/** 解析整数字符串（偷懒放在了这边 */
format.parseInt = (str) => {
  const num = parseInt(str);
  return Number.isFinite(num) ? num : null;
};

/** 解析浮点数字符串（偷懒放在了这边 */
format.parseFloat = (str) => {
  const num = parseFloat(str);
  return Number.isFinite(num) ? num : null;
};

/** 大写字符串首字母 */
format.capitalFirst = (str) => (!str ? '' : `${str[0].toUpperCase()}${str.slice(1)}`);

format.date = (time) => (time ? dayjs(time).format('YYYY-MM-DD') : null);
format.time = (time) => (time ? dayjs(time).format('YYYY-MM-DD HH:mm') : null);
format.seconds = (time) => (time ? dayjs(time).format('MM-DD HH:mm') : null);
format.timeFull = (time) => (time ? dayjs(time).format('YYYY-MM-DD HH:mm:ss') : null);
format.week = (time) => (time ? CHINESE_WEEKDAYS[dayjs(time).day()] : null);

format.minutes = (minutes) =>
  minutes >= 0
    ? `${minutes >= 60 ? `${parseInt(minutes / 60)}小时` : ''}${format.fixed(minutes % 60)}分钟`
    : null;

/**
 * 格式化倒计时
 *
 * @param time 倒计时的结束时间点
 * @param now 当前时间（可不传）
 */
format.countdown = (time, now) => {
  time = dayjs(time);
  now = now || dayjs();
  const duration = dayjs.duration(time.isAfter(now) ? time.diff(now) : 0);
  return `${time.isAfter(now) ? time.diff(now, 'days') : 0}天 ${numeral(duration.hours()).format(
    '00',
  )}:${numeral(duration.minutes()).format('00')}:${numeral(duration.seconds()).format('00')}`;
};

format.countdownHours = (time, now) => {
  time = dayjs(time);
  now = now || dayjs();
  const duration = dayjs.duration(time.isAfter(now) ? time.diff(now) : 0);
  return numeral(duration.hours()).format('00');
};

format.countdownMinutes = (time, now) => {
  time = dayjs(time);
  now = now || dayjs();
  const duration = dayjs.duration(time.isAfter(now) ? time.diff(now) : 0);
  return numeral(duration.minutes()).format('00');
};

format.countdownSeconds = (time, now) => {
  time = dayjs(time);
  now = now || dayjs();
  const duration = dayjs.duration(time.isAfter(now) ? time.diff(now) : 0);
  return numeral(duration.seconds()).format('00');
};

format.chineseNumber = (num) => {
  return CHINESE_NUMBERS[num];
};

format.chineseMonth = (num) => {
  return CHINESE_MONTH[num];
};

format.chineseWeekday = (num) => {
  return CHINESE_WEEKDAYS[num];
};

/** 清理下传给服务端的参数对象，下划线开头的字段一般都是前端加载，方便界面实现 */
format.stripObject = (object) => {
  object = toJS(object);
  if (!object) return object;
  if (object instanceof Date) return object;
  if (Array.isArray(object)) return object.map((o) => format.stripObject(o));
  if (typeof object !== 'object') return object;
  return Object.assign(
    {},
    ...Object.keys(object || {})
      .filter((field) => !field.startsWith('_'))
      .map((field) => ({ [field]: format.stripObject(object[field]) })),
  );
};

/** @deprecated by format.mosaickedCell 手机号打码 */
format.cell = (cell) => (!cell ? null : cell.substr(0, 3) + '****' + cell.substr(7, 4));
format.mosaickedCell = (cell) => cell?.replace(/(\d{3})\d{0,4}(\d{0,4})/, '$1****$2');

/** 身份证打码 */
format.identityNo = (identityNo) =>
  !identityNo
    ? null
    : identityNo.substr(0, 6) + '**********' + identityNo.substr(identityNo.length - 2);

/**
 * 格式化 url query 字符串
 *
 * - 直接用 `Taro.request` 的话不太好用，目测 axios 应该不支持微信等
 *   小程序环境，所以目前选用了 flyio。
 * - flyio 在编码空参数时有点儿问题，会直接把 `null`、`undefined`
 *   等参数转换成对应的字符串，服务端不认，所以改用 qs 来编码参数。
 * - qs 会把 `null` 编码成空字符串，到服务端就变成 '' 而不是空指针了，
 *   所以要改用 `undefined`。
 */
format.query = (params, qsOptions) => {
  return qs.stringify(
    Object.getOwnPropertyNames(params || {}).reduce((acc, field) => {
      const value = params[field] == null ? undefined : params[field];
      return { ...acc, [field]: value };
    }, {}),
    qsOptions,
  );
};

/**
 * @todo 页面间参数传递，全部统一用 JSON 传呢，就没那么麻烦了：
 * - 不过，考虑到小程序码之类的场景中参数长度有限制，也不能都统一用 JSON；
 * - 或者有需要的页面就搞成可选的？
 *
 * 反之，不统一的好处是，每个字段单独写，也可以当作页面参数的文档。
 */
format.parseUriString = (str) => (str ? decodeURIComponent(str) : null);
format.parseUriInt = (str) => (str ? parseInt(decodeURIComponent(str), 10) : null);
format.parseUriFloat = (str) => (str ? parseFloat(decodeURIComponent(str)) : null);
format.parseUriBool = (str) => str === 'true';
format.parseUriJson = (str) => (str ? JSON.parse(decodeURIComponent(str)) : null);
format.parseUriDayjs = (str) => (str ? dayjs(decodeURIComponent(str)) : null);

format.httpImage = (url) => {
  return url ? url.replace(/^http:/, 'https:') : null;
};

format.priceName = (levelName) => {
  levelName = levelName || '青铜';
  return levelName + (levelName.length > 2 ? '价' : '特权价');
};

format.plateNumber = (pn) => {
  if (!pn) {
    return '--';
  }
  if (pn.length < 4) {
    return pn;
  }
  return pn.substr(0, 2) + '*'.repeat(pn.length - 3) + pn.slice(-1);
};

format.orderRentStatus = (orderRent, needPay) => {
  // NOTICE 这里要照顾小程序的写法，不能用 ?. 之类的新语法
  const status = (orderRent || {}).status;
  const type = (orderRent || {}).type;
  const isYacht = type === 'YACHT_TRAVEL';
  const isSelfDriving = orderRent?.type === 'DRIVING_SELF';
  // TODO 就 frontapp 不一样
  const defaultResult =
    ((
      (store.getState().enums || (store.getState().persisted || {}).enums || {}).orderRentStatus ||
      {}
    ).map || {})[status] || '';
  switch (status) {
    case 'INTENTION':
      return '接单确认中';
    case 'ORDER_REVIEWING':
      return isYacht ? '预约确认中' : defaultResult;
    case 'HOSTLING':
      return '等待用车';
    case 'RENTING':
      return isYacht ? '预约成功' : '用车中';
    case 'FINISH':
      return isSelfDriving && needPay ? '尾款待支付' : defaultResult;
    default:
      return defaultResult;
  }
};

format.simplifyAddress = (regeocode) => {
  let { formatted_address, addressComponent } = regeocode;
  for (const field of ['province', 'city', 'district', 'township']) {
    const value = addressComponent[field];
    if (typeof formatted_address === 'string' && (formatted_address || '').startsWith(value)) {
      formatted_address = formatted_address.slice(value.length);
    }
  }
  return formatted_address;
};

format.addressHint = (address) => {
  return `${address.province}${address.city}${address.district || ''}${address.township || ''}`;
};

/** 航站楼：针对 51book 的格式优化 */
format.flightTerminal = (terminal) => {
  if (!(terminal || '').trim()) return '--';
  if (/^[a-zA-Z]\d$/.test(terminal)) return `${terminal}航站楼`;
  return terminal;
};

/** 航班有无餐食：针对 51book 的格式优化 */
format.flightMeal = (meal) => {
  if (meal === 'true') meal = '提供餐食';
  return '不提供餐食';
};

export default format;
