import * as React from 'react';
import * as valtio from 'valtio';

import constants from '@/utils/constants';

const utils = {};

/** 在客服中心和常见问题中需要展示其地址的门店列表 */
utils.CITY_ADDRESS_LIST = ['北京', '上海', '广州', '深圳', '成都', '昆明', '三亚', '济南'];

/** 搜索车型/车辆时的价格区间参数：前端是个枚举，后端现在变成价格区间了 */
utils.VEHICLE_PRICE_RANGE_TYPES = {
  LESS_THAN_2000: { toPrice: 2000 },
  BETWEEN_2000_4000: { fromPrice: 2000, toPrice: 4000 },
  GREATER_THAN_4000: { fromPrice: 4000 },
};

utils.isiOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

/** 判断当前前端是前台还是后台 */
utils.isBackstage = () => {
  // 后台类型目前比较少，所以比较好写；如果判断前台类型的话，以后这里忘记更新的可能性更大一些。
  return ['backApp', 'backweb'].includes(constants.clientType);
};

/** 一个简单的随机字符串 ID 生成器 */
utils.uuid = () => {
  return Math.random().toString(36).substring(2);
};

utils.sleepMs = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

/** 抄自： https://stackoverflow.com/a/34637436/233844 */
export class DeferredPromise {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    Object.freeze(this);
  }
}
utils.DeferredPromise = DeferredPromise;

utils.convertListToEnum = (list) => {
  return {
    list,
    map: list?.reduce((acc, item) => ({ ...acc, ...{ [item.name]: item.desc } }), {}),
  };
};

/**
 * 封装一个简单的获取 localStore 和 valtio snap 的函数，并同时兼容老代码中的 mobx store 和 fake
 * store，方便渐进式迁移。
 */
export function useLocalStoreContext(context, options) {
  const localStore = React.useContext(context);
  let snap;
  try {
    snap = valtio.useSnapshot(localStore, {
      // NOTICE 嘛，按我们代码的效率，默认开这个选项影响应该也不大，倒是能减轻不少心智负担。
      sync: true,
      ...options,
    });
  } catch (ex) {
    snap = localStore;
  }
  return [localStore, snap];
}
utils.useLocalStoreContext = useLocalStoreContext;

utils.compareVersions = (localVersion, remoteVersion) => {
  if (!localVersion && !remoteVersion) return 0;
  if (!localVersion) return -1;
  if (!remoteVersion) return 1;
  const localVersionArray = localVersion.split('.').map((n) => parseInt(n, 10));
  const remoteVersionArray = remoteVersion.split('.').map((n) => parseInt(n, 10));
  for (let i = 0; i < Math.max(localVersionArray.length, remoteVersionArray.length); i += 1) {
    const local = localVersionArray[i] || 0;
    const remote = remoteVersionArray[i] || 0;
    if (local !== remote) {
      return local - remote;
    }
  }
  return 0;
};

const R = 6371; // 地球半径 (km)

function deg2rad(deg) {
  return (deg * Math.PI) / 180;
}

// 计算经纬度距离（km）
utils.calcDistance = (location1, location2) => {
  location1 = location1 || {};
  location2 = location2 || {};
  const dLon = deg2rad(location1.lng - location2.lng);
  const dLat = deg2rad(location1.lat - location2.lat);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(location1.lat)) *
      Math.cos(deg2rad(location2.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

utils.nearestCity = (location, cities) => {
  return (cities || [])
    .map((city) => ({ ...city, distance: utils.calcDistance(city, location) }))
    .sort((city1, city2) => city1.distance - city2.distance)[0];
};

utils.vehicleDetailImages = (vehicle) => {
  vehicle = vehicle || {};
  return (vehicle.appDetailImg || '').split(',').filter((img) => img);
};

/**
 * 计算车辆详情页的价格相关的信息
 *
 * - 有星卡，所有车都可使用星卡折扣价；
 * - 星卡专享车，只有有星卡的人才能用，也只能用星卡专享价；
 * - 没有星卡，也能用星卡特价车，但用不了星卡特价；
 * - 超值推荐价，谁都能用；
 * - 只显示上述可用价格中的最低价。
 *
 * 新需求：https://lanhuapp.com/web/#/item/project/product?tid=d254741d-c283-4ad4-942c-14add798c9fc&pid=06981ae8-a199-4862-8e64-b6ca57d780ab&versionId=e0885e33-f687-4924-a31a-b3d5fdb6752a&docId=16d28485-24ec-4b4c-be63-909fa04455ec&docType=axure&pageId=04434bdb90164b5cac120c671e0ea0a4&image_id=16d28485-24ec-4b4c-be63-909fa04455ec&parentId=aa973aab4b4d4620a4efb1407803d000
 *
 * @todo 下次再有新需求要改这块儿逻辑的话，我觉得还是按苏皖给的表格来做吧！
 * @todo 更好的做法应该是把这个算法移到服务端，前端只负责展示。
 *
 * 新需求： https://www.tapd.cn/37862179/prong/stories/view/1137862179001000267
 * - 超值推荐价若小于星卡价的话，前台不再展示“星卡折扣价”的标签
 *   - 貌似现在的实现方式本来就满足这个条件了
 *
 * @deprecated
 */
utils.calcVehiclePriceType = ({ token, customerLevel, hasStarCard, vehicleModel }) => {
  const isAboveLevel1 = customerLevel && customerLevel !== '青铜';

  vehicleModel = vehicleModel || {};
  const vehicle = vehicleModel.vehicle || {};
  const vehicleSpecial = vehicleModel.vehicleSpecial || {};

  // 特价车：星卡特价、超值推荐
  const isInSpecial = vehicle.vehicleSpecialStatus === 'IN_SPECIAL';
  // 专享车
  const isStarCardExclusive = vehicle.isStarCardExclusive;
  // 普通车（上面2个都不是则是普通车）
  const isNormalVehicle = !isInSpecial && !isStarCardExclusive;

  let isStarCardSpecial = false;
  let isOverbalanceRecommend = false;

  let originalPrice = vehicle.dayRentPrice;
  /** 最优惠价格的类型 */
  let priceType = 'ORIGIN';
  let price = originalPrice;

  let isLevelPriceTagVisible = false;
  let isOriginalPriceVisible = false;
  let isOverbalanceRecommendTagVisible = false;
  let isSpecialCountdownVisible = false;

  if (vehicleModel.levelRentPrice && vehicleModel.levelRentPrice < price) {
    priceType = 'LEVEL';
    price = vehicleModel.levelRentPrice;
  }

  /* 有星卡时，所有车都能用星卡折扣价，需要参与比较 */
  if (hasStarCard && vehicle.starCardDiscountPrice < price) {
    priceType = 'STAR_CARD_DISCOUNT';
    price = vehicle.starCardDiscountPrice;
  }

  if (isInSpecial) {
    switch (vehicleSpecial.vehicleSpecialType) {
      case 'STAR_CARD_SPECIAL':
        isStarCardSpecial = true;
        if (hasStarCard && vehicleSpecial.price < price) {
          priceType = 'SPECIAL';
          price = vehicleSpecial.price;
        }
        if (token) {
          isLevelPriceTagVisible = true;
          if (hasStarCard) {
            isOriginalPriceVisible = true;
          } else {
            isOriginalPriceVisible = isOriginalPriceVisible || isAboveLevel1;
            if (vehicleModel.levelRentPrice < vehicleSpecial.price) {
              //
            } else {
              isSpecialCountdownVisible = true;
            }
          }
        } else {
          isSpecialCountdownVisible = true;
        }
        // https://app.asana.com/0/281082232721951/1202565306449895
        if (priceType === 'LEVEL') {
          isLevelPriceTagVisible = true;
          isOriginalPriceVisible = true;
        }
        break;

      case 'OVERBALANCE_RECOMMEND':
        isOverbalanceRecommend = true;
        if (vehicleSpecial.price < price) {
          priceType = 'OVERBALANCE_RECOMMEND';
          price = vehicleSpecial.price;
        }
        if (token) {
          if (vehicleSpecial.price < vehicleModel.levelRentPrice) {
            isLevelPriceTagVisible = true;
            isOriginalPriceVisible = true;
          } else {
            if (priceType === 'LEVEL') {
              isLevelPriceTagVisible = true;
              isOriginalPriceVisible = true;
              isOverbalanceRecommendTagVisible = true;
            }
          }
          if (hasStarCard) {
            //
          } else {
            if (vehicleSpecial.price < vehicleModel.levelRentPrice) {
              isSpecialCountdownVisible = true;
            }
          }
          if (priceType === 'STAR_CARD_DISCOUNT') {
            isOverbalanceRecommendTagVisible = true;
          }
        } else {
          isOriginalPriceVisible = true;
          isSpecialCountdownVisible = true;
        }
        break;

      default:
        break;
    }
  }

  if (isNormalVehicle) {
    if (token) {
      isLevelPriceTagVisible = true;
      isOriginalPriceVisible = isOriginalPriceVisible || isAboveLevel1;
    }
  }

  /* 专享车不做价格比较，只要是专享车，不管客户能不能用，都要显示星卡专享价 */
  if (isStarCardExclusive) {
    priceType = 'STAR_CARD_EXCLUSIVE';
    price = vehicle.starCardDiscountPrice;
    isOriginalPriceVisible = true;
    isLevelPriceTagVisible = !!token;
  }

  isOriginalPriceVisible = isOriginalPriceVisible || priceType === 'STAR_CARD_DISCOUNT';
  isOriginalPriceVisible = isOriginalPriceVisible && priceType !== 'ORIGIN';

  isOverbalanceRecommendTagVisible =
    isOverbalanceRecommendTagVisible || priceType === 'OVERBALANCE_RECOMMEND';

  const isStarCardDiscountVisible =
    !hasStarCard &&
    !isStarCardExclusive &&
    (!isInSpecial || vehicleSpecial.vehicleSpecialType !== 'STAR_CARD_SPECIAL') &&
    vehicle.starCardDiscountPrice < price;
  const isStarCardEntranceVisible =
    !hasStarCard && utils.calcVehiclePriceType({ vehicleModel, hasStarCard: true }).price < price;

  return {
    /** 是否在特价期间：目前包括星卡特价、超值推荐两种情况 */
    isInSpecial,
    /** 是否是星卡专享车 */
    isStarCardExclusive,
    /** 是否是普通价格的车辆，而非各种特价、专享等情况 */
    isNormalVehicle,
    /** 是否是星卡特价车 */
    isStarCardSpecial,
    /** 是否是超值推荐的特价车 */
    isOverbalanceRecommend,
    /** 所展示的加粗价格是哪种价格类型，可能包括原价、等级价、多日价、特价、专享价等 */
    priceType,
    /** 加粗价格 */
    price,
    /** 原价（划掉的价格） */
    originalPrice,
    /** 是否展示等级价标签 */
    isLevelPriceTagVisible,
    /** 是否展示原价（划掉的价格） */
    isOriginalPriceVisible,
    /** 是否展示超值推荐价标签 */
    isOverbalanceRecommendTagVisible,
    /** 是否展示特价倒计时 */
    isSpecialCountdownVisible,
    /** 是否展示星卡特价标签 */
    isStarCardDiscountVisible,
    /** 是否展示星卡购买入口 */
    isStarCardEntranceVisible,
  };
};

export default utils;
