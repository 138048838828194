import colorAlpha from 'color-alpha';
import React from 'react';
import { Modal } from 'semantic-ui-react';

import theme from '@/utils/theme';
import AssetImage from '@/widgets/AssetImage';

export default class CommonModal extends React.Component {
  static defaultProps = {
    isClosingDisabled: true,
    isButtonsVisible: true,
  };

  render() {
    return (
      <Modal
        open={!!(this.props.isVisible || this.props.visible)}
        onClose={this.props.onClose}
        style={{
          maxWidth: 288,
          borderRadius: 8,
          ...this.props.style,
        }}
        dimmer={{ style: { background: colorAlpha('black', 0.45) } }}
      >
        <div className="flex-column">
          <div
            style={{
              marginTop: 32,
              alignSelf: 'center',
              whiteSpace: 'pre-line',
              ...theme.fonts.medium(15),
              color: theme.colors.cloudPrimary,
              ...this.props.titleStyle,
            }}
          >
            {this.props.title}
          </div>

          {!!(this.props.onClose && !this.props.isClosingDisabled) && (
            <div
              onClick={this.props.onClose}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                padding: 16,
              }}
            >
              <AssetImage src={require('src/images/icon_close_gray@2x.png')} />
            </div>
          )}

          <div
            className="flex-column align-center"
            style={{
              margin: '24px 24px 0 24px',
              textAlign: 'center',
              whiteSpace: 'pre-line',
              ...theme.fonts.medium(14, 24),
              color: 'black',
              ...this.props.contentStyle,
            }}
          >
            {/* 多的一层 span 是为了实现单行居中、多行左对齐的效果 */}
            <span>{this.props.content}</span>
          </div>

          {!!this.props.isButtonsVisible && (
            <div className="flex-row align-center" style={{ padding: '0 20px' }}>
              {!!this.props.leftButton && (
                <div
                  onClick={() => {
                    this.props.onClose?.();
                    this.props.onClickLeftButton?.();
                  }}
                  style={{
                    flex: 1,
                    padding: '24px 0',
                    textAlign: 'center',
                    ...theme.fonts.medium(15),
                    color: this.props.leftButtonColor || colorAlpha(theme.colors.cloudPrimary, 0.6),
                  }}
                >
                  {this.props.leftButton}
                </div>
              )}

              <div
                onClick={() => {
                  if (this.props.onClose) {
                    this.props.onClose();
                  }
                  if (this.props.onClick) {
                    this.props.onClick();
                  }
                }}
                style={{
                  flex: 1,
                  padding: '24px 0',
                  textAlign: 'center',
                  ...theme.fonts.medium(15),
                  color: this.props.buttonColor || 'black',
                  ...this.props.buttonTextStyle,
                }}
              >
                {this.props.button || '我知道了'}
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
