import './css/report.css';
import 'antd/dist/antd.css';

import { Descriptions } from 'antd';
import React, { Fragment, useMemo } from 'react';

import format from '@/utils/format';
import theme from '@/utils/theme';

const COURT_DETAIL_TYPES = {
  shixin: {
    title: '失信被执行人',
    render: (item) => (
      <Descriptions>
        <Descriptions.Item label="立案时间">{format.date(item.sortTime)}</Descriptions.Item>
        <Descriptions.Item label="被执行人姓名">{item.pname}</Descriptions.Item>
        <Descriptions.Item label="身份证号/组织机构代码">{item.idcardNo}</Descriptions.Item>
        <Descriptions.Item label="执行依据文号">{item.yjCode}</Descriptions.Item>
        <Descriptions.Item label="做出执行依据单位">{item.yjdw}</Descriptions.Item>
        <Descriptions.Item label="被执行人的履行情况">{item.lxqk}</Descriptions.Item>
        <Descriptions.Item label="内容概要">{item.body}</Descriptions.Item>
      </Descriptions>
    ),
  },
  zxgg: {
    title: '执行公告',
    render: (item) => (
      <Descriptions>
        <Descriptions.Item label="标题">{item.title}</Descriptions.Item>
        <Descriptions.Item label="立案时间">{format.date(item.sortTime)}</Descriptions.Item>
        <Descriptions.Item label="被执行人姓名">{item.pname}</Descriptions.Item>
        <Descriptions.Item label="身份证号/组织机构代码">{item.idcardNo}</Descriptions.Item>
        <Descriptions.Item label="案件状态">{item.caseState}</Descriptions.Item>
        <Descriptions.Item label="执行标的">{item.execMoney}</Descriptions.Item>
        <Descriptions.Item label="内容概要">{item.body}</Descriptions.Item>
      </Descriptions>
    ),
  },
  cpws: {
    title: '裁判文书',
    render: (item) => (
      <Descriptions>
        <Descriptions.Item label="标题">{item.title}</Descriptions.Item>
        <Descriptions.Item label="审判时间">{format.date(item.sortTime)}</Descriptions.Item>
        <Descriptions.Item label="文书类型（案件类型）">{item.caseType}</Descriptions.Item>
        <Descriptions.Item label="审理程序">{item.trialProcedure}</Descriptions.Item>
        <Descriptions.Item label="裁判结果">{item.judgeResult}</Descriptions.Item>
        <Descriptions.Item label="依据">{item.yiju}</Descriptions.Item>
        <Descriptions.Item label="案由">{item.caseCause}</Descriptions.Item>
        <Descriptions.Item label="内容概要">{item.body}</Descriptions.Item>
      </Descriptions>
    ),
  },
  ktgg: {
    title: '开庭公告',
    render: (item) => (
      <Descriptions>
        <Descriptions.Item label="标题">{item.title}</Descriptions.Item>
        <Descriptions.Item label="开庭时间">{format.date(item.sortTime)}</Descriptions.Item>
        <Descriptions.Item label="原告">{item.plaintiff}</Descriptions.Item>
        <Descriptions.Item label="被告">{item.defendant}</Descriptions.Item>
        <Descriptions.Item label="案由">{item.caseCause}</Descriptions.Item>
        <Descriptions.Item label="内容概要">{item.body}</Descriptions.Item>
      </Descriptions>
    ),
  },
  fygg: {
    title: '法院公告',
    render: (item) => (
      <Descriptions>
        <Descriptions.Item label="发布时间">{format.date(item.sortTime)}</Descriptions.Item>
        <Descriptions.Item label="原告">{item.plaintiff}</Descriptions.Item>
        <Descriptions.Item label="被告">{item.defendant}</Descriptions.Item>
        <Descriptions.Item label="版面">{item.layout}</Descriptions.Item>
        <Descriptions.Item label="公告类型">{item.ggType}</Descriptions.Item>
        <Descriptions.Item label="内容概要">{item.body}</Descriptions.Item>
      </Descriptions>
    ),
  },
  ajlc: {
    title: '案件流程',
    render: (item) => (
      <Descriptions>
        <Descriptions.Item label="立案时间">{format.date(item.sortTime)}</Descriptions.Item>
        <Descriptions.Item label="原告">{item.plaintiff}</Descriptions.Item>
        <Descriptions.Item label="被告">{item.defendant}</Descriptions.Item>
        <Descriptions.Item label="案件类别">{item.caseType}</Descriptions.Item>
        <Descriptions.Item label="审理程序">{item.trialProcedure}</Descriptions.Item>
        <Descriptions.Item label="执行状态">{item.status}</Descriptions.Item>
        <Descriptions.Item label="案件状态">{item.caseStatus}</Descriptions.Item>
        <Descriptions.Item label="诉讼标的">{item.actionObject}</Descriptions.Item>
        <Descriptions.Item label="有效日期">{item.effectiveDate}</Descriptions.Item>
        <Descriptions.Item label="案由">{item.caseCause}</Descriptions.Item>
        <Descriptions.Item label="内容概要">{item.body}</Descriptions.Item>
      </Descriptions>
    ),
  },
  bgt: {
    title: '曝光台',
    render: (item) => (
      <Descriptions>
        <Descriptions.Item label="立案时间">{format.date(item.sortTime)}</Descriptions.Item>
        <Descriptions.Item label="曝光日期">{format.date(item.bgDate)}</Descriptions.Item>
        <Descriptions.Item label="申请人">{item.proposer}</Descriptions.Item>
        <Descriptions.Item label="身份证/组织机构代码">{item.idcardNo}</Descriptions.Item>
        <Descriptions.Item label="未执行金额">{item.unnexeMoney}</Descriptions.Item>
        <Descriptions.Item label="标的金额">{item.execMoney}</Descriptions.Item>
        <Descriptions.Item label="依据">{item.yiju}</Descriptions.Item>
        <Descriptions.Item label="地址">{item.address}</Descriptions.Item>
        <Descriptions.Item label="内容概要">{item.body}</Descriptions.Item>
      </Descriptions>
    ),
  },
  wdhmd: {
    title: '网贷黑名单',
    render: (item) => (
      <Descriptions>
        <Descriptions.Item label="贷款时间">{format.date(item.sortTime)}</Descriptions.Item>
        <Descriptions.Item label="姓名">{item.pname}</Descriptions.Item>
        <Descriptions.Item label="企业注册号">{item.idcardNo}</Descriptions.Item>
        <Descriptions.Item label="来源单位名称">{item.sourceName}</Descriptions.Item>
        <Descriptions.Item label="未还/罚息">{item.whfx}</Descriptions.Item>
        <Descriptions.Item label="本金/本息">{item.bjbx}</Descriptions.Item>
        <Descriptions.Item label="已还金额">{item.yhje}</Descriptions.Item>
        <Descriptions.Item label="手机号">{item.mobile}</Descriptions.Item>
        <Descriptions.Item label="信息更新时间">{item.updateTime}</Descriptions.Item>
        <Descriptions.Item label="居住地址">{item.address}</Descriptions.Item>
        <Descriptions.Item label="内容概要">{item.body}</Descriptions.Item>
      </Descriptions>
    ),
  },
};
export function InfoVerifyReport({ infoVerifyReport }) {
  return (
    <Fragment>
      <div style={{ height: 15 }} />
      <TitleOne title={'验证数据产品'} />
      <TitleTwo title={'身份证二要素验证'} />
      <Descriptions bordered>
        <Descriptions.Item label="校验项">身份证号、姓名</Descriptions.Item>
        <Descriptions.Item label="校验结果">{infoVerifyReport.idTwoZ?.message}</Descriptions.Item>
      </Descriptions>
      <TitleTwo title={'手机三要素简版——移动联通电信'} />
      <Descriptions bordered>
        <Descriptions.Item label="校验项">身份证号、手机号、姓名</Descriptions.Item>
        <Descriptions.Item label="手机号运营商">
          {infoVerifyReport.telCheckS?.operationDesc}
        </Descriptions.Item>
        <Descriptions.Item label="校验结果">
          {infoVerifyReport.telCheckS?.resultDesc}
        </Descriptions.Item>
      </Descriptions>
      <TitleTwo title={'手机信息验证'} />
      <Descriptions bordered>
        <Descriptions.Item label="手机运营商类型">
          {infoVerifyReport.telStatus?.operationDesc}
        </Descriptions.Item>
        <Descriptions.Item label="手机在网状态">
          {infoVerifyReport.telStatus?.statusDesc}
        </Descriptions.Item>
        <Descriptions.Item label="手机在网时长">
          {infoVerifyReport.telPeriod?.periodDesc}
        </Descriptions.Item>
        <Descriptions.Item label="手机消费档次">-</Descriptions.Item>
      </Descriptions>
      <div style={{ marginTop: 10 }}>注：</div>
      <div>
        1.
        「手机在网状态」、「手机在网时长」、「手机消费档次」数据覆盖全国范围，移动、联通、电信三家运营商数据。
      </div>
      <div>
        2. 手机消费档次的取值返回为系数，取值为 0-200，单位：元，系数越大，代表手机消费档次越高。
      </div>
      <TitleTwo title={'法院信息详情-个人高级版'} />
      {infoVerifyReport.courtDetailPro?.entryList?.length > 0 ? (
        <table className="tables">
          <thead>
            <tr>
              <th>序号</th>
              <th>查询项目</th>
              <th>法院名称</th>
              <th>案号</th>
              <th>匹配度</th>
            </tr>
          </thead>
          <tbody>
            {infoVerifyReport.courtDetailPro?.entryList?.map((item, i) => (
              <Fragment key={item.dataType + item.sortTime}>
                <tr>
                  <td>{i + 1}</td>
                  <td>{COURT_DETAIL_TYPES[item.dataType]?.title}</td>
                  <td>{item.court}</td>
                  <td>{item.caseNo}</td>
                  <td>{item.matchRatio}</td>
                </tr>
                <tr>
                  <td colSpan={5}>{COURT_DETAIL_TYPES[item.dataType]?.render(item)}</td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <NoData title="法院信息详情-个人高级版" />
      )}
      <div style={{ marginTop: 10 }}>{`注：`}</div>
      <div>{`匹配度取值为0-1，值越⼤，表⽰匹配程度越⾼。`}</div>
    </Fragment>
  );
}

function NoData({ title }) {
  return (
    <table className="tables">
      <tbody>
        <tr>
          <td style={{ border: 'none', textAlign: 'left' }}>查询成功，无{title}相关信息</td>
        </tr>
      </tbody>
    </table>
  );
}

function TitleOne({ title }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <div style={{ width: 6, height: 20, background: '#4377F5', marginRight: 10 }} />
      <div style={{ ...theme.fonts.regular(16) }}>{title}</div>
    </div>
  );
}

function TitleTwo({ title }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '15px 0',
      }}
    >
      <div style={{ width: 20, height: 1, backgroundColor: theme.colors.textLightgray }} />
      <div
        style={{
          ...theme.fonts.medium(14),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 10px',
        }}
      >
        <div>{title}</div>
      </div>
      <div style={{ width: 20, height: 1, backgroundColor: theme.colors.textLightgray }} />
    </div>
  );
}
