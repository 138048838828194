import './css/report.css';
import 'antd/dist/antd.css';

import React, { Fragment, useMemo } from 'react';
import autoBind from 'react-autobind';
import ui from 'src/utils/ui';

import api2 from '@/utils/api2';
import format from '@/utils/format';
import theme from '@/utils/theme';

import { BeforeLoanReport } from './BeforeLoanReport';
import { InfoVerifyReport } from './InfoVerifyReport';

class RiskQueryReportPage extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.navprops = this.props.match.params || {};

    this.state = {
      name: null,
      cell: null,
      idCardNo: null,
      evaluateTime: null,
      beforeLoanReport: {},
      hitRulesInfo: {},
      infoVerifyReport: {},
      riskQueryRecordId: null,
      wagonsResult: null,
      type: 'assess',
      sortDirection: 'asc',
    };
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    try {
      const result = await api2.get('cloud-svr/back/riskQuery/getRiskQueryReport', {
        riskQueryRecordId: this.navprops.riskQueryRecordId,
      });
      const { res = {} } = result.data;

      this.setState({
        name: res?.name,
        cell: res?.cell,
        idCardNo: res?.idCardNo,
        evaluateTime: res?.evaluateTime,
        beforeLoanReport: res?.beforeLoanReport,
        hitRulesInfo: res?.hitRulesInfo,
        infoVerifyReport: res?.infoVerifyReport,
        riskQueryRecordId: res?.riskQueryRecordId,
        wagonsResult: res?.wagonsResult,
      });
    } catch (ex) {
      console.warn('RiskRecordDetailPage.getRiskQueryReport:', ex);
      ui.toastError(ex);
      if (ex.message === '未登录') {
        this.props.history.replace('/m/Login');
      }
    }
  }

  render() {
    const { beforeLoanReport, infoVerifyReport } = this.state;

    return (
      <div
        style={{
          margin: '0 auto',
          maxWidth: 600,
          minHeight: document.body.clientHeight,
          background: 'rgba(68,120,245,0.05)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {this.renderHeader()}
        <div
          style={{
            backgroundColor: 'white',
            padding: '10px 15px',
          }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}
          >
            <div style={{ ...theme.fonts.medium(14), width: 70 }}>客户姓名</div>
            <div style={{ ...theme.fonts.regular(14) }}>{this.state.name}</div>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}
          >
            <div style={{ ...theme.fonts.medium(14), width: 70 }}>手机号码</div>
            <div style={{ ...theme.fonts.regular(14) }}>{this.state.cell}</div>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}
          >
            <div style={{ ...theme.fonts.medium(14), width: 70 }}>身份证号</div>
            <div style={{ ...theme.fonts.regular(14) }}>{this.state.idCardNo}</div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            height: 40,
            marginTop: 10,
            backgroundColor: 'white',
          }}
        >
          <div
            style={{
              ...(this.state.type === 'assess' ? theme.fonts.medium(16) : theme.fonts.regular(16)),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: this.state.type === 'assess' ? '#4377F5' : 'black',
            }}
            onClick={() => this.setState({ type: 'assess' })}
          >
            <div>综合评估</div>
          </div>
          <div
            style={{
              ...(this.state.type === 'survey' ? theme.fonts.medium(16) : theme.fonts.regular(16)),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: this.state.type === 'survey' ? '#4377F5' : 'black',
            }}
            onClick={() => this.setState({ type: 'survey' })}
          >
            <div>风险勘测</div>
          </div>
          <div
            style={{
              ...(this.state.type === 'verify' ? theme.fonts.medium(16) : theme.fonts.regular(16)),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: this.state.type === 'verify' ? '#4377F5' : 'black',
            }}
            onClick={() => this.setState({ type: 'verify' })}
          >
            <div>身份验证</div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: this.state.type === 'assess' ? '#4377F5' : 'transparent',
              width: 65,
              height: 4,
            }}
          />
          <div
            style={{
              background: this.state.type === 'survey' ? '#4377F5' : 'transparent',
              width: 65,
              height: 4,
            }}
          />
          <div
            style={{
              background: this.state.type === 'verify' ? '#4377F5' : 'transparent',
              width: 65,
              height: 4,
            }}
          />
        </div>
        {this.state.type === 'assess' && (
          <div
            style={{
              backgroundColor: 'white',
              minHeight: document.body.clientHeight - 196,
              padding: '10px 15px',
            }}
          >
            <div
              style={{
                ...theme.fonts.regular(12),
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>编号：{this.state.riskQueryRecordId}</div>
              <div>评估时间：{format.time(this.state.evaluateTime)}</div>
            </div>
            <div
              style={{
                ...theme.fonts.medium(14),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 50,
              }}
            >
              <div style={{ marginBottom: 10 }}>
                {this.state.wagonsResult === '建议通过' ? (
                  <div
                    style={{
                      width: 80,
                      height: 80,
                      borderRadius: 40,
                      border: '2px solid #339999',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#339999',
                      boxShadow: 'none',
                      ...theme.fonts.medium(16),
                      background: '#fff',
                    }}
                  >
                    <div>
                      建<span style={{ marginLeft: 2 }}>议</span>
                    </div>
                    <div>
                      通<span style={{ marginLeft: 2 }}>过</span>
                    </div>
                  </div>
                ) : this.state.wagonsResult === '建议拒绝' ? (
                  <div
                    style={{
                      width: 80,
                      height: 80,
                      borderRadius: 40,
                      border: '2px solid #ff6e6e',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#ff6e6e',
                      boxShadow: 'none',
                      ...theme.fonts.medium(16),
                      background: '#fff',
                    }}
                  >
                    <div>
                      建<span style={{ marginLeft: 2 }}>议</span>
                    </div>
                    <div>
                      拒<span style={{ marginLeft: 2 }}>绝</span>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      width: 80,
                      height: 80,
                      borderRadius: 40,
                      border: '2px solid #d0813e',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: '#d0813e',
                      boxShadow: 'none',
                      ...theme.fonts.medium(16),
                      background: '#fff',
                    }}
                  >
                    <div>
                      建<span style={{ marginLeft: 2 }}>议</span>
                    </div>
                    <div>
                      复<span style={{ marginLeft: 2 }}>议</span>
                    </div>
                  </div>
                )}
              </div>
              {this.state.wagonsResult === '建议通过' && (
                <div
                  style={{
                    color: '#000',
                    marginTop: 10,
                    ...theme.fonts.medium(14),
                  }}
                >
                  租车人各项风险较低，建议通过
                </div>
              )}
              {this.state.wagonsResult === '建议拒绝' && (
                <div style={{ color: '#000', marginTop: 10, ...theme.fonts.medium(14) }}>
                  租车人存在风险问题，建议拒绝
                </div>
              )}
              {this.state.wagonsResult === '建议复议' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#000',
                    marginTop: 10,
                    ...theme.fonts.medium(14),
                  }}
                >
                  <div>租车人存在风险</div>
                  <div>建议结合线下资料进行风险评估</div>
                </div>
              )}
            </div>
            <div
              className="flex-row align-center"
              style={{ ...theme.fonts.regular(16), margin: '20px 0' }}
            >
              <div
                style={{ color: '#FF0000' }}
              >{`• 高风险(${this.state.hitRulesInfo?.highRisk?.length})`}</div>
              <div
                style={{ marginLeft: 20, color: '#FF6600' }}
              >{`• 中风险(${this.state.hitRulesInfo?.mediumRisk?.length})`}</div>
              <div
                style={{ marginLeft: 20, color: '#F5BB23' }}
              >{`• 低风险(${this.state.hitRulesInfo?.lowRisk?.length})`}</div>
            </div>
            {this.renderCards()}
          </div>
        )}
        {['survey', 'verify'].includes(this.state.type) && (
          <div style={{ ...theme.fonts.regular(14) }}>
            <div style={{ padding: '10px 15px' }}>
              <div
                style={{
                  ...theme.fonts.regular(12),
                }}
              >
                <div>
                  报告编号：
                  {this.state.type === 'survey'
                    ? beforeLoanReport.swiftNumber
                    : infoVerifyReport.swiftNumber}
                </div>
                <div style={{ marginTop: 6 }}>
                  报告生成时间：
                  {format.time(
                    this.state.type === 'survey'
                      ? beforeLoanReport.reportTime
                      : infoVerifyReport.reportTime,
                  )}
                </div>
              </div>
              {this.state.type === 'survey' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '20px 0 10px 0',
                  }}
                >
                  <div style={{ ...theme.fonts.medium(15) }}>
                    反欺诈规则：{beforeLoanReport.finalWeight} 分
                  </div>
                  <div
                    style={{
                      marginTop: 8,
                      ...theme.fonts.regular(13),
                      color: 'rgba(0,0,0,0.45)',
                    }}
                  >
                    (区间：0-30 通过，31-50 复议，51-100 拒绝)
                  </div>
                  <div style={{ ...theme.fonts.medium(15), marginTop: 18 }}>
                    反欺诈评分：{beforeLoanReport.scoreAfAutoFin?.scoreafautofin} 分
                  </div>
                  <div
                    style={{
                      marginTop: 8,
                      ...theme.fonts.regular(13),
                      color: 'rgba(0,0,0,0.45)',
                    }}
                  >
                    (区间：0-30 通过，31-50 复议，51-100 拒绝)
                  </div>
                  <div style={{ ...theme.fonts.medium(15), marginTop: 18 }}>
                    信用评分：{beforeLoanReport.scoreAutoLea?.scoreautolea} 分
                  </div>
                  <div
                    style={{
                      marginTop: 8,
                      ...theme.fonts.regular(13),
                      color: 'rgba(0,0,0,0.45)',
                    }}
                  >
                    (区间：701-1000 通过，501-700 复议，300-500 拒绝)
                  </div>
                  <div style={{ marginTop: 18, ...theme.fonts.medium(15), color: '#4377F5' }}>
                    最终决定：{beforeLoanReport.finalDecision}
                  </div>
                </div>
              )}

              {this.state.type === 'verify' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '20px 0 10px 0',
                  }}
                >
                  <div style={{ ...theme.fonts.medium(15) }}>
                    最终评分：{infoVerifyReport.finalWeight}
                  </div>
                  <div
                    style={{
                      marginTop: 8,
                      ...theme.fonts.regular(13),

                      color: 'rgba(0,0,0,0.45)',
                    }}
                  >
                    低⻛险0-60，中⻛险61-80，⾼⻛险81-100
                  </div>
                  <div style={{ marginTop: 18, ...theme.fonts.medium(15), color: '#4377F5' }}>
                    最终决定：{infoVerifyReport.finalDecision}
                  </div>
                </div>
              )}
            </div>

            <div style={{ marginTop: 6, background: 'white', padding: '10px 15px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div
                  style={{
                    ...theme.fonts.medium(14, 24),
                    background: '#CE4A43',
                    color: 'white',
                    width: 80,
                    textAlign: 'center',
                    height: 24,
                  }}
                >
                  风险提示
                </div>
                <div style={{ ...theme.fonts.regular(14), padding: '4px 8px', color: '#CE4A43' }}>
                  本次报告命中{' '}
                  {this.state.type === 'survey'
                    ? beforeLoanReport.hitExceptionRule?.length
                    : infoVerifyReport.hitExceptionRule?.length}{' '}
                  条异常规则信息
                </div>
              </div>
              {this.state.type === 'survey' &&
                beforeLoanReport.hitExceptionRule?.map((rule) => (
                  <div key={rule} style={{ ...theme.fonts.regular(14), padding: '0 8px' }}>
                    <span style={{ color: '#CE4A43' }}>* </span>
                    {rule}
                  </div>
                ))}
              {this.state.type === 'verify' &&
                infoVerifyReport.hitExceptionRule?.map((rule) => (
                  <div key={rule} style={{ ...theme.fonts.regular(14), padding: '0 8px' }}>
                    <span style={{ color: '#CE4A43' }}>* </span>
                    {rule}
                  </div>
                ))}
            </div>
            <div style={{ marginTop: 1, background: 'white', padding: '15px' }}>
              <TitleOne title={'规则命中详情'} />

              {this.state.type === 'survey' ? (
                <HitRules title={'反欺诈规则集详情'} hitRules={beforeLoanReport.hitRules} />
              ) : (
                <HitRules title={'验证规则集详情'} hitRules={infoVerifyReport.hitRules} />
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 20,
                  marginTop: 10,
                }}
              >
                <div style={{ ...theme.fonts.medium(14), marginLeft: 6 }}>显示数据详情</div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    this.setState({
                      sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc',
                    });
                  }}
                >
                  <div
                    style={{
                      width: 12,
                      height: 6,
                      backgroundColor: this.state.sortDirection === 'desc' ? '#a8a8a8' : '#4377F5',
                      '-webkit-mask': `url(${require('src/images/icon_sort_up.svg')}) no-repeat center`,
                      mask: `url(${require('src/images/icon_sort_up.svg')}) no-repeat center`,
                    }}
                  />
                  <div
                    style={{
                      width: 12,
                      height: 6,
                      marginTop: 2,
                      backgroundColor: this.state.sortDirection === 'desc' ? '#4377F5' : '#a8a8a8',
                      '-webkit-mask': `url(${require('src/images/icon_sort_down.svg')}) no-repeat center`,
                      mask: `url(${require('src/images/icon_sort_down.svg')}) no-repeat center`,
                    }}
                  />
                </div>
              </div>
              {this.state.sortDirection === 'desc' && this.state.type === 'survey' && (
                <BeforeLoanReport beforeLoanReport={beforeLoanReport} />
              )}
              {this.state.sortDirection === 'desc' && this.state.type === 'verify' && (
                <InfoVerifyReport infoVerifyReport={infoVerifyReport} />
              )}

              <div
                style={{ ...theme.fonts.regular(15), margin: '10px 0 10px 0', fontWeight: 'bold' }}
              >
                阅读须知：
              </div>
              <div>
                1. 客户使用本报告，需经过被查询人授权，客户承担因授权不充分引起的任何法律责任。
              </div>
              <div>
                2.
                本报告仅限客户内部使用，请妥善保管本报告，不得向任何第三泄露或允许任何第三方使用本报告。
              </div>
              <div>3. 本报告仅供客户参考，不作为客户决策的依据。</div>
              <div>4. 未经我司书面许可，任何人不得擅自复制、摘录、编辑、转载、披露和发表。</div>
              <div>5. 请确保在安全的物理及网络环境操作并确保导出内容的保密、安全及合规应用。</div>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderHeader() {
    const HEADER_HEIGHT = 44;

    return (
      <div
        style={{
          width: '100vw',
          height: HEADER_HEIGHT,
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            height: HEADER_HEIGHT,
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div
              onClick={() => {
                this.props.history.goBack();
              }}
              className="flex-row justify-center align-center"
            >
              <img
                style={{ marginLeft: 20, height: 15 }}
                alt=""
                src={require('src/images/icon_chevron_left.svg')}
              />
            </div>
          </div>
          <div
            style={{
              ...theme.fonts.medium(15),
              textAlign: 'center',
            }}
          >
            客户风险评定报告
          </div>
        </div>
      </div>
    );
  }

  renderCards = () => {
    const { hitRulesInfo } = this.state;
    const ruleHitsHigh = hitRulesInfo?.highRisk || [];
    const ruleHitsMedium = hitRulesInfo?.mediumRisk || [];
    const ruleHitsLow = hitRulesInfo?.lowRisk || [];

    if (!ruleHitsLow.length && !ruleHitsMedium.length && !ruleHitsHigh.length) {
      return (
        <div
          style={{
            ...theme.fonts.regular(16),
            border: '1px solid #ccc',
            borderRadius: 8,
            padding: 10,
            // lineHeight: 1.8,
            color: theme.colors.textGray,
          }}
        >
          未命中风险规则集
        </div>
      );
    }
    return (
      <div
        style={{
          ...theme.fonts.regular(16),
          border: '1px solid #ccc',
          borderRadius: 8,
          padding: 10,
        }}
      >
        {/* 高风险 */}
        {ruleHitsHigh?.map((line) => (
          <div key={line} style={{ color: '#FF0000' }}>
            • {line}
          </div>
        ))}
        {/* 中风险 */}
        {!!ruleHitsMedium.length &&
          ruleHitsMedium.map((line) => (
            <div key={line} style={{ color: '#FF6600' }}>
              • {line}
            </div>
          ))}
        {/* 低风险 */}
        {!!ruleHitsLow.length &&
          ruleHitsLow.map((line) => (
            <div key={line} style={{ color: '#F5BB23' }}>
              • {line}
            </div>
          ))}
      </div>
    );
  };
}

function HitRules({ title, hitRules }) {
  return (
    <Fragment>
      <TitleTwo title={title} />
      {hitRules?.length > 0 ? (
        <table className="tables">
          <thead>
            <tr>
              <th>命中规则集</th>
              <th>权重</th>
              <th>命中规则详情</th>
              <th>取值结果</th>
            </tr>
          </thead>
          <tbody>
            {hitRules?.map((ruleSet) =>
              ruleSet.detailList.map((detail, i) => (
                <tr key={ruleSet.rule + detail.code}>
                  {i === 0 && (
                    <Fragment>
                      <td rowSpan={ruleSet.detailList.length}>{ruleSet.rule}</td>
                      <td rowSpan={ruleSet.detailList.length}>
                        <Weight weight={ruleSet.weight} />
                      </td>
                    </Fragment>
                  )}
                  <td>{detail.detail}</td>
                  <td>
                    {detail.value?.split('\n').map((line) => (
                      <p key={line}>{line}</p>
                    ))}
                  </td>
                </tr>
              )),
            )}
          </tbody>
        </table>
      ) : (
        <NoData title={title} />
      )}
    </Fragment>
  );
}

function NoData({ title }) {
  return (
    <table className="tables">
      <tbody>
        <tr>
          <td style={{ border: 'none', textAlign: 'left' }}>查询成功，无{title}相关信息</td>
        </tr>
      </tbody>
    </table>
  );
}

function Weight({ weight }) {
  const color = useMemo(() => {
    const value = parseFloat(weight);
    if (value >= 0 && value <= 30) {
      return '#55C559';
    }
    if (value > 30 && value <= 50) {
      return '#D0803E';
    }
    if (value > 50 && value <= 100) {
      return '#D34646';
    }
    return null;
  }, [weight]);

  return (
    <div
      className="flex-row justify-center align-center"
      style={{ width: 30, height: 20, borderRadius: 4, background: color, color: 'white' }}
    >
      {weight}
    </div>
  );
}
function TitleOne({ title }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <div style={{ width: 6, height: 20, background: '#4377F5', marginRight: 10 }} />
      <div style={{ ...theme.fonts.regular(16) }}>{title}</div>
    </div>
  );
}

function TitleTwo({ title }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '15px 0',
      }}
    >
      <div style={{ width: 20, height: 1, backgroundColor: theme.colors.textLightgray }} />
      <div
        style={{
          ...theme.fonts.medium(14),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 10px',
        }}
      >
        <div>{title}</div>
      </div>
      <div style={{ width: 20, height: 1, backgroundColor: theme.colors.textLightgray }} />
    </div>
  );
}

export default RiskQueryReportPage;
