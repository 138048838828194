import './css/report.css';
import 'antd/dist/antd.css';

import { Descriptions } from 'antd';
import React, { Fragment, useMemo } from 'react';

import theme from '@/utils/theme';

const DURATION_ENUM = {
  d7: '近 7 天',
  d15: '近 15 天',
  m1: '近 1 个月',
  m3: '近 3 个月',
  m6: '近 6 个月',
  m12: '近 12 个月',
};
const BEFORELOAN_TOTALLOAN_LASTTYPE = {
  a: '传统银行',
  b: '网络零售银行',
  c: '持牌网络小贷',
  d: '持牌小贷',
  e: '持牌消费金融',
  f: '持牌融资租赁',
  g: '持牌汽车金融',
  h: '其他',
};
const BEFORELOAN_TOTALLOAN_AMOUNT = {
  a: '0到5',
  b: '5到10',
  c: '10到20',
  d: '20到30',
  e: '30到40',
  f: '40到50',
  g: '50到60',
  h: '60到70',
  i: '70到80',
  j: '80到90',
  k: '90到100',
  l: '100以上',
};

export function BeforeLoanReport({ beforeLoanReport }) {
  return (
    <Fragment>
      <div style={{ height: 15 }} />
      <TitleOne title={'反欺诈数据产品'} />
      <CourtExecuteePro beforeLoanReport={beforeLoanReport} />
      <CourtJudgmentWrit beforeLoanReport={beforeLoanReport} />
      <SpecialListVerification beforeLoanReport={beforeLoanReport} />
      <DebitIntentVerification beforeLoanReport={beforeLoanReport} />
      <DebitRiskSurvay beforeLoanReport={beforeLoanReport} />
      <TitleTwo title={'团伙欺诈排查-通用版'} />
      {!!beforeLoanReport.fraudRelationG?.frgListLevel &&
      !!beforeLoanReport.fraudRelationG?.frgGroupNumDesc ? (
        <Descriptions bordered>
          <Descriptions.Item label="风险等级">
            {beforeLoanReport.fraudRelationG?.frgListLevel}
          </Descriptions.Item>
          <Descriptions.Item label="团伙规模">
            {beforeLoanReport.fraudRelationG?.frgGroupNumDesc}
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <NoData title="团伙欺诈排查-通用版" />
      )}
      <div>{'注：'}</div>
      <div>{'1. 风险等级取值区间为3-10，取值越大，风险越高。'}</div>
      <div>{'2. 团伙规模指同一风险等级对应群组规模的取值。'}</div>
      <TitleTwo title={'偿债压力指数'}></TitleTwo>
      <Descriptions bordered>
        <Descriptions.Item label="偿债压力指数">
          {beforeLoanReport.debtRepayStress?.drsNoDebtScore}
        </Descriptions.Item>
      </Descriptions>
      <div>{'注：'}</div>
      <div>{'1. 用户本人当前偿债压力指数的情况，取值为0 - 100，数值越大，压力越大。'}</div>

      <TitleTwo title={'身份证号手机号归属地'}></TitleTwo>
      <Descriptions bordered>
        <Descriptions.Item label="身份证所属省">
          {beforeLoanReport.keyAttribution?.idProvince}
        </Descriptions.Item>
        <Descriptions.Item label="身份证所属市">
          {beforeLoanReport.keyAttribution?.idCity}
        </Descriptions.Item>
        <Descriptions.Item label="手机所属省">
          {beforeLoanReport.keyAttribution?.cellProvince}
        </Descriptions.Item>
        <Descriptions.Item label="手机所属市">
          {beforeLoanReport.keyAttribution?.cellCity}
        </Descriptions.Item>
        <Descriptions.Item label="手机卡类型">
          {beforeLoanReport.keyAttribution?.cellCardType}
        </Descriptions.Item>
      </Descriptions>
      <TotalLoan beforeLoanReport={beforeLoanReport} />
    </Fragment>
  );
}

/** 法院被执行人-高级版 */
function CourtExecuteePro({ beforeLoanReport }) {
  return (
    <Fragment>
      <TitleTwo title={'法院被执行人-高级版'} />

      <div style={{ marginBottom: 6 }}>命中失信被执行人</div>
      {beforeLoanReport.executionPro?.badList?.length > 0 ? (
        <table className="tables">
          <thead>
            <tr>
              <th>序号</th>
              <th>名称</th>
              <th>案号</th>
              <th>省份</th>
              <th>立案时间</th>
              <th>发布时间</th>
              <th>个人匹配度</th>
              <th>五级分类</th>
            </tr>
          </thead>
          <tbody>
            {beforeLoanReport.executionPro?.badList?.map((item, i) => (
              <Fragment key={item.caseNum + item.time}>
                <tr>
                  <td>{i + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.caseNum}</td>
                  <td>{item.address}</td>
                  <td>{item.time}</td>
                  <td>{item.postTime}</td>
                  <td>{item.matchRatio}</td>
                  <td>{item.eventLevel}</td>
                </tr>
                <tr>
                  <td colSpan={8}>
                    <Descriptions size="small">
                      <Descriptions.Item label="法院名称">{item.court}</Descriptions.Item>
                      <Descriptions.Item label="履行情况">{item.performance}</Descriptions.Item>
                      <Descriptions.Item label="依据单位">{item.baseCompany}</Descriptions.Item>
                      <Descriptions.Item label="依据文号">{item.base}</Descriptions.Item>
                      <Descriptions.Item label="具体情形">
                        {item.concreteSituation}
                      </Descriptions.Item>
                      <Descriptions.Item label="义务">{item.obligation}</Descriptions.Item>
                    </Descriptions>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <NoData title="命中失信被执行人" />
      )}
      <div style={{ marginTop: 10 }}>注：</div>
      <div>1. 个人匹配度：取值0~1。值越大，表示匹配程度越高。</div>
      <div>
        2.
        五级分类：警告、负向、中性、正向、利好。在司法数据中只有警告、负向、中性三级。其中警告表示较高行政单位作出的判决，或金额较大，或为刑事类案件等；负向表示一般案件，涉及金额较小，未造成严重后果；中性有可能没有金额判定。
      </div>

      <div style={{ margin: '12px 0 6px 0' }}>命中法院被执行人</div>
      {beforeLoanReport.executionPro?.executList?.length > 0 ? (
        <table className="tables">
          <thead>
            <tr>
              <th>序号</th>
              <th>名称</th>
              <th>案号</th>
              <th>法院名称</th>
              <th>立案日期</th>
              <th>执行金额</th>
              <th>案件状态</th>
              <th>个人匹配度</th>
              <th>五级分类</th>
            </tr>
          </thead>
          <tbody>
            {beforeLoanReport.executionPro?.executList?.map((item, i) => (
              <Fragment key={item.caseNum + item.time}>
                <tr>
                  <td>{i + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.caseNum}</td>
                  <td>{item.court}</td>
                  <td>{item.time}</td>
                  <td>{item.money}</td>
                  <td>{item.statute}</td>
                  <td>{item.matchRatio}</td>
                  <td>{item.eventLevel}</td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <NoData title="命中法院被执行人" />
      )}
      <div style={{ marginTop: 10 }}>注：</div>
      <div>1. 个人匹配度：取值0~1。值越大，表示匹配程度越高。</div>
      <div>
        2.
        五级分类：警告、负向、中性、正向、利好。在司法数据中只有警告、负向、中性三级。其中警告表示较高行政单位作出的判决，或金额较大，或为刑事类案件等；负向表示一般案件，涉及金额较小，未造成严重后果；中性有可能没有金额判定。
      </div>
    </Fragment>
  );
}

/** 法院裁判文书 */
function CourtJudgmentWrit({ beforeLoanReport }) {
  return (
    <Fragment>
      <TitleTwo title={'法院裁判文书'} />
      {beforeLoanReport.executionJud?.judList?.length > 0 ? (
        <table className="tables">
          <thead>
            <tr>
              <th>序号</th>
              <th>法院名称</th>
              <th>法院等级</th>
              <th>案由</th>
              <th>审理程序</th>
              <th>五级分类</th>
              <th>匹配度</th>
            </tr>
          </thead>
          <tbody>
            {beforeLoanReport.executionJud?.judList?.map((item, i) => (
              <Fragment key={item.caseNo + item.sortTimeString}>
                <tr>
                  <td>{i + 1}</td>
                  <td>{item.court}</td>
                  <td>{item.courtRank}</td>
                  <td>{item.caseCause}</td>
                  <td>{item.trialProcedure}</td>
                  <td>{item.eventLevel}</td>
                  <td>{item.matchRatio}</td>
                </tr>
                <tr>
                  <td colSpan={8} style={{ padding: 8 }}>
                    <table className="tables">
                      <thead>
                        <tr>
                          <th>当事人名称</th>
                          <th>当事人类型</th>
                          <th>当事人立场</th>
                          <th>当事人称号</th>
                          <th>判决金额</th>
                          <th>结果</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.personList?.map((person) => (
                          <Fragment key={person.pname}>
                            <tr>
                              <td>{person.pname}</td>
                              <td>{person.partyType}</td>
                              <td>{person.partyPosition}</td>
                              <td>{person.partyTitle}</td>
                              <td>{person.partyFee}</td>
                              <td>{person.partyResult}</td>
                            </tr>
                          </Fragment>
                        ))}
                      </tbody>
                    </table>

                    <Descriptions size="small">
                      <Descriptions.Item label="审结时间">{item.sortTimeString}</Descriptions.Item>
                      <Descriptions.Item label="案号">{item.caseNo}</Descriptions.Item>
                      <Descriptions.Item label="文书类型">{item.caseTypeS}</Descriptions.Item>
                      <Descriptions.Item label="内容" span={3}>
                        {item.body}
                      </Descriptions.Item>
                      <Descriptions.Item label="判决结果" span={3}>
                        {item.judgeResult}
                      </Descriptions.Item>
                      <Descriptions.Item label="依据" span={3}>
                        {item.foundation}
                      </Descriptions.Item>
                    </Descriptions>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <NoData title="法院裁判文书" />
      )}
    </Fragment>
  );
}

/** 特殊名单验证 */
function SpecialListVerification({ beforeLoanReport }) {
  const details = beforeLoanReport.specialListC?.hitRuleDetails;

  return (
    <Fragment>
      <TitleTwo title={'特殊名单验证'} />
      {Object.keys(details || {}).length > 0 ? (
        <table className="tables">
          <thead>
            <tr>
              <th>序号</th>
              <th>命中特殊名单</th>
              <th>取值结果</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(details || {}).map((detail, i) => (
              <tr key={detail}>
                <td>{i + 1}</td>
                <td>{detail}</td>
                <td>{details[detail]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <NoData title="特殊名单验证" />
      )}
    </Fragment>
  );
}

/** 借贷意向验证 */
function DebitIntentVerification({ beforeLoanReport }) {
  const data = beforeLoanReport.applyLoanStr;

  const bankEnum = {
    bank: {
      title: '银行',
      customerTypeEnum: {
        none: '银行汇总',
        tra: '传统银行',
        ret: '网络零售银行',
      },
    },
    nbank: {
      title: '非银',
      customerTypeEnum: {
        none: '非银汇总',
        sloan: '持牌小贷',
        nsLoan: '持牌网络小贷',
        cons: '持牌消费金融',
        finLea: '持牌融资租赁',
        autoFin: '持牌汽车金融',
        oth: '其他',
      },
    },
  };
  const businessTypeEnum = {
    rel: '信用卡（类信用卡）',
    caOn: '线上现金分期',
    caOff: '线下现金分期',
    pdl: '线上小额现金贷',
    af: '汽车金额',
    coOn: '线上消费分期',
    coOff: '线下消费分期',
    oth: '其他',
  };
  const periodEnum = { night: '夜间', week: '周末' };

  return (
    <Fragment>
      <TitleTwo title={'借贷意向验证'} />
      <div>
        借贷意向数据覆盖大部分的金融机构。机构类型包括银行、改制机构、小贷、消费类分期、现金类分期、代偿类分期和非银其它。
      </div>

      <div style={{ margin: '12px 0 6px 0' }}>本人在本机构借贷意向表现</div>
      {!data?.tableSelfNull ? (
        <table className="tables">
          <thead>
            <tr>
              <th>申请次数</th>
              {Object.keys(DURATION_ENUM).map((duration) => (
                <th key={duration}>{DURATION_ENUM[duration]}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(bankEnum).map((type) => (
              <tr key={type}>
                <td>{bankEnum[type].title}</td>
                {Object.keys(DURATION_ENUM).map((duration) => (
                  <td key={duration}>
                    {data?.[duration]?.id?.[type]?.selfNum} /{' '}
                    {data?.[duration]?.cell?.[type]?.selfNum}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <NoData title="本人在本机构借贷意向表现" />
      )}
      <div style={{ marginTop: 10 }}>{'注：'}</div>
      <div>
        {
          '1. 取值结果展示：按身份证号查询命中次数/按手机号查询命中次数。如："1/2"表示按身份证号查询命中1次，按手机号查询命中2次。'
        }
      </div>
      <div>
        {
          '2. 取值为"空" "0" "N"——空：无申请记录；0：本机构无申请记录但其他非银机构有申请；N：申请次数详情。'
        }
      </div>

      <div style={{ margin: '12px 0 6px 0' }}>本人在各个客户类型借贷意向表现</div>
      {!data?.tableCustomerTypeNull ? (
        <table className="tables">
          <DebitIntentVerificationTableHeader title="客户类型" enums={DURATION_ENUM} />
          <tbody>
            {Object.keys(bankEnum).map((bank) => {
              const { customerTypeEnum } = bankEnum[bank];
              return (
                <Fragment key={bank}>
                  {Object.keys(customerTypeEnum).map((customerType) => (
                    <tr key={customerType}>
                      <td>{customerTypeEnum[customerType]}</td>
                      {Object.keys(DURATION_ENUM).map((duration) => (
                        <Fragment key={duration}>
                          <td>
                            {data?.[duration]?.id?.[bank]?.[customerType]?.orgNum} /{' '}
                            {data?.[duration]?.cell?.[bank]?.[customerType]?.orgNum}
                          </td>
                          <td>
                            {data?.[duration]?.id?.[bank]?.[customerType]?.allNum} /{' '}
                            {data?.[duration]?.cell?.[bank]?.[customerType]?.allNum}
                          </td>
                        </Fragment>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      ) : (
        <NoData title="本人在各个客户类型借贷意向表现" />
      )}
      <div style={{ marginTop: 10 }}>{'注：'}</div>
      <div>
        {
          '1. 取值结果展示：按身份证号查询命中次数/按手机号查询命中次数。如："1/2"表示按身份证号查询命中1次，按手机号查询命中2次。'
        }
      </div>
      <div>{'2. 取值为"空" "N"——空：无申请记录；N：申请记录详情。'}</div>

      <div style={{ margin: '12px 0 6px 0' }}>本人在各个业务类型借贷意向表现</div>
      {!data?.tableBusinessTypeNull ? (
        <table className="tables">
          <DebitIntentVerificationTableHeader title="业务类型" enums={DURATION_ENUM} />
          <tbody>
            {Object.keys(businessTypeEnum).map((businessType) => (
              <tr key={businessType}>
                <td>{businessTypeEnum[businessType]}</td>
                {Object.keys(DURATION_ENUM).map((duration) => (
                  <Fragment key={duration}>
                    <td>
                      {data?.[duration]?.id?.[businessType]?.orgNum} /{' '}
                      {data?.[duration]?.cell?.[businessType]?.orgNum}
                    </td>
                    <td>
                      {data?.[duration]?.id?.[businessType]?.allNum} /{' '}
                      {data?.[duration]?.cell?.[businessType]?.allNum}
                    </td>
                  </Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <NoData title="本人在各个业务类型借贷意向表现" />
      )}
      <div style={{ marginTop: 10 }}>{'注：'}</div>
      <div>
        {
          '1. 取值结果展示：按身份证号查询命中次数/按手机号查询命中次数。如："1/2"表示按身份证号查询命中1次，按手机号查询命中2次。'
        }
      </div>
      <div>{'2. 取值"空" "N"——空：无申请记录；N：申请记录详情。'}</div>

      <div style={{ margin: '12px 0 6px 0' }}>本人在异常时间段借贷意向表现</div>
      {!data?.tableExceptionTimeNull ? (
        <table className="tables">
          <DebitIntentVerificationTableHeader title="时间-机构" enums={DURATION_ENUM} />
          <tbody>
            {Object.keys(periodEnum).map((period) => {
              return (
                <Fragment key={period}>
                  {Object.keys(bankEnum).map((bank) => (
                    <tr key={bank}>
                      <td>
                        {periodEnum[period]}-{bankEnum[bank].title}
                      </td>
                      {Object.keys(DURATION_ENUM).map((duration) => (
                        <Fragment key={duration}>
                          <td>
                            {data?.[duration]?.id?.[bank]?.[period]?.orgNum} /{' '}
                            {data?.[duration]?.cell?.[bank]?.[period]?.orgNum}
                          </td>
                          <td>
                            {data?.[duration]?.id?.[bank]?.[period]?.allNum} /{' '}
                            {data?.[duration]?.cell?.[bank]?.[period]?.allNum}
                          </td>
                        </Fragment>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      ) : (
        <NoData title="本人在异常时间段借贷意向表现" />
      )}
      <div style={{ marginTop: 10 }}>{'注：'}</div>
      <div>
        {
          '1. 取值结果展示：按身份证号查询命中次数/按手机号查询命中次数。如："1/2"表示按身份证号查询命中1次，按手机号查询命中2次。'
        }
      </div>
      <div>{'2. 取值"空" "N"——空：无申请记录；N：申请记录详情。'}</div>
    </Fragment>
  );
}

function DebitIntentVerificationTableHeader({ title, enums }) {
  return (
    <thead>
      <tr>
        <th rowSpan={2}>{title}</th>
        {Object.keys(enums).map((duration) => (
          <th key={duration} colSpan={2}>
            {enums[duration]}
          </th>
        ))}
      </tr>
      <tr>
        {Object.keys(enums).map((duration) => (
          <Fragment key={duration}>
            <th>机构数</th>
            <th>次数</th>
          </Fragment>
        ))}
      </tr>
    </thead>
  );
}

/** 借贷风险勘测 */
function DebitRiskSurvay({ beforeLoanReport }) {
  const durationEnum = { ...DURATION_ENUM, y1: '1 年以前' };
  const typeEnum = {
    totMonNum: '月份数',
    avgMonNum: '月平均申请数',
    maxMonNum: '最大月申请次数',
    minMonNum: '最小月申请次数',
  };

  return (
    <Fragment>
      <TitleTwo title={'借贷风险勘测'}></TitleTwo>

      <div style={{ margin: '12px 0 6px 0' }}>借贷风险勘测-查基础信息命中详情</div>
      {!beforeLoanReport.applyLoanUsury?.tableBaseNull ? (
        <table className="tables">
          <thead>
            <tr>
              <th>命中借贷风险勘测信息</th>
              <th>按身份证号查询</th>
              <th>按手机号查询</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>最近申请距今天数</td>
              <td>{beforeLoanReport.applyLoanUsury?.fst?.id?.inteday} 天</td>
              <td>{beforeLoanReport.applyLoanUsury?.fst?.cell?.inteday} 天</td>
            </tr>
            <tr>
              <td>最早申请距今天数</td>
              <td>{beforeLoanReport.applyLoanUsury?.lst?.id?.inteday} 天</td>
              <td>{beforeLoanReport.applyLoanUsury?.lst?.cell?.inteday} 天</td>
            </tr>
          </tbody>
        </table>
      ) : (
        <NoData title="借贷风险勘测-查基础信息命中详情" />
      )}

      <div style={{ margin: '12px 0 6px 0' }}>借贷风险勘测-查机构数、次数命中详情</div>
      {!beforeLoanReport.applyLoanUsury?.tableTimeNull ? (
        <table className="tables">
          <thead>
            <tr>
              <th>命中借贷风险勘测信息</th>
              {Object.keys(durationEnum).map((duration) => (
                <th key={duration}>{durationEnum[duration]}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>身份证号查询的申请次数</td>
              {Object.keys(durationEnum).map((duration) => (
                <td key={duration}>{beforeLoanReport.applyLoanUsury?.[duration]?.id?.allNum}</td>
              ))}
            </tr>
            <tr>
              <td>身份证号查询的申请机构数</td>
              {Object.keys(durationEnum).map((duration) => (
                <td key={duration}>{beforeLoanReport.applyLoanUsury?.[duration]?.id?.orgNum}</td>
              ))}
            </tr>
            <tr>
              <td>手机号查询的申请次数</td>
              {Object.keys(durationEnum).map((duration) => (
                <td key={duration}>{beforeLoanReport.applyLoanUsury?.[duration]?.cell?.allNum}</td>
              ))}
            </tr>
            <tr>
              <td>手机号查询的申请机构数</td>
              {Object.keys(durationEnum).map((duration) => (
                <td key={duration}>{beforeLoanReport.applyLoanUsury?.[duration]?.cell?.orgNum}</td>
              ))}
            </tr>
          </tbody>
        </table>
      ) : (
        <NoData title="借贷风险勘测-查机构数、次数命中详情" />
      )}

      <div style={{ margin: '12px 0 6px 0' }}>借贷风险勘测-查月份数、月申请次数命中详情</div>
      {!beforeLoanReport.applyLoanUsury?.tableMonthNull ? (
        <table className="tables">
          <thead>
            <tr>
              <th>命中借贷风险勘测信息</th>
              {['m3', 'm6', 'm12'].map((duration) => (
                <th key={duration}>{durationEnum[duration]}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[
              ['id', '身份证'],
              ['cell', '手机'],
            ].map(([type, title]) =>
              Object.keys(typeEnum).map((type2) => (
                <tr key={type + type2}>
                  <td>
                    {title}号查询有申请的{typeEnum[type2]}
                  </td>
                  {['m3', 'm6', 'm12'].map((duration) => (
                    <td key={duration}>
                      {beforeLoanReport.applyLoanUsury?.[duration]?.[type]?.[type2]}
                    </td>
                  ))}
                </tr>
              )),
            )}
          </tbody>
        </table>
      ) : (
        <NoData title="借贷风险勘测-查月份数、月申请次数命中详情" />
      )}
    </Fragment>
  );
}

/** 借贷行为验证 */
function TotalLoan({ beforeLoanReport }) {
  const data = beforeLoanReport.totalLoan;

  return (
    <Fragment>
      <TitleTwo title={'借贷行为验证'} />
      <div>借贷行为验证数据覆盖大部分的金融机构，是虚拟信贷联盟中的借贷行为情况。</div>

      <div style={{ margin: '12px 0 6px 0' }}>借贷行为验证——命中基础信息</div>
      {data?.last ? (
        <table className="tables">
          <thead>
            <tr>
              <th>命中借贷行为信息</th>
              <th>按身份证号查询</th>
              <th>按手机号查询</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>最近一次线上小额现金贷借贷时间</td>
              <td>{data.last.id.lasttime || '--'}</td>
              <td>{data.last.cell.lasttime || '--'}</td>
            </tr>
            <tr>
              <td>最近一次申请线上小额现金贷的机构类型</td>
              <td>
                {data.last.id.lasttype
                  ? BEFORELOAN_TOTALLOAN_LASTTYPE[data.last.id.lasttype] || '--'
                  : '--'}
              </td>
              <td>
                {data.last.cell.lasttype
                  ? BEFORELOAN_TOTALLOAN_LASTTYPE[data.last.cell.lasttype] || '--'
                  : '--'}
              </td>
            </tr>
            <tr>
              <td>当前在非银机构借贷等级</td>
              <td>
                {data.last.id.nowamount
                  ? BEFORELOAN_TOTALLOAN_AMOUNT[data.last.id.nowamount] || '--'
                  : '--'}
              </td>
              <td>
                {data.last.cell.nowamount
                  ? BEFORELOAN_TOTALLOAN_AMOUNT[data.last.cell.nowamount] || '--'
                  : '--'}
              </td>
            </tr>
            <tr>
              <td>线上小额现金贷借贷次数</td>
              <td>{data.last.id.pdlAllnum || '--'}次</td>
              <td>{data.last.cell.pdlAllnum || '--'}次</td>
            </tr>
            <tr>
              <td>线上小额现金贷借贷机构数</td>
              <td>{data.last.id.pdlOrgnum || '--'}个</td>
              <td>{data.last.cell.pdlOrgnum || '--'}个</td>
            </tr>
          </tbody>
        </table>
      ) : (
        <NoData title="借贷行为验证——命中基础信息" />
      )}
      <div style={{ marginTop: 10 }}>{'注：'}</div>
      <div>
        {`当前在非银机构借贷等级的取值返回为系数，取值为0—100，系数越大，代表借贷等级越高。`}
      </div>

      <div style={{ margin: '12px 0 6px 0' }}>借贷行为验证——非银机构详情信息</div>
      {data?.last ? (
        <TotalLoanTable
          data={data}
          columns={[
            {
              title: '借贷等级',
              field: 'allamount',
              format: (value) => BEFORELOAN_TOTALLOAN_AMOUNT[value],
            },
            { title: '借贷机构数', field: 'allorgnum' },
            {
              title: '应还款等级',
              field: 'payamount',
              format: (value) => BEFORELOAN_TOTALLOAN_AMOUNT[value],
            },
          ]}
        />
      ) : (
        <NoData title="借贷行为验证——非银机构详情信息" />
      )}
      <div style={{ marginTop: 10 }}>{`注：`}</div>
      <div>
        {`1. 取值结果展示：按身份证号查询命中次数 / 按手机号查询命中次数，如："1/2"表示按身份证号查询命中1次，按手机号查询命中2次。`}
      </div>
      <div>
        {`2. 借贷等级、应还款等级的取值返回为系数，取值为0—100，系数越大，代表借贷等级和应还款等级越高。`}
      </div>
      <div>
        {`3. 第一个月是指自查询日起（不含当日）第1天到第360天的历史数据；第二个月是指自查询日起（不含当日）第31天到第360天的历史数据，以此类推。`}
      </div>

      <div style={{ height: 12 }} />
      {data?.last ? (
        <TotalLoanTable
          data={data}
          columns={[
            { title: '新增申请次数', field: 'newallnum' },
            { title: '新增申请机构数', field: 'neworgnum' },
            {
              title: '新增核准借贷等级',
              field: 'passamount',
              format: (value) => BEFORELOAN_TOTALLOAN_AMOUNT[value],
            },
            { title: '新增核准借贷次数', field: 'passnum' },
          ]}
        />
      ) : (
        <NoData title="借贷行为验证——非银机构详情信息" />
      )}
      <div style={{ marginTop: 10 }}>{`注：`}</div>
      <div>
        {`1. 取值结果展示：按身份证号查询命中次数 / 按手机号查询命中次数，如："1/2"表示按身份证号查询命中1次，按手机号查询命中2次。`}
      </div>
      <div>
        {`2. 新增核准借贷等级的取值返回为系数，取值为0—100，系数越大，代表新增核准借贷等级越高。`}
      </div>
      <div>
        {`3. 第一个月是指自查询日起（不含当日）第1天到第30天的历史数据；第二个月是指自查询日起（不含当日）第31天到第60天的历史数据，以此类推。`}
      </div>
    </Fragment>
  );
}

function TotalLoanTable({ data, columns }) {
  return (
    <table className="tables">
      <thead>
        <tr>
          <th colSpan={2}>非银机构</th>
          {columns.map((column) => (
            <th key={column.title}>{column.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array(12)
          .fill()
          .map((_, i) => i)
          .map((i) => {
            return (
              <tr key={i}>
                {i === 0 && <td rowSpan={12}>时间</td>}
                <td>第{i + 1}个月</td>
                {columns.map((column) => {
                  const formatter = (value) =>
                    value ? (column.format ? column.format(value) : value) || '--' : '--';
                  return (
                    <td key={column.title}>
                      {formatter(data[`m${i + 1}`]?.id[column.field])} /{' '}
                      {formatter(data[`m${i + 1}`]?.cell[column.field])}
                    </td>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}

function HitRules({ title, hitRules }) {
  return (
    <Fragment>
      <TitleTwo title={title} />
      {hitRules?.length > 0 ? (
        <table className="tables">
          <thead>
            <tr>
              <th>命中规则集</th>
              <th>权重</th>
              <th>命中规则详情</th>
              <th>取值结果</th>
            </tr>
          </thead>
          <tbody>
            {hitRules?.map((ruleSet) =>
              ruleSet.detailList.map((detail, i) => (
                <tr key={ruleSet.rule + detail.code}>
                  {i === 0 && (
                    <Fragment>
                      <td rowSpan={ruleSet.detailList.length}>{ruleSet.rule}</td>
                      <td rowSpan={ruleSet.detailList.length}>
                        <Weight weight={ruleSet.weight} />
                      </td>
                    </Fragment>
                  )}
                  <td>{detail.detail}</td>
                  <td>
                    {detail.value?.split('\n').map((line) => (
                      <p key={line}>{line}</p>
                    ))}
                  </td>
                </tr>
              )),
            )}
          </tbody>
        </table>
      ) : (
        <NoData title={title} />
      )}
    </Fragment>
  );
}

function NoData({ title }) {
  return (
    <table className="tables">
      <tbody>
        <tr>
          <td style={{ border: 'none', textAlign: 'left' }}>查询成功，无{title}相关信息</td>
        </tr>
      </tbody>
    </table>
  );
}

function Weight({ weight }) {
  const color = useMemo(() => {
    const value = parseFloat(weight);
    if (value >= 0 && value <= 30) {
      return '#55C559';
    }
    if (value > 30 && value <= 50) {
      return '#D0803E';
    }
    if (value > 50 && value <= 100) {
      return '#D34646';
    }
    return null;
  }, [weight]);

  return (
    <div
      className="flex-row justify-center align-center"
      style={{ width: 30, height: 20, borderRadius: 4, background: color, color: 'white' }}
    >
      {weight}
    </div>
  );
}
function TitleOne({ title }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <div style={{ width: 6, height: 20, background: '#4377F5', marginRight: 10 }} />
      <div style={{ ...theme.fonts.regular(16) }}>{title}</div>
    </div>
  );
}

function TitleTwo({ title }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '15px 0',
      }}
    >
      <div style={{ width: 20, height: 1, backgroundColor: theme.colors.textLightgray }} />
      <div
        style={{
          ...theme.fonts.medium(14),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 10px',
        }}
      >
        <div>{title}</div>
      </div>
      <div style={{ width: 20, height: 1, backgroundColor: theme.colors.textLightgray }} />
    </div>
  );
}
