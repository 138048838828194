import React from 'react';

export default function BottomBanner(props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 40,
        paddingBottom: 40,
      }}
    >
      <div
        style={{
          width: 50,
          height: 0.5,
          backgroundColor: '#dddddd',
        }}
      />
      <div
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          color: '#dddddd',
          fontSize: 12,
          fontWeight: 300,
        }}
      >
        {props.content || '全国专业超跑运营'}
      </div>
      <div
        style={{
          width: 50,
          height: 0.5,
          backgroundColor: '#dddddd',
        }}
      />
    </div>
  );
}
