import qs from 'qs';
import React from 'react';
import autoBind from 'react-autobind';
import { Link } from 'react-router-dom';
import ui from 'src/utils/ui';

import api2 from '@/utils/api2';
import theme from '@/utils/theme';
import Card from '@/widgets/Card';

class AuthenticationPage extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.navprops = this.props.match.params || {};
    this.query = qs.parse(props.location.search.substr(1));
    this.state = {
      codeModalVisible: false,
      name: '',
      cell: '',
      verificationCode: '',
      idCard: '',
      isChecked: false,
      sending: false,
      smsCounter: 0,
      submitting: false,
      smsTitle: '获取验证码',
    };
  }
  async init() {
    try {
      const result = await api2.get('cloud-svr/front/riskQuery/checkRiskQueryCode', {
        code: this.query?.code,
      });
    } catch (ex) {
      console.warn('AuthenticationPage.checkRiskQueryCode:', ex);
      ui.toastError(ex);
    }
  }

  componentDidMount() {
    this.init();
    setTimeout(() => {
      this.setState({ submitting: false });
    }, 888);
  }
  componentWillUnmount() {
    clearTimeout(this.smsTimer);
  }

  render() {
    const isCanSubmit =
      this.state.name && this.state.cell && this.state.verificationCode && this.state.idCard;
    const u = navigator.userAgent;
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    return (
      <div
        style={{
          minHeight: isIOS ? document.body.clientHeight : '100vh',
          background: 'rgba(68,120,245,0.05)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {this.renderHeader()}
        <div
          style={{
            position: 'relative',
          }}
        >
          <div>
            <img
              alt=""
              src={require('src/images/banner.jpg')}
              style={{ width: '100vw', display: 'block' }}
            />
          </div>

          <div
            style={{ position: 'absolute', top: 'calc((100vw * 153) / 414)', left: 15, right: 15 }}
          >
            <Card
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: 0,
                padding: '22px 15px 22px 15px',
                borderRadius: 8,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  background: 'rgba(68,120,245,0.05)',
                  borderRadius: 8,
                  height: 53,
                }}
              >
                <div
                  style={{
                    ...theme.fonts.medium(14, 53),
                    width: 86,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  我的姓名
                </div>
                <input
                  placeholder="请输入真实姓名"
                  value={this.state.name}
                  onChange={(event) => this.setState({ name: event.target.value })}
                  style={{
                    marginRight: 10,
                    fontSize: this.state.name ? 15 : 14,
                    flex: 1,
                    outline: 'none',
                    height: 40,
                    border: 'none',
                    background: 'transparent',
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',

                  flexDirection: 'row',
                  alignItems: 'center',
                  background: 'rgba(68,120,245,0.05)',
                  borderRadius: 8,
                  height: 53,
                  marginTop: 13,
                }}
              >
                <div
                  style={{
                    ...theme.fonts.medium(14, 53),
                    width: 86,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  手机号码
                </div>
                <input
                  placeholder="请输入手机号码"
                  type="number"
                  value={this.state.cell}
                  onChange={(event) => this.setState({ cell: event.target.value })}
                  style={{
                    fontSize: this.state.cell ? 15 : 14,
                    marginRight: 10,
                    flex: 1,
                    outline: 'none',
                    height: 40,
                    border: 'none',
                    background: 'transparent',
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  background: 'rgba(68,120,245,0.05)',
                  borderRadius: 8,
                  height: 53,
                  marginTop: 13,
                }}
              >
                <div
                  style={{
                    ...theme.fonts.medium(14, 53),
                    width: 86,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  验证码
                </div>
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <input
                    placeholder="请输入验证码"
                    type="number"
                    value={this.state.verificationCode}
                    onChange={(event) => this.setState({ verificationCode: event.target.value })}
                    style={{
                      margin: 0,
                      marginRight: 10,
                      fontSize: this.state.verificationCode ? 15 : 14,
                      width: 100,

                      outline: 'none',
                      height: 40,

                      border: 'none',
                      background: 'transparent',
                      paddingRight: 0,
                    }}
                  />
                  <div
                    style={{
                      ...theme.fonts.regular(12),
                      width: 100,
                      height: 30,
                      borderRadius: 15,
                      background: this.state.cell
                        ? this.state.sending || this.state.smsCounter > 0
                          ? '#D6D6D6'
                          : '#4478F5'
                        : 'rgba(68,120,245,0.45)',
                      marginRight: 16,
                      color: '#FFFFFF',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={this.requestSmsCode}
                  >
                    {this.state.sending
                      ? '发送中……'
                      : this.state.smsCounter > 0
                      ? `${this.state.smsCounter}s重新获取`
                      : `${this.state.smsTitle}`}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  background: 'rgba(68,120,245,0.05)',
                  borderRadius: 8,
                  height: 53,
                  marginTop: 13,
                }}
              >
                <div
                  style={{
                    ...theme.fonts.medium(14, 53),
                    width: 86,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  身份证号
                </div>
                <input
                  placeholder="请输入身份证号"
                  value={this.state.idCard}
                  onChange={(event) => this.setState({ idCard: event.target.value })}
                  style={{
                    fontSize: this.state.idCard ? 15 : 14,
                    marginRight: 10,
                    flex: 1,
                    outline: 'none',
                    height: 40,
                    border: 'none',
                    background: 'transparent',
                  }}
                />
              </div>
            </Card>
            <div
              style={{
                marginTop: 14,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div onClick={() => this.setState({ isChecked: !this.state.isChecked })}>
                <img
                  style={{ marginRight: 7, width: 11, height: 11 }}
                  alt=""
                  src={
                    this.state.isChecked
                      ? require('src/images/icon_checkbox_checked.svg')
                      : require('src/images/icon_checkbox.svg')
                  }
                />
              </div>

              <div style={{ ...theme.fonts.regular(11) }}>
                使用本服务意味您已阅读并同意签署《
                <Link to="/m/BrAuthorization" style={{ color: '#4377F5' }}>
                  信息查询授权书
                </Link>
                》
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: 250 }} />
        <div style={{ flex: 1 }} />

        <div
          style={{
            ...theme.fonts.regular(15),
            color: '#FFFFFF',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: 44,
            borderRadius: 22,
            backgroundColor:
              !isCanSubmit || this.state.submitting ? 'rgba(68,120,245,0.2)' : '#4478F5',
            margin: '0px 42px 40px 42px',
          }}
          onClick={() => {
            if (!isCanSubmit) {
              return;
            }
            this.submit();
          }}
        >
          提交认证信息
        </div>
      </div>
    );
  }
  async submit() {
    try {
      if (this.state.submitting) {
        return;
      }

      if (!this.state.isChecked) {
        ui.toastError('请勾选信息查询授权书');
        return;
      }

      this.setState({ submitting: true });
      const response = await api2.post('cloud-svr/front/riskQuery/riskQuery', {
        code: this.query.code,
        verificationCode: this.state.verificationCode,
        name: this.state.name,
        cell: this.state.cell,
        idCard: this.state.idCard,
      });
      this.props.history.push('/m/OnSuccess');
    } catch (error) {
      console.log('AuthenticationPage riskQuery error:', error);
      ui.toastError(error);
    } finally {
      this.setState({ submitting: false });
    }
  }

  requestSmsCode() {
    if (this.state.sending || this.state.smsCounter > 0 || !this.state.cell) {
      return;
    }

    this.setState({ sending: true }, async () => {
      try {
        await api2.post('cloud-svr/front/riskQuery/sendVerificationCode', {
          cell: this.state.cell,
          code: this.query.code,
        });
        this.updateSmsTimer(60);
      } catch (error) {
        console.warn('request sms code error:', error);
        ui.toastError(error);
      } finally {
        this.setState({ sending: false });
      }
    });
  }

  updateSmsTimer(counter) {
    this.setState({ smsCounter: counter }, () => {
      if (counter > 0) {
        this.smsTimer = setTimeout(() => this.updateSmsTimer(counter - 1), 1000);
      }
      if (counter == 0) {
        this.setState({ smsTitle: '重新获取' });
      }
    });
  }
  renderHeader() {
    const HEADER_HEIGHT = 44;

    return (
      <div
        style={{
          width: '100vw',
          height: HEADER_HEIGHT,
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            height: HEADER_HEIGHT,
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              ...theme.fonts.medium(15),
              textAlign: 'center',
            }}
          >
            提交认证信息
          </div>
        </div>
      </div>
    );
  }
}

export default AuthenticationPage;
