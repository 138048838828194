import React from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import store, * as storeOps from 'src/utils/store';
import ui from 'src/utils/ui';
import AssetImage from 'src/widgets/AssetImage';

import api2 from '@/utils/api2';
import format from '@/utils/format';
import theme from '@/utils/theme';
import MyList from '@/widgets/MyList';

@connect((state) => ({
  searchRecord: state.searchRecord,
}))
class RiskQueryRecordPage extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.navprops = this.props.match.params || {};

    this.state = {
      riskRecordLists: this.props.searchRecord.riskRecordLists || [],
      totalCount: 0,
      useCount: 0,
      name: null,
      nullIssue: '请搜索风控查询记录',
      sortDirection: this.props.searchRecord.sortDirection,
      searchKeywords: this.props.searchRecord.searchKeywords,
      pageIndex: this.props.searchRecord.pageIndex,
      hasNext: this.props.searchRecord.hasNext,
      canRequest: this.props.searchRecord.canRequest,
    };
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    try {
      const result = await api2.get('cloud-svr/back/riskQuery/queryRiskPlanDetail');
      const { res = {} } = result?.data;
      this.setState({ name: res?.name, totalCount: res?.totalCount, useCount: res?.useCount });
    } catch (ex) {
      console.warn('RiskQueryRecordPage.queryRiskPlanDetail:', ex);
      ui.toastError(ex);
      if (ex.message === '未登录') {
        this.props.history.replace('/m/Login');
      }
    }
  }

  async loadRiskRecord() {
    const { pageIndex, searchKeywords, sortDirection } = this.state;
    try {
      if (!this.state.hasNext) return;
      if (!this.state.canRequest) return;
      this.setState({ nullIssue: '加载数据中' });
      const result =
        (
          await api2.get('cloud-svr/back/riskQuery/findRiskQueryRecordModelPage', {
            searchKeywords,
            sortDirection,
            pageIndex: pageIndex,
            pageSize: 20,
          })
        ).data?.res || {};

      this.setState((prevState) => ({
        nullIssue: pageIndex === 1 && result?.beanList?.length === 0 ? '暂无符合的记录信息' : '',
        riskRecordLists: [...(pageIndex > 1 ? prevState.riskRecordLists : []), ...result?.beanList],
        hasNext: result.hasNext,
        pageIndex: prevState.pageIndex + 1,
        canRequest: false,
      }));
    } catch (ex) {
      console.warn('RiskQueryRecordPage.findRiskQueryRecordModelPage:', ex);
      ui.toastError(ex);
      if (ex.message === '未登录') {
        this.props.history.replace('/m/Login');
      }
    } finally {
      this.setState({ canRequest: true });
    }
  }

  render() {
    const u = navigator.userAgent;
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    return (
      <div
        style={{
          margin: '0 auto',
          maxWidth: 600,
          minHeight: isIOS ? document.body.clientHeight : '100vh',
          background: 'rgba(68,120,245,0.05)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {this.renderHeader()}
        <div
          style={{
            padding: '15px 15px 6px 15px',
            background: '#fff',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                borderRadius: 15,
                padding: '0px 15px',
                backgroundColor: 'rgba(68,120,245,0.05)',
              }}
            >
              <div style={{ width: 15 }}>
                <AssetImage src={require('src/images/icon_search@2x.png')} />
              </div>
              <input
                placeholder="搜索姓名/手机号/身份证号"
                value={this.state.searchKeywords}
                onChange={(event) => this.setState({ searchKeywords: event.target.value })}
                style={{
                  margin: '4px 10px',
                  fontSize: this.state.searchKeywords ? 15 : 14,
                  flex: 1,
                  outline: 'none',
                  height: 30,
                  border: 'none',
                  background: 'transparent',
                }}
              />
            </div>
            <div
              style={{
                ...theme.fonts.regular(16),
                width: 60,
                textAlign: 'center',
              }}
              onClick={() => {
                this.setState({ pageIndex: 1, hasNext: true, canRequest: true }, () => {
                  this.loadRiskRecord();
                });
              }}
            >
              搜索
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              height: 20,
              marginTop: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              onClick={() => {
                this.setState(
                  {
                    sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc',
                    pageIndex: 1,
                    hasNext: true,
                    canRequest: true,
                  },
                  () => {
                    this.loadRiskRecord();
                  },
                );
              }}
            >
              <div
                style={{
                  width: 12,
                  height: 6,
                  backgroundColor: this.state.sortDirection === 'desc' ? '#a8a8a8' : '#4377F5',
                  '-webkit-mask': `url(${require('src/images/icon_sort_up.svg')}) no-repeat center`,
                  mask: `url(${require('src/images/icon_sort_up.svg')}) no-repeat center`,
                }}
              />
              <div
                style={{
                  width: 12,
                  height: 6,
                  marginTop: 2,
                  backgroundColor: this.state.sortDirection === 'desc' ? '#4377F5' : '#a8a8a8',
                  '-webkit-mask': `url(${require('src/images/icon_sort_down.svg')}) no-repeat center`,
                  mask: `url(${require('src/images/icon_sort_down.svg')}) no-repeat center`,
                }}
              />
            </div>

            <div style={{ ...theme.fonts.regular(12), marginLeft: 6 }}>
              {this.state.sortDirection == 'desc' ? '报告生成时间从新到旧' : '报告生成时间从旧到新'}
            </div>
          </div>
        </div>
        <div
          style={{
            ...theme.fonts.regular(14),
            padding: '10px 15px',
          }}
        >
          <div>• 已购套餐：{this.state.name}</div>
          <div
            style={{ marginTop: 8 }}
          >{`• 套餐已用：${this.state.useCount}/${this.state.totalCount}`}</div>
        </div>
        {this.state.riskRecordLists.length === 0 && (
          <div
            style={{
              marginTop: 200,
              ...theme.fonts.regular(20),
              color: theme.colors.textGray,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {' '}
            {this.state.nullIssue}
          </div>
        )}
        {this.state.riskRecordLists.length > 0 && (
          <div
            style={{
              padding: 15,
              backgroundColor: 'white',
            }}
          >
            <MyList
              hasMore={this.state.hasNext}
              onLoadMore={this.loadRiskRecord}
              bottomBannerDisabled
            >
              {this.state.riskRecordLists.map((item, index) => {
                return (
                  <div
                    key={item.riskQueryRecordId}
                    style={{ paddingTop: 10 }}
                    onClick={() => this.toRiskQueryReport(item.riskQueryRecordId)}
                  >
                    <div
                      style={{
                        ...theme.fonts.regular(14),
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div>{`${item.name}(${item.cell})`}</div>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div>查询结果：</div>
                        <div
                          style={{
                            color:
                              item.wagonsResult === '建议通过'
                                ? '#339999'
                                : item.wagonsResult === '建议拒绝'
                                  ? '#ff6e6e'
                                  : '#d0813e',
                          }}
                        >
                          {item.wagonsResult}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        ...theme.fonts.regular(12),
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 10,
                      }}
                    >
                      <div>{`报告编号：${item.riskQueryRecordId}`}</div>
                      <div>{`报告生成时间：${format.time(item.evaluateTime)}`}</div>
                    </div>
                    <div style={{ marginTop: 10, height: 0.6, backgroundColor: '#d7d7d7' }} />
                  </div>
                );
              })}
            </MyList>
          </div>
        )}

        <div style={{ flex: 1 }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '20px 42px 20px 42px',
            margin: '0 auto',
          }}
        >
          <img
            alt=""
            src={require('src/images/bottom.png')}
            style={{ width: '100%', maxWidth: 400, height: 'auto', display: 'block' }}
          />
        </div>
      </div>
    );
  }
  renderHeader() {
    const HEADER_HEIGHT = 44;

    return (
      <div
        style={{
          width: '100vw',
          height: HEADER_HEIGHT,
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            height: HEADER_HEIGHT,
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div
              onClick={() => {
                store.dispatch(
                  storeOps.setSearchRecord({
                    riskRecordLists: [],
                    searchKeywords: '',
                    sortDirection: 'desc',
                    pageIndex: 1,
                    hasNext: true,
                  }),
                );
                this.props.history.goBack();
              }}
              className="flex-row justify-center align-center"
            >
              <img
                style={{ marginLeft: 20, height: 15 }}
                alt=""
                src={require('src/images/icon_chevron_left.svg')}
              />
            </div>
          </div>
          <div
            style={{
              ...theme.fonts.medium(15),
              textAlign: 'center',
            }}
          >
            风控查询记录
          </div>
        </div>
      </div>
    );
  }

  toRiskQueryReport(riskQueryRecordId) {
    store.dispatch(
      storeOps.setSearchRecord({
        searchKeywords: this.state.searchKeywords,
        sortDirection: this.state.sortDirection,
        pageIndex: this.state.pageIndex,
        hasNext: this.state.hasNext,
        riskRecordLists: this.state.riskRecordLists,
        canRequest: false,
      }),
    );
    this.props.history.push(`/m/RiskQueryReport/${riskQueryRecordId}`);
  }
}

export default RiskQueryRecordPage;
