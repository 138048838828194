import { produce } from 'immer';
import update from 'immutability-helper';
import qs from 'qs';
import { createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

const initialPersisted = {
  token: null,
  merchantName: null,
  name: null,
  cell: null,
  mail: null,

  unionId: null,
  openId: null,

  uuid: null,
  // 长登录客户编号，统计用
  userId: null,

  cityId: 101,
  customer: null,

  // TODO DEPRECATED 以后就用 userAgent 来区分了
  embed: null,
  cities: {
    list: [{ id: 101, name: '北京' }],
    map: { 101: { id: 101, name: '北京' } },
  },
  enums: {
    customerGrade: { list: [], map: {} },
    customerStatus: { list: [], map: {} },
    feeType: { list: [], map: {} },
    orderRentStatus: { list: [], map: {} },
    orderRentType: { list: [], map: {} },
    payDirectionType: { list: [], map: {} },
    payMode: { list: [], map: {} },
    paymentFinancialStatus: { list: [], map: {} },
    peerStatus: { list: [], map: {} },
    rechargeOrderStatus: { list: [], map: {} },
    taskType: { list: [], map: {} },
    userMessageSubType: { list: [], map: {} },
    userMessageType: { list: [], map: {} },
    vehicleTrustStatus: { list: [], map: {} },
    vehicleTrustOrderType: { list: [], map: {} },
    vehicleSource: { list: [], map: {} },
    updatedAt: 0,
  },
  consults: [],
  vehicleBrands: { list: [], map: {} },
  //搜索合同记录条件
  searchContractRecord: {
    searchKeywords: '',
    sortDirection: 'desc',
    sortProperty: 'createdAt',
    status: 'ALL',
    activeId: null,
    pageIndex: 1,
    hasNext: true,
    contractLists: [],
  },
};

const initialState = {
  persisted: initialPersisted,
  currentScreen: null,
  onBack: null,

  inApp: false, // DEPRECATED
  adParams: {},

  channelName: null,
  channelRemark: null,

  commonModal: null,

  /**
   * PC 版车型列表页的筛选条件（哦，移动官网好像也一样，那就共用这个存储吧）
   *
   * 需求：
   *
   * - 从车辆列表页返回时，应保留各个搜索条件；
   * - 手动刷新页面后，应清空所有搜索条件。
   *
   * 所以就把搜索条件放在这边吧，应该能满足要求。
   */
  pcDrivingSelf: {
    vehicleTypeName: '全部',
    cityId: 101,
    priceSort: null,
    seatsCount: null,
    baseInsuranceOpen: null,
    advanceInsuranceOpen: null,
    paginationData: { current: 1, pageSize: 20 },
    scrollY: 0,
  },

  //搜索风控记录条件
  searchRecord: {
    searchKeywords: '',
    sortDirection: 'desc',
    pageIndex: 1,
    hasNext: true,
    riskRecordLists: [],
    canRequest: true,
  },
};

const SAVE_SCREEN = 'SAVE_SCREEN';
const ON_BACK = 'ON_BACK';

const SAVE_UUID = 'SAVE_UUID';
const SAVE_AD_PARAMS = 'SAVE_AD_PARAMS';
const SAVE_CHANNEL_REMARK = 'SAVE_CHANNEL_REMARK';

const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const LOAD_UNIONID = 'LOAD_UNIONID';
const CHANGE_CITY = 'CHANGE_CITY';
const LOAD_CUSTOMER = 'LOAD_CUSTOMER';
const LOAD_MERCHANT_ACCOUNT = 'LOAD_MERCHANT_ACCOUNT';

const IN_APP = 'IN_APP';
const LOAD_CITIES = 'LOAD_CITIES';
const LOAD_ENUMS = 'LOAD_ENUMS';
const LOAD_CONSULTS = 'LOAD_CONSULTS';

export function saveScreen(screenName) {
  return {
    type: SAVE_SCREEN,
    screenName,
  };
}

export function onBack(onBack) {
  return { type: ON_BACK, onBack };
}

export function saveUuid(uuid) {
  return {
    type: SAVE_UUID,
    uuid,
  };
}

export function saveAdParams(searchString) {
  return {
    type: SAVE_AD_PARAMS,
    searchString,
  };
}

export function saveChannelRemark(channelRemark) {
  return {
    type: SAVE_CHANNEL_REMARK,
    channelRemark,
  };
}

export const login = (token) => ({ type: LOGIN, token });

export function logout() {
  return { type: LOGOUT };
}

export function loadUnionId(ids = {}) {
  return { type: LOAD_UNIONID, ...ids };
}

export function changeCity(cityId) {
  return {
    type: CHANGE_CITY,
    cityId,
  };
}
window.globalStoreSetCityId = changeCity;

export const loadCustomer = (customer) => ({ type: LOAD_CUSTOMER, customer });
export const loadMerchantAccount = (merchantAccount) => ({
  type: LOAD_MERCHANT_ACCOUNT,
  merchantAccount,
});

export function inApp() {
  return { type: IN_APP };
}

export function loadCities(cities) {
  return {
    type: LOAD_CITIES,
    cities,
  };
}

export function loadEnums(enums, updatedAt) {
  return {
    type: LOAD_ENUMS,
    enums: Object.assign({}, enums, {
      updatedAt: updatedAt || new Date().getTime(),
    }),
  };
}

export function loadConsults(consults) {
  return {
    type: LOAD_CONSULTS,
    consults,
  };
}

const actionHandlers = {};

const SET_OPEN_ID = 'SET_OPEN_ID';
export const setOpenId = (openId) => ({ type: SET_OPEN_ID, openId });
actionHandlers[SET_OPEN_ID] = produce((draft, action) => {
  draft.persisted.openId = action.openId !== 'NONE' ? action.openId : null;
});

const SET_EMBED = 'SET_EMBED';
export function setEmbed(embed) {
  return { type: SET_EMBED, embed };
}
actionHandlers[SET_EMBED] = (state, action) => {
  return update(state, {
    persisted: {
      embed: { $set: action.embed },
    },
  });
};

const SET_CHANNEL_INFO = 'SET_CHANNEL_INFO';
export const setChannelInfo = (channelName, channelRemark) => ({
  type: SET_CHANNEL_INFO,
  channelName,
  channelRemark,
});
actionHandlers[SET_CHANNEL_INFO] = produce((draft, action) => {
  draft.channelName = action.channelName;
  draft.channelRemark = action.channelRemark;
});

const SET_COMMON_MODAL = 'SET_COMMON_MODAL';
export const setCommonModal = (modal) => ({ type: SET_COMMON_MODAL, modal });
actionHandlers[SET_COMMON_MODAL] = (state, action) => ({ ...state, commonModal: action.modal });

const SET_VEHICLE_BRANDS = 'SET_VEHICLE_BRANDS';
export function setVehicleBrands(vehicleBrands) {
  return { type: SET_VEHICLE_BRANDS, vehicleBrands };
}
actionHandlers[SET_VEHICLE_BRANDS] = (state, action) => {
  const theMap = {};
  for (const brand of action.vehicleBrands) {
    theMap[brand.id] = brand;
  }
  return update(state, {
    persisted: {
      vehicleBrands: {
        list: { $set: action.vehicleBrands },
        map: { $set: theMap },
      },
    },
  });
};

const SET_SEARCH_RECORD = 'SET_SEARCH_RECORD';
export const setSearchRecord = (params) => ({ type: SET_SEARCH_RECORD, params });
actionHandlers[SET_SEARCH_RECORD] = (state, action) => ({
  ...state,
  searchRecord: { ...state.searchRecord, ...action.params },
});

const SET_CONTRACT_RECORD = 'SET_CONTRACT_RECORD';
export const setContractRecord = (params) => ({ type: SET_CONTRACT_RECORD, params });

const SET_PC_DRIVING_SELF = 'SET_PC_DRIVING_SELF';
export const setPcDrivingSelf = (params) => ({ type: SET_PC_DRIVING_SELF, params });
actionHandlers[SET_PC_DRIVING_SELF] = (state, action) => ({
  ...state,
  pcDrivingSelf: { ...state.pcDrivingSelf, ...action.params },
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_SCREEN:
      return update(state, {
        currentScreen: { $set: action.screenName },
      });

    case ON_BACK:
      return update(state, {
        onBack: { $set: action.onBack },
      });

    case SAVE_UUID:
      return update(state, {
        persisted: {
          uuid: { $set: action.uuid },
        },
      });

    case SAVE_AD_PARAMS:
      return update(state, {
        adParams: (oldParams) => {
          const newParams = qs.parse((action.searchString || '').slice(1));
          const result = Object.assign(
            {},
            oldParams,
            ...Object.getOwnPropertyNames(newParams)
              .filter((field) => field.startsWith('_'))
              .map((field) => ({ [field]: newParams[field] })),
          );
          return result;
        },
      });
    case SAVE_CHANNEL_REMARK:
      return update(state, {
        channelRemark: { $set: action.channelRemark },
      });

    // eslint-disable-next-line no-fallthrough

    case LOGIN:
      return update(state, {
        persisted: {
          token: { $set: action.token },
        },
      });

    case LOGOUT:
      return update(state, {
        persisted: {
          token: { $set: null },
          merchantName: { $set: null },
          name: { $set: null },
          cell: { $set: null },
          mail: { $set: null },
        },
      });

    case SET_CONTRACT_RECORD:
      return update(state, {
        persisted: {
          searchContractRecord: {
            $set: { ...initialPersisted.searchContractRecord, ...action.params },
          },
        },
      });

    case LOAD_UNIONID:
      return update(state, {
        persisted: {
          unionId: { $set: action.unionId },
          openId: { $set: action.openId },
        },
      });

    case CHANGE_CITY:
      return update(state, {
        persisted: {
          cityId: { $set: action.cityId },
        },
      });

    case LOAD_CUSTOMER:
      return update(state, {
        persisted: {
          userId: { $set: action.customer.inviteCode },
          customer: { $set: action.customer },
        },
      });

    case LOAD_MERCHANT_ACCOUNT:
      return update(state, {
        persisted: {
          merchantName: { $set: action.merchantAccount.merchantName },
          name: { $set: action.merchantAccount.name },
          cell: { $set: action.merchantAccount.cell },
          mail: { $set: action.merchantAccount.mail },
        },
      });

    case IN_APP:
      return update(state, {
        inApp: { $set: true },
      });

    case LOAD_CITIES:
      return update(state, {
        persisted: {
          // NOTICE 旧版应用存的旧版数据里可能没有新版应用后加的字段，所以这里要注意处理这种情况；
          // immutability-helper 明确说了它不会自动帮我们处理，因为 JS 本身的固有缺陷。
          cities: (cities) =>
            update(cities || initialPersisted.cities, {
              list: { $set: action.cities },
              map: {
                $set: action.cities.reduce((m, city) => {
                  m[city.id] = city;
                  return m;
                }, {}),
              },
            }),
        },
      });

    case LOAD_ENUMS:
      return update(state, {
        persisted: {
          enums: { $set: action.enums },
        },
      });

    case LOAD_CONSULTS:
      return update(state, {
        persisted: {
          consults: { $set: action.consults },
        },
      });

    default: {
      const handler = actionHandlers[action.type];
      if (handler) {
        return handler(state, action);
      }
      break;
    }
  }

  return state;
}

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    whitelist: ['persisted'],
    stateReconciler: autoMergeLevel2,
  },
  reducer,
);
const store = createStore(persistedReducer);
export const persistor = persistStore(store);

window.globalStore = store;
export default store;
