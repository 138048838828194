import { useLayoutEffect, useRef, useState } from 'react';

/** 自动计算并更新指定元素的宽高 */
export function useElementSize() {
  const ref = useRef(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (ref.current) {
      const newSize = ref.current.getBoundingClientRect();
      if (size.width !== newSize.width || size.height !== newSize.height) {
        setSize(newSize);
      }
    }
  });

  return [ref, size];
}
