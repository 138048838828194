import qs from 'qs';
import React from 'react';
import autoBind from 'react-autobind';
import ui from 'src/utils/ui';

import theme from '@/utils/theme';

export default class BrAuthorizationPage extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.navprops = this.props.match.params || {};
    this.query = qs.parse(props.location.search.substr(1));
  }

  render() {
    return (
      <div style={{ minHeight: '100vh', backgroundColor: this.query.bgColor || 'white' }}>
        {this.renderHeader()}
        <img
          alt=""
          src={ui.OSS_STATIC + '/frontweb/br_authorization.png'}
          style={{ display: 'flex', width: '100vw', height: 'auto' }}
        />
      </div>
    );
  }
  renderHeader() {
    const HEADER_HEIGHT = 44;

    return (
      <div
        style={{
          width: '100vw',
          height: HEADER_HEIGHT,
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            height: HEADER_HEIGHT,
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div
              onClick={() => {
                this.props.history.goBack();
              }}
              className="flex-row justify-center align-center"
            >
              <img
                style={{ marginLeft: 20, height: 15 }}
                alt=""
                src={require('src/images/icon_chevron_left.svg')}
              />
            </div>
          </div>
          <div
            style={{
              ...theme.fonts.medium(15),
              textAlign: 'center',
            }}
          >
            信息查询授权书
          </div>
        </div>
      </div>
    );
  }
}
