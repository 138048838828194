import 'animate.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'semantic-ui-css/semantic.min.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import autobind from 'autobind-decorator';
import update from 'immutability-helper';
import qs from 'qs';
import React from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { cssTransition, ToastContainer } from 'react-toastify';
import config from 'src/etc/config';
import logic from 'src/utils/logic';
import store, * as storeOps from 'src/utils/store';
import ui from 'src/utils/ui';

import CommonModal from './components/common/CommonModal';
import { PageTitle } from './widgets/PageTitle';

@connect((state) => ({
  commonModal: state.commonModal,
}))
class App extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.query = qs.parse((this.props.location.search || '').slice(1));

    this.checkToken();
  }

  componentDidMount() {
    logic.statLog({ eventId: 2001 });

    this.checkChannelData();
    this.removeNavListener = this.props.history.listen(this.onChangeHistory);

    this.init();
  }

  componentWillUnmount() {
    this.removeNavListener();
  }

  render() {
    return (
      <div>
        {!!this.query.title && <PageTitle title={this.query.title} />}

        <ToastContainer
          autoClose={4000}
          closeButton={false}
          transition={cssTransition({
            enter: 'slideInDown',
            exit: 'slideOutUp',
            duration: 300,
          })}
          hideProgressBar
          className="toastify-container"
          toastClassName="toastify-border"
          bodyClassName="toastify-body"
        />

        <Switch>
          <Route id="container" path="/m" component={require('src/pages/m/MobileRouter').default} />
          <Route path="/pc" component={require('src/pages/pc/DesktopRouter').default} />
          {/* 百度推广专用 */}
          <Route path="/pcPromotion" component={require('src/pages/pc/DesktopRouter').default} />
          <Route path="/" component={DeviceSwitcher} />
        </Switch>

        <CommonModal {...this.props.commonModal} onClose={() => {}} />
      </div>
    );
  }

  @autobind
  checkToken() {
    const query = qs.parse((this.props.location.search || '').slice(1));
    const unsetQueries = [];

    // NOTICE 通过 query params 来传初始数据的话，这些数据如果在 app 端有变化，会导致 webview 控件的
    // url 也跟着变，从而导致多余的浏览历史，进而导致多一次后退之类的问题。所以现在改成了通过
    // injectedJavaScriptBeforeContentLoaded 机制来传这些数据。
    //
    // 不过小程序没有类似的 webview 机制，所以原来的 query params 机制也要保留。
    const initialData = window._wagonsInitialData || {};

    const openId = initialData.openId || query.openId;
    if (openId) {
      store.dispatch(storeOps.setOpenId(openId));
      unsetQueries.push('openId');
    }

    const token = initialData.token || query.token;
    if (token != null) {
      if (token === 'NONE') {
        store.dispatch(storeOps.logout());
      } else {
        store.dispatch(storeOps.login(token, initialData.userId || query.userId));
      }
      unsetQueries.push('token', 'userId');
    }

    const channelName = initialData.channelName || query._c;
    const channelRemark = initialData.channelRemark || query._cr;
    if (channelName || channelRemark) {
      store.dispatch(storeOps.setChannelInfo(channelName, channelRemark));
      unsetQueries.push('_c', '_cr');
    }

    if (query.embed) {
      store.dispatch(storeOps.setEmbed(query.embed));
      unsetQueries.push('embed');
    }

    if (unsetQueries.length > 0) {
      this.props.history.replace(
        update(this.props.location, {
          search: {
            $set: `?${qs.stringify(update(query, { $unset: unsetQueries }))}`,
          },
        }),
      );
    }
  }

  checkChannelData() {
    store.dispatch(storeOps.saveAdParams(this.props.location.search));

    const query = qs.parse((this.props.location.search || '').slice(1));
    switch (query._channel) {
      case 'baidu':
        store.dispatch(storeOps.saveChannelRemark(query.keyword));
        break;
      default:
    }
  }

  onChangeHistory(location, action) {
    store.dispatch(storeOps.saveScreen(location.pathname));

    if (action === 'PUSH') {
      window.scrollTo(0, 0);

      logic.statLog({ eventId: 2001 });

      // 主要是为了配合广告指标统计，要自动把所有 _ 开头的 query params
      // 自动传给下一个页面。
      this.props.history.replace(
        update(location, {
          search: (oldSearch) => {
            const oldSearchObject = qs.parse((oldSearch || '').slice(1));
            const oldSearchFields = Object.getOwnPropertyNames(oldSearchObject);
            const adParams = store.getState().adParams || {};
            const newSearchObject = Object.assign(
              {},
              oldSearchObject,
              ...Object.getOwnPropertyNames(adParams)
                .filter((field) => !oldSearchFields.includes(field))
                .map((field) => ({ [field]: adParams[field] })),
            );
            return '?' + qs.stringify(newSearchObject);
          },
        }),
      );
    }

    store.dispatch(storeOps.saveAdParams(location.search));
  }

  @autobind
  init() {
    this.initOpeninstall();
  }

  @autobind
  initOpeninstall() {
    // 解析所有 query 参数并传给 openinstall
    // 其中 channelCode 会变成 channel，其他都装在 data 中，作为拉起参数 {channel, data} 传给 app
    const urlParams = window.OpenInstall?.parseUrlParams();
    console.log('openinstall parsed url params', urlParams);

    new window.OpenInstall(
      {
        appKey: config.openinstallAppKey,
        onready: function () {
          window.openinstall = this;
          console.log('openinstall instance', window.openinstall);
        },
      },
      urlParams,
    );
  }

  @autobind
  loadAppData() {
    const pagesDontNeedGlobalData = [
      '/m/loginForAssistance',
      '/m/assistanceSuccess',
      '/m/bindWeixinSuccess',
      '/m/poolAmountEmpty',
      '/m/activeEnd',
      '/m/PosterUlink',
    ];
    if (
      // 这个是 SPA 部署到 OSS 上时的路径改写规则
      pagesDontNeedGlobalData.includes(window.location.pathname.replace(/\/$/, ''))
    ) {
      return;
    }

    logic.loadAppData();
  }
}
export default withRouter(App);

class _DeviceSwitcher extends React.Component {
  render() {
    return null;
  }

  componentDidMount() {
    // 获取微信 openId
    // 之前是通过 nginx 配置，把正式环境的 /m-test/ 路径重定向到测试环境；
    // 现在改用 OSS 的部署方式了，就需要用代码来实现这个逻辑了。
    if (window.location.pathname?.startsWith('/m-test/')) {
      window.location.href = `https://test.web.wagons.cn${window.location.pathname.replace(
        /^\/m-test\//,
        '/m/',
      )}${window.location.search}`;
      return;
    }

    if (ui.inMobile()) {
      // NOTICE 必须要用这种原生的方式来跳转，不能用 react-router 带的 history API，
      // 不然微信分享会有问题。
      // 触发条件：ios；直接访问根路径。（直接访问 /m 就没问题）
      window.location.replace('/m');
    } else {
      window.location.replace('/pc');
    }
  }
}
const DeviceSwitcher = withRouter(_DeviceSwitcher);
