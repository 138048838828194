import canvasSize from 'canvas-size';
import React, { useCallback, useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import config from 'src/etc/config';
import ui from 'src/utils/ui';

import { useElementSize } from '@/utils/hooks';

/** 从 node_modules/pdfjs-dist/build/ 中获取的 */
pdfjs.GlobalWorkerOptions.workerSrc =
  'https://wagonsclub.oss-cn-beijing.aliyuncs.com/static/vendor/pdf.worker-5.7.2.min.js';

const MARGIN = 0;

export function PdfViewer({ ossId, url }) {
  const pdfUrl = useMemo(() => {
    if (url) return url;
    if (ossId) {
      const baseName = window.atob(ossId);
      return `${ui.OSS_STATIC.replace(/\/static/, '')}/${
        config.buildType === 'prod' ? '' : 'test/'
      }media/default/${baseName}`;
    }
    return null;
  }, [ossId, url]);

  const [pageCount, setPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [componentRef, componentSize] = useElementSize();
  const [containerRef, containerSize] = useElementSize();
  const [scale, setScale] = useState(window.devicePixelRatio);

  const onLoadFirstPage = useCallback(
    ({ originalWidth, originalHeight }) => {
      console.log(
        'PdfViewerPage.onLoadFirstPage:',
        { originalWidth, originalHeight },
        componentSize.width,
      );
      console.log('canvasSize.test', canvasSize.test({ width: 2880, height: 1620 }));
      const canvasLimit = Math.sqrt((16777216 - 216) / (originalWidth * originalHeight));
      setScale(
        (originalWidth *
          Math.min(
            1, // TODO 暂未搞定 canvas 清晰度问题
            window.devicePixelRatio,
            canvasLimit,
          )) /
          (componentSize.width - 2 * MARGIN),
      );
    },
    [componentSize.width],
  );

  return (
    <div
      ref={componentRef}
      style={{
        ...(containerSize?.height ? { height: containerSize?.height / scale + MARGIN } : {}),
      }}
    >
      <div
        ref={containerRef}
        style={{ transform: `scale(${1 / scale})`, transformOrigin: 'top left' }}
      >
        <Document
          file={pdfUrl}
          // - 默认的 canvas 模式可能会有 pixel 大小限制（16777216）导致显示不了，在 ios 上
          // - svg 模式在 android 上有些字体会显示不出来，变成方块
          // renderMode="svg"
          // options={{ maxImageSize: 16000000 }}
          loading=""
          noData="加载数据出错"
          error="加载数据出错"
          onLoadSuccess={(params) => {
            setPageCount(params.numPages);
            setTimeout(() => setIsLoading(false), 500);
          }}
          onLoadError={(error) => {
            ui.toastError(error);
            setTimeout(() => setIsLoading(false), 500);
          }}
        >
          {Array(pageCount)
            .fill()
            .map((_, i) => (
              <div
                key={i}
                style={{
                  margin: `0 ${MARGIN * scale}px ${MARGIN * scale}px ${MARGIN * scale}px`,
                }}
              >
                <Page
                  pageIndex={i}
                  loading=""
                  width={Math.floor((componentSize.width - 2 * MARGIN) * scale)}
                  {...(i === 0 ? { onLoadSuccess: onLoadFirstPage } : {})}
                />
              </div>
            ))}
        </Document>
      </div>
    </div>
  );
}
