import PropTypes from 'prop-types';
import PullToRefresh from 'pulltorefreshjs';
import React from 'react';
import autoBind from 'react-autobind';
import ReactDOMServer from 'react-dom/server';
import VisibilitySensor from 'react-visibility-sensor';
import BottomBanner from 'src/components/common/BottomBanner';
import styled from 'styled-components';

/**
 * @todo 还有一种情况没实现，当加载更多时触发下拉刷新，怎样取消加载更多然后刷新呢？
 *
 * @typedef {object} Props
 * @prop {function | undefined} onReload
 * @prop {function | undefined} onLoadMore
 * @prop {boolean} hasMore
 * @extends {React.Component<Props>}
 */
class MyList extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  static propTypes = {
    onReload: PropTypes.func,
    onLoadMore: PropTypes.func,
    hasMore: PropTypes.bool,
  };

  state = {
    refreshing: false,
    loadingMore: false,
  };

  render() {
    return (
      <div className={this.props.className} style={{ ...this.props.style }}>
        <div id={`ptr-${this.props.className.split(' ')[0]}`}>
          {this.props.children}
          {!this.state.refreshing && (
            <div>
              <VisibilitySensor onChange={this.onChangeBottomVisibility}>
                {this.props.hasMore ? (
                  <div
                    onClick={() => this.onChangeBottomVisibility(true)}
                    className="flex-row justify-center align-center"
                    style={{ height: 50 }}
                  >
                    {this.state.loadingMore ? (
                      <img
                        alt=""
                        src={require('src/images/icon_loading.gif')}
                        style={{
                          width: 'auto',
                          height: 32,
                        }}
                      />
                    ) : (
                      <div style={{ color: 'lightgray' }}>点击加载更多</div>
                    )}
                  </div>
                ) : !this.props.bottomBannerDisabled ? (
                  <BottomBanner content="已经到底啦" />
                ) : (
                  <div />
                )}
              </VisibilitySensor>
            </div>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    const mainElement = `#ptr-${this.props.className.split(' ')[0]}`;
    this.ptrObj = PullToRefresh.init({
      mainElement,
      iconArrow: ReactDOMServer.renderToString(
        <img
          alt=""
          src={require('src/images/icon_loading.gif')}
          style={{ width: 24, height: 'auto' }}
        />,
      ),
      iconRefreshing: ReactDOMServer.renderToString(
        <img
          alt=""
          src={require('src/images/icon_loading.gif')}
          style={{ width: 24, height: 'auto' }}
        />,
      ),
      instructionsPullToRefresh: ' ',
      instructionsReleaseToRefresh: ' ',
      instructionsRefreshing: ' ',
      onRefresh: this.onRefresh,
      shouldPullToRefresh: () => {
        return !window.scrollY && this.props.onReload;
      },
    });
  }

  componentWillUnmount() {
    this.ptrObj.destroy();
  }

  async onRefresh() {
    if (!this.state.refreshing && !this.state.loadingMore && this.props.onReload) {
      return new Promise((resolve) => {
        this.setState({ refreshing: true }, async () => {
          try {
            await this.props.onReload();
          } finally {
            this.setState({ refreshing: false });
            resolve();
          }
        });
      });
    }
  }

  onChangeBottomVisibility(isVisible) {
    if (
      (this.props.children || []).length > 0 &&
      isVisible &&
      this.props.hasMore &&
      !this.state.refreshing &&
      !this.state.loadingMore &&
      this.props.onLoadMore
    ) {
      this.setState({ loadingMore: true }, async () => {
        try {
          await this.props.onLoadMore();
        } finally {
          this.setState({ loadingMore: false });
        }
      });
    }
  }
}

export default styled(MyList)`
  .ptr--ptr {
    font-size: 40px;
  }

  .ptr--text {
    display: none;
  }
`;
