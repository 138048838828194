import qs from 'qs';
import React from 'react';
import autoBind from 'react-autobind';

import theme from '@/utils/theme';

class OnSuccessPage extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.navprops = this.props.match.params || {};
  }

  componentDidMount() {}

  render() {
    return (
      <div
        style={{
          minHeight: document.body.clientHeight,
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {this.renderHeader()}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 55,
            }}
          >
            <img
              alt=""
              src={require('src/images/icon_onSuccess.svg')}
              style={{ width: 80, height: 80, display: 'block' }}
            />
          </div>
          <div style={{ ...theme.fonts.medium(16), marginTop: 20 }}>已提交</div>

          <div
            style={{
              ...theme.fonts.regular(15),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 34,
              color: 'rgba(0,0,0,0.6)',
            }}
          >
            认证信息已提交，请联系商家继续操作
          </div>
        </div>
      </div>
    );
  }

  renderHeader() {
    const HEADER_HEIGHT = 44;

    return (
      <div
        style={{
          width: '100vw',
          height: HEADER_HEIGHT,
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            height: HEADER_HEIGHT,
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              ...theme.fonts.medium(15),
              textAlign: 'center',
            }}
          >
            提交成功
          </div>
        </div>
      </div>
    );
  }
}

export default OnSuccessPage;
