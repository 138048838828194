import React from 'react';
import autoBind from 'react-autobind';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import { Route, Switch } from 'react-router-dom';

export default class DesktopRouter extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    return (
      <CacheSwitch>
        <Route path="/pc" exact component={require('src/pages/m/IndexPage').default} />
        {/* 云服务 */}
        <Route path="/pc/Login" component={require('src/pages/m/LoginPage').default} />
        <Route path="/pc/RiskQuery" component={require('src/pages/m/RiskQueryPage').default} />
        <Route
          path="/pc/BrAuthorization"
          component={require('src/pages/m/BrAuthorizationPage').default}
        />
        <Route
          path="/pc/Authentication"
          component={require('src/pages/m/AuthenticationPage').default}
        />
        <Route path="/pc/OnSuccess" component={require('src/pages/m/OnSuccessPage').default} />
        <Route
          path="/pc/RiskQueryRecord"
          component={require('src/pages/m/RiskQueryRecordPage').default}
        />
      </CacheSwitch>
    );
  }
}
