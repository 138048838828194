import colorAlpha from 'color-alpha';
import React, { Fragment } from 'react';
import { Modal } from 'semantic-ui-react';
import theme from 'src/utils/theme';

export function PopupModal({
  isVisible,
  onClose,
  title,
  isClosingButtonDisabled,
  children,
  titleStyle,
  closingButtonStyle,
  modalStyle,
  closingIconStyle,
}) {
  return (
    <Modal
      open={!!isVisible}
      onClose={onClose}
      centered={false}
      dimmer={{
        // 只是示例下 dimmer.style 要怎么写，具体在这里其实没啥效果，真正有效果的是下边的 width。
        style: {
          padding: 0,
          backgroundColor: 'rgba(0,0,0,0.45)',
        },
      }}
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        margin: 0,
        width: '100%',
        borderRadius: '0 0 0 0',
        ...modalStyle,
      }}
    >
      {title ? (
        <Fragment>
          <div style={{ height: 50 }} />
          <div
            className="flex-row align-center"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: 50,
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                flex: 1,
                margin: '0 15px',
                ...theme.fonts.medium(14),
                color: 'black',
                ...titleStyle,
              }}
            >
              {title}
            </div>

            {!isClosingButtonDisabled && (
              <div
                onClick={onClose}
                className="flex-column"
                style={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  right: 0,
                  padding: '0 15px 2px 15px',
                  justifyContent: 'center',
                  ...closingButtonStyle,
                }}
              >
                <ClosingButton closingIconStyle={closingIconStyle} />
              </div>
            )}
          </div>
        </Fragment>
      ) : (
        <div style={{ height: 12 }} />
      )}

      {children}

      {!title && !isClosingButtonDisabled && (
        <div onClick={onClose} style={{ position: 'absolute', top: 0, right: 0, padding: 16 }}>
          <ClosingButton closingIconStyle={closingIconStyle} />
        </div>
      )}
    </Modal>
  );
}

function ClosingButton({ closingIconStyle }) {
  return (
    <div
      style={{
        width: 12,
        height: 12,
        backgroundColor: '#4377F5',
        WebkitMask: `url(${require('@/images/popup_close.svg')}) no-repeat center`,
        mask: `url(${require('@/images/popup_close.svg')}) no-repeat center`,
        ...closingIconStyle,
      }}
    />
  );
}
