import classNames from 'classnames';
import colorAlpha from 'color-alpha';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useConstant from 'use-constant';
import { proxy } from 'valtio';

import api2 from '@/utils/api2';
import theme from '@/utils/theme';
import ui from '@/utils/ui';
import format from '@/wagons-clientlib/format';
import { useLocalStoreContext } from '@/wagons-clientlib/utils';
import { PopupModal } from '@/widgets/PopupModal';
import { useData_contract_queryContractPlanDetail } from '@/wagons-clientlib/data/front/cloud/contract';

const LocalStoreContext = React.createContext({});

class LocalStore {
  modal = null;
  isSubmitting = false;

  async submitSingerInfo(hooksData) {
    const { history } = hooksData;

    try {
      this.isSubmitting = true;

      const { modal } = this;

      if (!modal.name) throw new Error('请补充乙方信息后提交确认');
      if (!modal.cell) throw new Error('请补充乙方信息后提交确认');
      if (!/^\d{11}$/.test(modal.cell)) throw new Error('请补充乙方信息后提交确认');
      if (!modal.idNumber) throw new Error('请补充乙方信息后提交确认');
      if (!/^\d{17}[\dXx]$/.test(modal.idNumber)) throw new Error('请补充乙方信息后提交确认');
      if (modal.signerType === 'ORGANIZATION') {
        if (!modal.orgName) throw new Error('请补充乙方信息后提交确认');
        if (!modal.uscc) throw new Error('请补充乙方信息后提交确认');
      }

      const result = await api2.post(
        `/cloud-svr/back/contract/${
          { PERSONAL: 'createPersonalAccount', ORGANIZATION: 'createOrgAccount' }[modal.signerType]
        }`,
        modal,
      );

      // 注意这两种跳转方式的区别：
      // - 第一种：普通浏览器跳转，对应了新建合同页面中的 useBeforeunload 机制，也是目前再用的；
      // - 第二种：react-router-dom 跳转机制，对应了新建合同页面中的 Prompt 机制，目前还有点儿问题。
      window.location.href =
        '/m/ContractEditor?' +
        format.query({
          contractSignerId: result.data.res?.contractSignerId,
        });
      // history.push(
      //   '/m/ContractEditor?' +
      //     format.query({
      //       contractSignerId: result.data.res?.contractSignerId,
      //     }),
      // );
    } catch (ex) {
      console.warn('NewContractButton.submitSingerInfo error: ', ex);
      ui.toastError(ex);
    } finally {
      this.isSubmitting = false;
    }
  }
}

function useSubmitSingerInfo() {
  const history = useHistory();
  const [localStore] = useLocalStoreContext(LocalStoreContext);
  return () => localStore.submitSingerInfo({ history });
}

export function NewContractButton(props) {
  const localStore = useConstant(() => proxy(new LocalStore()));

  return (
    <LocalStoreContext.Provider value={localStore}>
      <ComponentContent {...props} />
    </LocalStoreContext.Provider>
  );
}

function ComponentContent(props) {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);

  const { contractPlanDetail = {} } = useData_contract_queryContractPlanDetail();

  const openModal = useCallback(() => {
    if (!(contractPlanDetail.totalCount - contractPlanDetail.useCount) > 0) {
      ui.toastError('该功能需先进行主体资质认证，请联系对接人提交相关资质信息');
      return;
    }

    localStore.modal = {
      isVisible: true,
      signerType: 'PERSONAL',
    };
  }, [contractPlanDetail.totalCount, contractPlanDetail.useCount, localStore]);

  return (
    <Fragment>
      <button
        onClick={openModal}
        className={props.className}
        style={{
          border: 'none',
          outline: 'none',
          background: 'transparent',
          padding: 0,
          ...props.style,
        }}
      >
        {props.children}
      </button>

      <Modal />
    </Fragment>
  );
}

function Modal() {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);
  const { modal } = snap;

  const submitSingerInfo = useSubmitSingerInfo();

  return (
    <PopupModal
      isVisible={modal?.isVisible}
      onClose={() => {
        localStore.modal = null;
      }}
      title="选择合同类型"
    >
      <div className="flex-column" style={{ margin: '0 27px 20px 27px' }}>
        <div
          className="flex-row align-center"
          style={{ marginBottom: 6, justifyContent: 'space-between' }}
        >
          <SignerTypeRadio title="乙方为个人" type="PERSONAL" />
          <SignerTypeRadio title="乙方为公司" type="ORGANIZATION" />
        </div>

        {modal?.signerType === 'PERSONAL' && <PersonalInputs />}
        {modal?.signerType === 'ORGANIZATION' && <OrgInputs />}
      </div>

      <div
        className="flex-row"
        style={{ marginTop: 20, borderTop: `0.33px solid ${colorAlpha('black', 0.3)}` }}
      >
        <button
          onClick={() => {
            localStore.modal = null;
          }}
          disabled={snap.isSubmitting}
          style={{
            flex: 1,
            border: 'none',
            outline: 'none',
            background: 'white',
            padding: '20px 0 calc(max(20px, env(safe-area-inset-bottom))) 0',
            opacity: snap.isSubmitting ? 0.2 : 1,
            ...theme.fonts.medium(15),
            color: theme.colors.newBlack,
          }}
        >
          取消
        </button>

        <button
          onClick={() => submitSingerInfo()}
          disabled={snap.isSubmitting}
          style={{
            flex: 1,
            border: 'none',
            outline: 'none',
            background: 'white',
            padding: '20px 0 calc(max(20px, env(safe-area-inset-bottom))) 0',
            opacity: snap.isSubmitting ? 0.2 : 1,
            ...theme.fonts.medium(15),
            color: theme.colors.cloudPrimary,
          }}
        >
          确认
        </button>
      </div>
    </PopupModal>
  );
}

function SignerTypeRadio({ title, type }) {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);
  const { modal } = snap;

  const isSelected = useMemo(() => modal?.signerType === type, [modal?.signerType, type]);

  return (
    <button
      onClick={() => {
        localStore.modal.signerType = type;
      }}
      disabled={isSelected}
      style={{
        width: 160,
        height: 32,
        borderRadius: 88,
        border: 'none',
        outline: 'none',
        background: isSelected ? '#4377F5' : colorAlpha('#4377F5', 0.12),
        ...theme.fonts.regular(14),
        color: isSelected ? 'white' : '#4377F5',
      }}
    >
      {title}
    </button>
  );
}

function PersonalInputs() {
  return (
    <Fragment>
      <EditorField
        title={
          <Fragment>
            姓<span style={{ color: 'transparent' }}>占位</span>名
          </Fragment>
        }
        placeholder="输入乙方姓名（必填）"
        field="name"
      />

      <EditorField
        title="手机号码"
        placeholder="输入乙方手机号码（必填）"
        keyboardType="tel"
        field="cell"
      />

      <EditorField
        title="身份证号"
        placeholder="输入乙方身份证号（必填）"
        keyboardType="url"
        field="idNumber"
      />

      <EditorField style={{ opacity: 0 }} />
      <EditorField style={{ opacity: 0 }} />
    </Fragment>
  );
}

function OrgInputs() {
  return (
    <Fragment>
      <EditorField
        title="公司名称"
        placeholder="输入公司名称（必填）"
        field="orgName"
        titleStyle={{ width: '5em' }}
      />

      <EditorField
        title="公司税号"
        placeholder="请录入统一社会信用代（必填）"
        field="uscc"
        titleStyle={{ width: '5em' }}
      />

      <EditorField
        title="签署人姓名"
        placeholder="输入乙方签署人姓名（必填）"
        field="name"
        titleStyle={{ width: '5em' }}
      />

      <EditorField
        title="手机号码"
        placeholder="输入乙方签署人手机号码（必填）"
        keyboardType="tel"
        field="cell"
        titleStyle={{ width: '5em' }}
      />

      <EditorField
        title="身份证号"
        placeholder="输入乙方签署人身份证号（必填）"
        keyboardType="url"
        field="idNumber"
        titleStyle={{ width: '5em' }}
      />
    </Fragment>
  );
}

/**
 * @param {{
 *   title: string;
 *   style: React.CSSProperties;
 *   titleStyle: React.CSSProperties;
 * }} props
 * @returns
 */
function _EditorField({ title, placeholder, keyboardType, field, className, style, titleStyle }) {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);
  const { modal } = snap;

  return (
    <div
      className={classNames(className, 'flex-row align-center')}
      style={{ marginTop: 14, ...style }}
    >
      <div
        style={{
          ...theme.fonts.medium(14),
          color: theme.colors.newBlack,
          ...titleStyle,
        }}
      >
        {title}
      </div>

      <input
        placeholder={placeholder}
        type={keyboardType || 'text'}
        value={modal?.[field]}
        onChange={(e) => {
          localStore.modal[field] = e.target.value?.trim();
        }}
        style={{
          flex: 1,
          marginLeft: 12,
          height: 48,
          borderRadius: 8,
          border: 'none',
          outline: 'none',
          background: colorAlpha(theme.colors.cloudPrimary, 0.05),
          padding: '0 15px',
          ...theme.fonts.regular(14),
          color: theme.colors.newBlack,
        }}
      />
    </div>
  );
}
const EditorField = styled(_EditorField)`
  ${theme.styles.inputPlaceholder(colorAlpha('black', 0.3))}
`;
