import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import config from 'src/etc/config';
import api from 'src/utils/api';
import api2 from 'src/utils/api2';
import constants from 'src/utils/constants';
import store, * as storeOps from 'src/utils/store';
import urlTools from 'url';
import { v4 as uuidv4 } from 'uuid';

import frontUtils from '@/wagons-clientlib/frontUtils';

import ui from './ui';

const logic = {};

logic.loadAppDataCancelTokenSource = axios.CancelToken.source();

logic.loadAppData = () => {
  loadCities();
  loadEnums();
  loadVehicleBrands();
};

async function loadCities() {
  try {
    const result = await api2.get('/city/getCityModelList', null, {
      axios: { cancelToken: logic.loadAppDataCancelTokenSource.token },
    });
    store.dispatch(storeOps.loadCities(result.data.cityList));
  } catch (error) {
    if (axios.isCancel(error)) return;
    console.warn('logic.loadCities error:', error);
  }
}

async function loadEnums() {
  try {
    const result = await api2.get('/login/getEnums', null, {
      axios: { cancelToken: logic.loadAppDataCancelTokenSource.token },
    });
    store.dispatch(storeOps.loadEnums(result.data));
  } catch (error) {
    if (axios.isCancel(error)) return;
    console.warn('logic.loadEnums error:', error);
    setTimeout(loadEnums, 10 * 1000);
  }
}

async function loadVehicleBrands() {
  try {
    const vehicleBrands = (
      await api2.get('/vehicle/findAllVehicleBrandList', null, {
        axios: { cancelToken: logic.loadAppDataCancelTokenSource.token },
      })
    ).data.vehicleBrandList;
    store.dispatch(storeOps.setVehicleBrands(vehicleBrands));
  } catch (error) {
    if (axios.isCancel(error)) return;
    console.warn('logic.loadVehicleBrands error:', error);
    setTimeout(loadVehicleBrands, 10 * 1000);
  }
}

logic.loadCustomer = async (callback) => {
  if (!store.getState().persisted.token) {
    return;
  }

  try {
    const result = await api2.get('/customer/getCustomer');
    const customer = result.data.customer;
    store.dispatch(storeOps.loadCustomer(customer));
    callback?.(customer);
    return customer;
  } catch (ex) {
    console.warn('logic.loadCustomer error:', ex);
    ui.toastError(ex);
    if (ex.message === '未登录') {
      store.dispatch(storeOps.logout());
    }
  }
};

logic.loadVehicleDetail = async (vehicleId, callback) => {
  try {
    const result = await api2.get('/vehicle/getVehicleDetail', { vehicleId });
    if (callback) {
      callback(result.data.vehicleDetail);
    }
  } catch (ex) {
    console.warn('logic.loadVehicleDetail error:', ex);
    ui.toastError(ex);
  }
};

logic.loadVehicleCalendar = async (vehicleId, callback) => {
  try {
    const result = await api2.get('/vehicle/getVehicleCalendar', {
      vehicleId,
    });
    const usedRanges = result.data.vehicleCalendar.vehicleUsedTime;
    const usedDates = {};
    for (const usedRange of usedRanges) {
      for (
        let time = moment(usedRange.startTime, 'YYYY-MM-DD HH:mm:ss').startOf('day');
        time <= moment(usedRange.endTime, 'YYYY-MM-DD HH:mm:ss').startOf('day');
        time.add(1, 'days')
      ) {
        if (
          time.isBefore(moment().startOf('day'), 'day') ||
          !!usedDates[time.format('YYYY-MM-DD')]
        ) {
          // 1.判断日期，小于今天的直接跳过(若usedDates里面有了，也直接跳过，不需要判断了)
          continue;
        }

        if (
          time.isAfter(moment().startOf('day'), 'day') &&
          ((time
            .clone()
            .hours(8)
            .isBefore(moment(usedRange.startTime, 'YYYY-MM-DD HH:mm:ss'), 'minute') &&
            time
              .clone()
              .hours(22)
              .minutes(30)
              .isSameOrBefore(moment(usedRange.startTime, 'YYYY-MM-DD HH:mm:ss'), 'minute')) ||
            (time
              .clone()
              .hours(8)
              .isSameOrAfter(moment(usedRange.endTime, 'YYYY-MM-DD HH:mm:ss'), 'minute') &&
              time
                .clone()
                .hours(22)
                .minutes(30)
                .isAfter(moment(usedRange.endTime, 'YYYY-MM-DD HH:mm:ss'), 'minute')))
        ) {
          // 日期大于今天的如果当天8：00~22：30都可用，就显示当天全部可用
          continue;
        }

        usedDates[time.format('YYYY-MM-DD')] = {};
        if (moment().startOf('day').isSame(time)) {
          // 2.日期=今天，判断今天当前时间~22点30分是否落在租车时间内，是的话则今天不可用，否则今天部分可用
          if (
            moment().isAfter(moment(usedRange.startTime, 'YYYY-MM-DD HH:mm:ss'), 'minute') &&
            moment()
              .hours(22)
              .minutes(30)
              .isBefore(moment(usedRange.endTime, 'YYYY-MM-DD HH:mm:ss'), 'minute')
          ) {
            usedDates[time.format('YYYY-MM-DD')].type = 'full';
          } else {
            usedDates[time.format('YYYY-MM-DD')].type = 'part';
          }
        } else {
          // 3.日期>今天，判断当天8~22点是否完全落在租车时间内，是的话则当天不可用，否则当天部分可用
          if (
            time
              .clone()
              .hours(8)
              .isAfter(moment(usedRange.startTime, 'YYYY-MM-DD HH:mm:ss'), 'minute') &&
            time
              .clone()
              .hours(22)
              .minutes(30)
              .isBefore(moment(usedRange.endTime, 'YYYY-MM-DD HH:mm:ss'), 'minute')
          ) {
            usedDates[time.format('YYYY-MM-DD')].type = 'full';
          } else {
            usedDates[time.format('YYYY-MM-DD')].type = 'part';
          }
        }
      }
    }

    if (
      !usedDates[moment().format('YYYY-MM-DD')] &&
      moment().isAfter(moment().hours(8).minutes(0), 'minute')
    ) {
      usedDates[moment().format('YYYY-MM-DD')] = {};
      usedDates[moment().format('YYYY-MM-DD')].type = 'part';
    }

    if (callback) {
      callback(usedDates, result.data.vehicleCalendar.restrictionDateList);
    }
  } catch (error) {
    console.warn('load vehicle calendar error:', error);
    ui.toastError(error);
  }
};

logic.initCityId = (props) => {
  const query = qs.parse(props.location.search.substr(1));
  let cityId = 101;
  if (!!props.customer && props.customer.cityId != null) {
    cityId = props.customer.cityId;
  }
  if (props.cityId != null) {
    cityId = props.cityId;
  }
  if (query.cityId) {
    const queryCityId = parseInt(query.cityId, 10);
    if (!!queryCityId || queryCityId === 0) {
      cityId = queryCityId;
    }
  }
  if (cityId > 0 && !!props.cities && !props.cities.map[cityId]) {
    cityId = 101;
  }
  return cityId;
};

logic.activeVehicleIndex = (orderRent) => {
  const orderVehicles = (orderRent || {}).orderRentVehicleList || [];
  const activeVehicleIndex = orderVehicles.findIndex((ov) => ov.status !== 'RETURNED');
  return activeVehicleIndex >= 0 ? activeVehicleIndex : orderVehicles.length - 1;
};

logic.inWeixin = () => {
  return /micromessenger/i.test(navigator.userAgent);
};

/** 刚打开页面时，需通过该接口初始化微信 JS-SDK 及分享设置 */
logic.setupWeixinSdk = async (args) => {
  if (!logic.inWeixin()) {
    return;
  }

  try {
    const response = await api.get('/weixin/share', {
      baseURL: `${config.serverEndpoint}/base`,
      params: { url: window.location.href },
    });
    const result = response.data;
    console.log('setup weixin js-sdk:', window.location.href, result);

    const sign = result.data.data.sign;
    window.wx.config({
      debug: false, // process.env.NODE_ENV !== 'production',
      appId: sign.appId,
      timestamp: sign.timestamp,
      nonceStr: sign.nonceStr,
      signature: sign.signature,
      jsApiList: [
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareQZone',
        'onMenuShareTimeline',
        'onMenuShareWeibo',
        'scanQRCode',
        'showMenuItems',
        'updateAppMessageShareData',
        'updateTimelineShareData',
      ],
      openTagList: ['wx-open-launch-weapp'],
    });

    window.wx.ready(() => {
      logic.setupWeixinShare(args);
    });
  } catch (error) {
    console.warn('setup weixin js-sdk error:', error);
  }
};

/** 如果在页面打开时已通过上一个接口初始化过微信 JS-SDK 了， 然后在页面操作过程中需要对分享设置进行更新，可直接调用该接口。 */
logic.setupWeixinShare = async (args) => {
  args = args || {};
  const shareArgs = {
    title: args.title || '光速超跑-一站式奢享服务平台',
    desc: args.content || '美好生活在光速，臻享吃住行娱五星体验',
    link: args.url || window.location.href,
    imgUrl: args.imgUrl || ui.OSS_STATIC + '/frontweb/share/weixin_share_h5.jpg',
  };

  if (args.cityId) {
    const city = store.getState().persisted.cities.map[args.cityId];
    if (city) {
      shareArgs.title = `${shareArgs.title} - ${city.name}车库`;
    }
  }
  if (args.cityId != null) {
    const urlObj = urlTools.parse(shareArgs.link, true);
    urlObj.search = null;
    urlObj.query.cityId = args.cityId;
    shareArgs.link = urlTools.format(urlObj);
  }

  window.wx.updateAppMessageShareData(shareArgs);
  window.wx.updateTimelineShareData(shareArgs);

  // 注释掉的都是要被离弃掉的接口：
  // window.wx.onMenuShareTimeline(shareArgs);
  // window.wx.onMenuShareAppMessage(shareArgs);
  // window.wx.onMenuShareQQ(shareArgs);
  window.wx.onMenuShareWeibo(shareArgs);
  // window.wx.onMenuShareQZone(shareArgs);

  window.wx.showMenuItems({
    menuList: ['menuItem:share:weiboApp'],
  });
};

logic.submitAdParams = async (eventType) => {
  try {
    const result = (
      await api.post(
        '/promotion/addMarketAdStats',
        qs.stringify({
          eventType,
          page: window.location.pathname,
          urlParams: qs.stringify(store.getState().adParams, {
            sort: (f1, f2) => f1.localeCompare(f2),
          }),
          remark: null,
        }),
      )
    ).data;
    console.log('submit ad params:', result);
    if (!result.success) {
      throw new Error(result.message);
    }
  } catch (error) {
    console.warn('submit ad params error:', error);
  }
};

logic.inJd = () => {
  return /jdapp|jdjr-app/i.test(navigator.userAgent);
};

logic.statLog = async (args = {}) => {
  try {
    let openId = store.getState().persisted.openId;
    if (!openId) {
      openId = store.getState().persisted.uuid;
      if (!openId) {
        openId = uuidv4();
        store.dispatch(storeOps.saveUuid(openId));
      }
    }

    args = {
      appName: /iphone|ipod|android|webos|blackberry/i.test(navigator.userAgent)
        ? '移动官网'
        : 'PC官网',
      appVersion: constants.version,
      openid: openId,
      userId: store.getState().persisted.userId,
      shortUserId: store.getState().persisted.customer?.inviteCode,
      page: window.location.pathname,
      channelName: store.getState().channelName,
      channel: store.getState().channelRemark,
      arg1: frontUtils.webEnv(),
      ...args,
    };

    // const result = (
    //   await api2.post('/log/record', args, {
    //     axios: { baseURL: `${config.serverEndpoint}/front` },
    //   })
    // ).data;
    // console.log('submit front stat log:', result);
  } catch (error) {
    console.warn('submit front stat log error:', error);
  }
};

/// 较优价格，目前是等级价、特价、首单88折中的最优价
logic.vehicleEffectivePrice = (vehicleModel) => {
  vehicleModel = vehicleModel || {};
  const vehicle = vehicleModel.vehicle || {};

  let priceType = 'ORIGIN';
  let price = vehicle.dayRentPrice;

  if (vehicleModel.levelRentPrice < price) {
    priceType = 'LEVEL';
    price = vehicleModel.levelRentPrice || vehicle.dayRentPrice;
  }

  const isSpecial = vehicle.vehicleSpecialStatus === 'IN_SPECIAL';
  if (isSpecial && vehicleModel.vehicleSpecial.price < price) {
    priceType = 'SPECIAL';
    price = vehicleModel.vehicleSpecial.price;
  }

  return { isSpecial, priceType, price };
};

export default logic;
