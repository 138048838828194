import { useQueryWrapper } from '@/utils/queryHelpers';
import ui from '@/utils/ui';

export function useData_contract_getContractStatus() {
  const { data, error, ...rest } = useQueryWrapper(
    '/cloud-svr/back/contract/getContractStatus',
    null,
    false,
  );
  ui.useToast(error);
  return {
    data,
    error,
    ...rest,
    contractStatusEnum: { list: data?.list || [], map: data?.map || {} },
  };
}

export function useData_contract_queryContractPlanDetail() {
  const { data, error, ...rest } = useQueryWrapper(
    '/cloud-svr/back/contract/queryContractPlanDetail',
    null,
    true,
  );
  ui.useToast(error);
  return {
    data,
    error,
    ...rest,
    contractPlanDetail: data?.res || {},
  };
}
