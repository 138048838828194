import colorAlpha from 'color-alpha';
import update from 'immutability-helper';
import React from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import store, * as storeOps from 'src/utils/store';
import ui from 'src/utils/ui';
import Card from 'src/widgets/Card';

import api2 from '@/utils/api2';
import colors from '@/utils/colors';
import format from '@/utils/format';
import theme from '@/utils/theme';
import MyList from '@/widgets/MyList';
import { PopupModal } from '@/widgets/PopupModal';

import { NewContractButton } from './NewContractButton';

@connect((state) => ({
  searchContractRecord: state.persisted.searchContractRecord,
}))
class ContractRecordPage extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.navprops = this.props.match.params || {};

    this.state = {
      contractLists: this.props.searchContractRecord.contractLists,
      totalCount: 0,
      useCount: 0,
      name: null,
      nullIssue: '请搜索合同签署记录',
      status: this.props.searchContractRecord.status,
      sortDirection: this.props.searchContractRecord.sortDirection,
      sortProperty: this.props.searchContractRecord.sortProperty,
      searchKeywords: this.props.searchContractRecord.searchKeywords,
      pageIndex: this.props.searchContractRecord.pageIndex,
      hasNext: this.props.searchContractRecord.hasNext,
      activeId: this.props.searchContractRecord.activeId,
      isTimeModalVisible: false,
      isStatusModalVisible: false,
    };
  }

  componentDidMount() {
    this.init();
    if (this.state.activeId > 0) {
      this.reloadContract(this.state.activeId);
    } else {
      this.loadContractRecord();
    }
  }

  async init() {
    try {
      const result = await api2.get('cloud-svr/back/contract/queryContractPlanDetail');
      const { res = {} } = result?.data;
      this.setState({ name: res?.name, totalCount: res?.totalCount, useCount: res?.useCount });
    } catch (ex) {
      console.warn('RiskQueryRecordPage.queryRiskPlanDetail:', ex);
      ui.toastError(ex);
      if (ex.message === '未登录') {
        this.props.history.replace('/m/Login');
      }
    }
  }

  async loadContractRecord() {
    const { pageIndex, searchKeywords, sortDirection, status, sortProperty } = this.state;
    try {
      if (!this.state.hasNext) return;

      this.setState({ nullIssue: '加载数据中' });
      const result =
        (
          await api2.get('cloud-svr/back/contract/findContractModelPage', {
            searchKeywords,
            sortDirection,
            sortProperty,
            status: status === 'ALL' ? null : status,
            pageIndex: pageIndex,
            pageSize: 20,
          })
        ).data?.res || {};

      this.setState((prevState) => ({
        nullIssue: pageIndex === 1 && result?.beanList?.length === 0 ? '暂无符合的记录信息' : '',
        contractLists: [...(pageIndex > 1 ? prevState.contractLists : []), ...result?.beanList],
        hasNext: result.hasNext,
        pageIndex: prevState.pageIndex + 1,
      }));
    } catch (ex) {
      console.warn('ContractRecordPage.findContractModelPage:', ex);
      ui.toastError(ex);
      if (ex.message === '未登录') {
        this.props.history.replace('/m/Login');
        return;
      }
      this.setState({ nullIssue: '搜索合同有误，请重试' });
    }
  }

  async reloadContract(contractId) {
    try {
      if (!contractId > 0) {
        return;
      }
      const result =
        (
          await api2.get('cloud-svr/back/contract/getContractDetailModel', {
            contractId,
          })
        )?.data || {};

      const contract = result?.res || {};

      this.setState((prevState) => {
        const index = prevState.contractLists.findIndex(
          (v) => v.contractId === contract.contractId,
        );
        if (index >= 0) {
          return {
            contractLists: update(prevState.contractLists, {
              [index]: { $set: contract },
            }),
          };
        }
      });
    } catch (error) {
      console.log('reload contract error:', error);
      ui.toastError(error);
    } finally {
      this.setState({
        activeId: null,
      });
      store.dispatch(
        storeOps.setContractRecord({
          searchKeywords: '',
          sortDirection: 'desc',
          sortProperty: 'createdAt',
          status: 'ALL',
          activeId: null,
          pageIndex: 1,
          hasNext: true,
          contractLists: [],
        }),
      );
    }
  }

  render() {
    const u = navigator.userAgent;
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    const electronicContractStatus = {
      ALL: '全部',
      TO_GENERATE: '待生成合同',
      REVOKED: '已撤销',
      TO_SIGN: '待签署合同',
      SIGNED: '已签署合同',
      CANCELED: '已取消',
      FAILED: '失败',
      REJECTED: '拒签',
    };

    return (
      <div
        style={{
          margin: '0 auto',
          maxWidth: 600,
          minHeight: isIOS ? document.body.clientHeight : '100vh',
          background: 'rgba(68,120,245,0.05)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {this.renderHeader()}
        <div style={{ height: 165 }}>
          <div
            style={{
              position: 'fixed',
              zIndex: 1000,
              width: '100vw',
              maxWidth: 600,
              background: '#fff',
            }}
          >
            <div
              style={{
                padding: '13px 15px 12px 15px',
                background: '#fff',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: 42,

                  borderRadius: 21,
                  padding: '0px 20px',
                  backgroundColor: 'rgba(0,0,0,0.03)',
                }}
              >
                <div>
                  <img
                    alt=""
                    src={require('src/images/icon_search@2x.png')}
                    style={{ width: 14, height: 14, display: 'block' }}
                  />
                </div>

                <input
                  placeholder="合同编号/名称搜索合同"
                  value={this.state.searchKeywords}
                  onChange={(event) =>
                    this.setState(
                      {
                        searchKeywords: event.target.value,
                        pageIndex: 1,
                        hasNext: true,
                      },
                      () => {
                        this.loadContractRecord();
                        window.scrollTo(0, 0);
                      },
                    )
                  }
                  style={{
                    paddingLeft: 6,
                    ...theme.fonts.regular(14),
                    flex: 1,
                    outline: 'none',
                    height: 30,
                    border: 'none',
                    background: 'transparent',
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 14,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    this.setState({
                      isTimeModalVisible: true,
                    });
                  }}
                >
                  <img
                    alt=""
                    src={require('src/images/timeScreening.png')}
                    style={{ width: 14, height: 13, display: 'block' }}
                  />
                  <div style={{ ...theme.fonts.regular(12), marginLeft: 4 }}>
                    {`${this.state.sortProperty === 'createdAt' ? '创建时间' : '签署时间'}${
                      this.state.sortDirection === 'desc' ? '从新到旧' : '从旧到新'
                    }`}
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ ...theme.fonts.regular(13) }}>状态：</div>
                  <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={() => this.setState({ isStatusModalVisible: true })}
                  >
                    <div style={{ ...theme.fonts.medium(13), color: '#4377F5' }}>
                      {electronicContractStatus[this.state.status]}
                    </div>
                    <div
                      style={{
                        width: 4,
                        height: 7,
                        marginLeft: 8,
                        backgroundColor: '#4377F5',
                        '-webkit-mask': `url(${require('src/images/icon_chevron_right.svg')}) no-repeat center`,
                        mask: `url(${require('src/images/icon_chevron_right.svg')}) no-repeat center`,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                ...theme.fonts.medium(14),
                padding: '13px 15px 12px 15px',
                backgroundColor: '#fff',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: 6, height: 6, borderRadius: 3, background: 'black' }} />
                <div style={{ marginLeft: 8 }}>已购套餐：{this.state.name}</div>
              </div>
              <div
                style={{
                  marginTop: 13,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {' '}
                <div style={{ width: 6, height: 6, borderRadius: 3, background: 'black' }} />
                <div
                  style={{ marginLeft: 8 }}
                >{`套餐已用：${this.state.useCount}/${this.state.totalCount}`}</div>
              </div>
            </div>
          </div>
        </div>

        {this.state.contractLists.length === 0 && (
          <div
            style={{
              marginTop: 200,
              marginBottom: 100,
              ...theme.fonts.regular(20),
              color: theme.colors.textGray,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {' '}
            {this.state.nullIssue}
          </div>
        )}
        {this.state.contractLists.length > 0 && (
          <div
            style={{
              padding: '0 15px',
              marginTop: 14,
            }}
          >
            <MyList
              hasMore={this.state.hasNext}
              onLoadMore={this.loadContractRecord}
              bottomBannerDisabled
            >
              {this.state.contractLists.map((item) => {
                return (
                  <Card
                    key={item.contractId}
                    style={{ margin: '0 0 13px 0', padding: 12, background: '#FFFFFF' }}
                    onClick={() => {
                      store.dispatch(
                        storeOps.setContractRecord({
                          searchKeywords: this.state.searchKeywords,
                          sortDirection: this.state.sortDirection,
                          sortProperty: this.state.sortProperty,
                          pageIndex: this.state.pageIndex,
                          hasNext: this.state.hasNext,
                          contractLists: this.state.contractLists,
                          status: this.state.status,
                          activeId: item.contractId,
                        }),
                      );

                      window.location.href = `/m/ContractEditor/${item.contractId}`;
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          style={{
                            ...theme.fonts.regular(11, 20),
                            padding: '0 12px',
                            borderRadius: 2,
                            height: 20,
                            background: ['CANCELED', 'FAILED', 'REJECTED'].includes(
                              item.contractStatus,
                            )
                              ? '#E32828'
                              : item.contractStatus === 'SIGNED'
                                ? '#38A578'
                                : '#F7C84C',
                            color: '#FFFFFF',
                          }}
                        >
                          {electronicContractStatus[item.contractStatus]}
                        </div>
                        <div
                          style={{
                            ...theme.fonts.regular(11),
                            marginLeft: 12,
                            color: 'rgba(0,0,0,0.45)',
                          }}
                        >
                          合同编号{item.contractId}
                        </div>
                        <div style={{ flex: 1 }} />
                        {['FAILED'].includes(item.contractStatus) && (
                          <div style={{ ...theme.fonts.regular(13), color: '#E32828' }}>
                            请联系客服协助处理
                          </div>
                        )}
                        {['REJECTED'].includes(item.contractStatus) && (
                          <div style={{ ...theme.fonts.regular(13), color: '#E32828' }}>
                            {item.unFinishRemark}
                          </div>
                        )}
                      </div>
                      <div style={{ marginTop: 15, width: '100%' }}>
                        {this.renderContrctItem('合同名称', `《${item.contractName}》`)}
                      </div>
                      <div
                        style={{
                          margin: '12px 0 12px 0',
                          height: 1,
                          background: 'rgba(0,0,0,0.06)',
                        }}
                      />
                      {this.renderContrctItem('创建时间', `${format.time(item.createdDate)}`)}
                      {['SIGNED'].includes(item.contractStatus) && (
                        <div style={{ marginTop: 16 }}>
                          {this.renderContrctItem('签署完成', `${format.time(item.signedTime)}`)}
                        </div>
                      )}
                      {['CANCELED'].includes(item.contractStatus) && (
                        <div style={{ marginTop: 16 }}>
                          {this.renderContrctItem('取消时间', `${format.time(item.canceledTime)}`)}
                        </div>
                      )}

                      <div style={{ marginTop: 16 }}>
                        {this.renderContrctItem('消耗套餐', `${item.contractPlanCount}次`)}
                      </div>
                      {['TO_GENERATE', 'TO_SIGN', 'REVOKED'].includes(item.contractStatus) && (
                        <div
                          style={{
                            marginTop: 20,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          {['TO_GENERATE', 'REVOKED'].includes(item.contractStatus) && (
                            <div
                              style={{
                                ...theme.fonts.regular(14, 30),
                                width: 100,
                                height: 30,
                                border: '1px solid #4377F5',
                                borderRadius: 15,
                                color: '#4377F5',
                                textAlign: 'center',
                              }}
                            >
                              不再签署
                            </div>
                          )}
                          {['TO_SIGN'].includes(item.contractStatus) && (
                            <div
                              style={{
                                ...theme.fonts.regular(14, 30),
                                width: 100,
                                height: 30,
                                border: '1px solid #4377F5',
                                borderRadius: 15,
                                color: '#4377F5',
                                textAlign: 'center',
                              }}
                            >
                              撤销合同
                            </div>
                          )}
                          <div style={{ flex: 1 }} />
                          <div>
                            {['TO_GENERATE'].includes(item.contractStatus) && (
                              <div
                                style={{
                                  ...theme.fonts.regular(14, 30),
                                  width: 100,
                                  height: 30,
                                  borderRadius: 15,
                                  textAlign: 'center',
                                  background: '#4377F5',
                                  color: '#FFFFFF',
                                }}
                              >
                                生成合同
                              </div>
                            )}
                            {['REVOKED'].includes(item.contractStatus) && (
                              <div
                                style={{
                                  ...theme.fonts.regular(14, 30),
                                  width: 120,
                                  height: 30,
                                  borderRadius: 15,
                                  textAlign: 'center',
                                  background: '#4377F5',
                                  color: '#FFFFFF',
                                }}
                              >
                                重新生成合同
                              </div>
                            )}
                            {['TO_SIGN'].includes(item.contractStatus) && (
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                  style={{
                                    ...theme.fonts.regular(14, 30),
                                    width: 100,
                                    height: 30,
                                    borderRadius: 15,
                                    textAlign: 'center',
                                    background: !item.canSignA
                                      ? colorAlpha('black', 0.15)
                                      : '#4377F5',
                                    color: '#FFFFFF',
                                  }}
                                >
                                  甲方签署
                                </div>
                                <div
                                  style={{
                                    ...theme.fonts.regular(14, 30),
                                    marginLeft: 10,
                                    width: 100,
                                    height: 30,
                                    borderRadius: 15,
                                    textAlign: 'center',
                                    background: !item.canSignB
                                      ? colorAlpha('black', 0.15)
                                      : '#4377F5',
                                    color: '#FFFFFF',
                                  }}
                                >
                                  乙方签署
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </Card>
                );
              })}
            </MyList>
          </div>
        )}

        <div style={{ flex: 1 }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '20px 42px 20px 42px',
            margin: '0 auto',
          }}
        >
          <img
            alt=""
            src={require('src/images/bottom.png')}
            style={{ width: '100%', maxWidth: 400, height: 'auto', display: 'block' }}
          />
        </div>
        {this.renderTimeModal()}
        {this.renderStatusModal()}
      </div>
    );
  }
  renderHeader() {
    const HEADER_HEIGHT = 44;

    return (
      <div
        style={{
          width: '100vw',
          maxWidth: 600,
          height: HEADER_HEIGHT,
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            height: HEADER_HEIGHT,
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div
              onClick={() => {
                store.dispatch(
                  storeOps.setContractRecord({
                    contractLists: [],
                    status: 'ALL',
                    searchKeywords: '',
                    sortDirection: 'desc',
                    sortProperty: 'createdAt',
                    pageIndex: 1,
                    hasNext: true,
                  }),
                );
                this.props.history.goBack();
              }}
              className="flex-row justify-center align-center"
            >
              <img
                style={{ marginLeft: 20, height: 15 }}
                alt=""
                src={require('src/images/icon_chevron_left.svg')}
              />
            </div>
          </div>
          <div
            style={{
              ...theme.fonts.medium(15),
              textAlign: 'center',
            }}
          >
            签署记录
          </div>

          <div
            onClick={() => {
              store.dispatch(
                storeOps.setContractRecord({
                  searchKeywords: this.state.searchKeywords,
                  sortDirection: this.state.sortDirection,
                  sortProperty: this.state.sortProperty,
                  pageIndex: 1,
                  hasNext: true,
                  contractLists: [],
                  status: this.state.status,
                  activeId: null,
                }),
              );
            }}
            className="flex-row  align-center"
            style={{
              ...theme.fonts.medium(13),
              color: '#4377F5',
              position: 'absolute',
              right: 15,
              top: 0,
              bottom: 0,
            }}
          >
            <NewContractButton className="flex-row align-center justify-center">
              <img style={{ width: 10, height: 10 }} alt="" src={require('src/images/plus.png')} />
              <span style={{ ...theme.fonts.medium(13), marginLeft: 4 }}>新增合同</span>
            </NewContractButton>
          </div>
        </div>
      </div>
    );
  }

  renderContrctItem(title, content) {
    return (
      <div
        style={{
          ...theme.fonts.regular(14),
          display: 'flex',
          alignContent: 'center',
          flex: 1,
        }}
      >
        <div style={{ color: '#4377F5', width: 76, display: 'flex', alignItems: 'center' }}>
          {title}:
        </div>

        <div
          style={{
            width: 'calc(100% - 76px)',
            wordWrap: 'break-word',
          }}
        >
          {content}
        </div>
      </div>
    );
  }

  renderTimeModal() {
    const sortDirectionLists = [
      {
        name: 'CreateDesc',
        desc: '创建时间从新到旧',
        sortProperty: 'createdAt',
        sortDirection: 'desc',
      },
      {
        name: 'CreateAsc',
        desc: '创建时间从旧到新',
        sortProperty: 'createdAt',
        sortDirection: 'asc',
      },
      {
        name: 'SignDesc',
        desc: '签署时间从新到旧',
        sortProperty: 'signedTime',
        sortDirection: 'desc',
      },
      {
        name: 'SignAsc',
        desc: '签署时间从旧到新',
        sortProperty: 'signedTime',
        sortDirection: 'asc',
      },
    ];
    return (
      <PopupModal
        isVisible={this.state.isTimeModalVisible}
        onClose={() => this.setState({ isTimeModalVisible: false })}
        closingButtonStyle={{ padding: '0 17px' }}
        title={'排序规则'}
        modalStyle={{ borderRadius: 0 }}
        titleStyle={{ ...theme.fonts.medium(14), color: '#000000' }}
        closingIconStyle={{ width: 14, height: 14, backgroundColor: '#4377F5' }}
      >
        <div className="flex-column align-center " style={{ marginBottom: 20 }}>
          {sortDirectionLists.map((list, index) => (
            <div
              key={index}
              style={{
                ...theme.fonts.regular(14, 32),
                width: 240,
                height: 32,
                borderRadius: 16,
                background:
                  this.state.sortDirection === list.sortDirection &&
                  this.state.sortProperty === list.sortProperty
                    ? '#4377F5'
                    : 'rgba(68,120,245,0.12)',
                color:
                  this.state.sortDirection === list.sortDirection &&
                  this.state.sortProperty === list.sortProperty
                    ? '#FFFFFF'
                    : '#4377F5',
                textAlign: 'center',
                marginBottom: 13,
              }}
              onClick={() => {
                this.setState(
                  {
                    sortDirection: list.sortDirection,
                    sortProperty: list.sortProperty,
                    pageIndex: 1,
                    hasNext: true,
                    isTimeModalVisible: false,
                  },
                  () => {
                    this.loadContractRecord();
                    window.scrollTo(0, 0);
                  },
                );
              }}
            >
              {list.desc}
            </div>
          ))}
        </div>
      </PopupModal>
    );
  }

  renderStatusModal() {
    const contractStatusLists = [
      {
        name: 'ALL',
        desc: '全部',
      },
      {
        name: 'TO_GENERATE',
        desc: '待生成合同',
      },
      {
        name: 'TO_SIGN',
        desc: '待签署合同',
      },
      {
        name: 'SIGNED',
        desc: '已签署合同',
      },
      {
        name: 'REVOKED',
        desc: '已撤销',
      },
      {
        name: 'CANCELED',
        desc: '已取消',
      },
      {
        name: 'FAILED',
        desc: '失败',
      },
      {
        name: 'REJECTED',
        desc: '拒签',
      },
    ];
    return (
      <PopupModal
        isVisible={this.state.isStatusModalVisible}
        onClose={() => this.setState({ isStatusModalVisible: false })}
        closingButtonStyle={{ padding: '0 17px' }}
        modalStyle={{ borderRadius: 0 }}
        titleStyle={{ ...theme.fonts.medium(14), color: '#000000' }}
        closingIconStyle={{ width: 14, height: 14, backgroundColor: '#4377F5' }}
        title={'筛选合同状态'}
      >
        <div
          className="flex-row align-center "
          style={{ marginBottom: 20, padding: '0 27px ', flexWrap: 'wrap' }}
        >
          {contractStatusLists.map((list, index) => (
            <div
              key={index}
              style={{
                ...theme.fonts.regular(14, 32),
                width: 160,
                height: 32,
                borderRadius: 16,
                background: this.state.status === list.name ? '#4377F5' : 'rgba(68,120,245,0.12)',
                color: this.state.status === list.name ? '#FFFFFF' : '#4377F5',
                textAlign: 'center',
                marginBottom: 13,
                marginLeft: index % 2 !== 0 ? `calc(100% - 320px)` : 0,
              }}
              onClick={() => {
                this.setState(
                  {
                    status: list.name,
                    pageIndex: 1,
                    hasNext: true,
                    isStatusModalVisible: false,
                  },
                  () => {
                    window.scrollTo(0, 0);
                    this.loadContractRecord();
                  },
                );
              }}
            >
              {list.desc}
            </div>
          ))}
        </div>
      </PopupModal>
    );
  }
}

export default ContractRecordPage;
