// TODO move to frontWebUtils or like
const frontUtils = {};

frontUtils.webEnv = () => {
  if (/micromessenger/i.test(navigator.userAgent)) {
    if (/miniprogram/i.test(navigator.userAgent)) return 'weapp';
    return 'wechat';
  }
  if (/aliapp/i.test(navigator.userAgent)) return 'aliapp';
  if (navigator.userAgent.toLowerCase().includes('toutiaomicroapp')) return 'douyinMini';

  if (/wagons-backapp/i.test(navigator.userAgent)) return 'backapp';
  if (/wagons\//i.test(navigator.userAgent)) return 'frontapp';

  if (/iphone|ipod|android|webos|blackberry/i.test(navigator.userAgent)) {
    return 'mobile';
  }
  return 'desktop';
};

export default frontUtils;
