import qs from 'qs';
import React from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import store, * as storeOps from 'src/utils/store';
import ui from 'src/utils/ui';
import Card from 'src/widgets/Card';

import api2 from '@/utils/api2';
import logic from '@/utils/logic';
import theme from '@/utils/theme';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.navprops = this.props.match.params || {};
    this.query = qs.parse(this.props.location.search.substr(1));

    this.state = {
      cell: '',
      smscode: '',
      smsTitle: '获取验证码',
      isLoading: true,
      sending: false,
      smsCounter: 0,
      logining: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 888);
  }

  componentWillUnmount() {
    clearTimeout(this.smsTimer);
  }

  render() {
    const isCanSubmit = this.state.cell && this.state.smscode;
    if (this.state.isLoading) return null;
    const u = navigator.userAgent;
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    return (
      <div
        style={{
          minHeight: isIOS ? document.body.clientHeight : '100vh',
          background: 'rgba(68,120,245,0.05)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            padding: '60px 15px 20px 15px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',

              marginBottom: 40,
            }}
          >
            <div
              style={{
                width: 80,
                height: 80,
                borderRadius: 40,
                background: '#4478F5',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...theme.fonts.medium(16),
              }}
            >
              光速云
            </div>
          </div>
          <Card
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0 auto',
              maxWidth: 400,
              padding: '22px 15px 22px 15px',
              borderRadius: 8,
            }}
          >
            <div
              style={{
                display: 'flex',

                flexDirection: 'row',
                alignItems: 'center',
                background: 'rgba(68,120,245,0.05)',
                borderRadius: 8,
                height: 53,
              }}
            >
              <div
                style={{
                  ...theme.fonts.medium(14, 53),
                  width: 86,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                手机号码
              </div>
              <input
                placeholder="请输入手机号码"
                type="number"
                value={this.state.cell}
                onChange={(event) => this.setState({ cell: event.target.value })}
                style={{
                  fontSize: this.state.cell ? 15 : 14,
                  marginRight: 10,
                  flex: 1,
                  outline: 'none',
                  height: 40,
                  border: 'none',
                  background: 'transparent',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                background: 'rgba(68,120,245,0.05)',
                borderRadius: 8,
                height: 53,
                marginTop: 15,
              }}
            >
              <div
                style={{
                  ...theme.fonts.medium(14, 53),
                  width: 86,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                验证码
              </div>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <input
                  placeholder="请输入验证码"
                  type="number"
                  value={this.state.smscode}
                  onChange={(event) => this.setState({ smscode: event.target.value })}
                  style={{
                    margin: 0,
                    marginRight: 10,
                    fontSize: this.state.smscode ? 15 : 14,
                    width: 100,

                    outline: 'none',
                    height: 40,

                    border: 'none',
                    background: 'transparent',
                    paddingRight: 0,
                  }}
                />
                <div
                  style={{
                    ...theme.fonts.regular(12),
                    width: 100,
                    height: 30,
                    borderRadius: 15,
                    background: this.state.cell
                      ? this.state.sending || this.state.smsCounter > 0
                        ? '#D6D6D6'
                        : '#4478F5'
                      : 'rgba(68,120,245,0.45)',
                    marginRight: 16,
                    color: '#FFFFFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={this.requestSmsCode}
                >
                  {this.state.sending
                    ? '发送中……'
                    : this.state.smsCounter > 0
                      ? `${this.state.smsCounter}s重新获取`
                      : `${this.state.smsTitle}`}
                </div>
              </div>
            </div>
            <div
              style={{
                ...theme.fonts.regular(15),
                color: '#FFFFFF',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: 44,
                borderRadius: 22,
                backgroundColor:
                  isCanSubmit && !this.state.logining ? '#4478F5' : 'rgba(68,120,245,0.2)',
                marginTop: 22,
              }}
              onClick={() => isCanSubmit && !this.state.logining && this.login()}
            >
              登录
            </div>
          </Card>
        </div>

        <div style={{ flex: 1 }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '20px 42px 20px 42px',
            margin: '0 auto',
          }}
        >
          <img
            alt=""
            src={require('src/images/bottom.png')}
            style={{ width: '100%', maxWidth: 400, height: 'auto', display: 'block' }}
          />
        </div>
      </div>
    );
  }

  requestSmsCode() {
    if (this.state.sending || this.state.smsCounter > 0 || !this.state.cell) {
      return;
    }

    this.setState({ sending: true }, async () => {
      try {
        await api2.post('cloud-svr/back/index/send', { cell: this.state.cell });
        this.updateSmsTimer(60);
      } catch (error) {
        console.warn('request sms code error:', error);
        ui.toastError(error);
      } finally {
        this.setState({ sending: false });
      }
    });
  }

  updateSmsTimer(counter) {
    this.setState({ smsCounter: counter }, () => {
      if (counter > 0) {
        this.smsTimer = setTimeout(() => this.updateSmsTimer(counter - 1), 1000);
      }
      if (counter == 0) {
        this.setState({ smsTitle: '重新获取' });
      }
    });
  }

  async login() {
    if (this.state.logining) {
      return;
    }
    try {
      this.setState({ logining: true });

      const result = await api2.post('cloud-svr/back/index/loginByCell', {
        cell: this.state.cell,
        identifyingCode: this.state.smscode,
      });
      store.dispatch(storeOps.login(result.data.merchantAccount.token));
      this.onSuccess();
    } catch (error) {
      console.warn('login error:', error);
      ui.toastError(error);
    } finally {
      this.setState({ logining: false });
    }
  }

  onSuccess() {
    if (ui.inMobile()) {
      this.props.history.replace('/m');
    } else {
      this.props.history.replace('/pc');
    }
  }
}

export default connect((state) => ({
  token: state.persisted.token,
}))(LoginPage);
