import { QueryClient, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import api2 from './api2';
import store from './store';

export const theQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnMount: false,
      // refetchOnReconnect: false,
      // refetchOnWindowFocus: false,
      // NOTICE 先不要自动重试，避免给服务端太大的压力
      // TODO 但好像仍然会重试一次
      retry: false,
      staleTime: 10 * 60 * 1000,
      cacheTime: 20 * 60 * 1000,
    },
  },
});
window.wagons_theQueryClient = theQueryClient;

window.wagons_abortController = new AbortController();

export function queryKey(url, args, isLoginRequired) {
  const token = store.getState().persisted.token;
  return [
    url,
    {
      ...(isLoginRequired ? { token } : {}),
      ...args,
    },
    isLoginRequired,
  ];
}

/**
 * 对 useQuery 的一个统一的封装
 *
 * 两种调用形式：
 * - useQueryWrapper(key, api2Options, queryOptions)
 * - useQueryWrapper(url, args, isLoginRequired, api2Options, queryOptions)
 */
export function useQueryWrapper(url, ...restArgs) {
  const token = useSelector((s) => s.persisted.token);

  let key, args, isLoginRequired, api2Options, queryOptions;
  if (typeof url === 'string') {
    [args, isLoginRequired, api2Options, queryOptions] = restArgs;
    key = queryKey(url, args, isLoginRequired);
  } else {
    key = url;
    [url, args, isLoginRequired] = key;
    [api2Options, queryOptions] = restArgs;
  }

  const enabled = !!(
    (queryOptions?.enabled === undefined ? true : queryOptions?.enabled) &&
    (!isLoginRequired || token)
  );

  return useQuery(
    key,
    async ({ queryKey: [u, a] }) => {
      /** @todo v4 不知道还需不需要这个了 */
      if (!enabled) return undefined;
      const result = await api2.get(
        u,
        {
          // HACK 方便过滤日志输出
          _useQuery: 42,
          ...a,
        },
        {
          signal: window.wagons_abortController.signal,
          ...api2Options,
        },
      );
      return result.data;
    },
    {
      ...queryOptions,
      enabled,
    },
  );
}
