import React from 'react';
import { CacheSwitch } from 'react-router-cache-route';
import { Route } from 'react-router-dom';

import { ContractEditorPage } from './contract/ContractEditorPage';

export default function MobileRouter() {
  return (
    <CacheSwitch>
      <Route path="/m" exact component={require('src/pages/m/IndexPage').default} />

      <Route path="/m/Login" component={require('src/pages/m/LoginPage').default} />
      <Route path="/m/RiskQuery" component={require('src/pages/m/RiskQueryPage').default} />
      <Route
        path="/m/BrAuthorization"
        component={require('src/pages/m/BrAuthorizationPage').default}
      />
      <Route
        path="/m/Authentication"
        component={require('src/pages/m/AuthenticationPage').default}
      />
      <Route path="/m/OnSuccess" component={require('src/pages/m/OnSuccessPage').default} />
      <Route
        path="/m/RiskQueryRecord"
        component={require('src/pages/m/RiskQueryRecordPage').default}
      />
      <Route
        path="/m/RiskQueryReport/:riskQueryRecordId"
        component={require('src/pages/m/RiskQueryReportPage').default}
      />
      <Route
        path="/m/ContractRecord"
        component={require('src/pages/m/contract/ContractRecordPage').default}
      />
      <Route
        path={['/m/ContractEditor/:contractId', '/m/ContractEditor']}
        component={ContractEditorPage}
      />
    </CacheSwitch>
  );
}
