import useResizeObserver from '@react-hook/resize-observer';
import axios from 'axios';
import dayjs from 'dayjs';
import qs from 'qs';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import colors from 'src/utils/colors';
import store, * as storeOps from 'src/utils/store';
import format from 'src/wagons-clientlib/format';
import utils from 'src/wagons-clientlib/utils';

import api2 from './api2';
import { theQueryClient } from './queryHelpers';
import { usePrevious } from './usePrevious';

const ui = {};

ui.OSS_STATIC = 'https://wagonsclub.oss-cn-beijing.aliyuncs.com/static';

ui.isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

/** @deprecated by ui.env */
ui.inMobile = () => {
  return /iphone|ipad|ipod|android|webos|blackberry/i.test(navigator.userAgent);
};

ui.env = () => {
  if (/micromessenger/i.test(navigator.userAgent)) {
    if (/miniprogram/i.test(navigator.userAgent)) return 'weapp';
    return 'wechat';
  }
  if (/aliapp/i.test(navigator.userAgent)) return 'aliapp';
  if (navigator.userAgent.toLowerCase().includes('toutiaomicroapp')) return 'douyinMini';

  if (/wagons-backapp/i.test(navigator.userAgent)) return 'backapp';
  if (/wagons\//i.test(navigator.userAgent)) return 'frontapp';

  if (/iphone|ipod|android|webos|blackberry/i.test(navigator.userAgent)) {
    return 'mobile';
  }
  return 'desktop';
};

export function useQueryParams() {
  const location = useLocation();
  const query = useMemo(() => qs.parse(location.search.substring(1)), [location.search]);
  return query;
}

/** copied from https://www.npmjs.com/package/@react-hook/resize-observer */
export function useSize(targetRef) {
  const [size, setSize] = useState();

  useLayoutEffect(() => {
    setSize(targetRef.current?.getBoundingClientRect());
  }, [targetRef]);

  // Where the magic happens
  useResizeObserver(targetRef, (entry) => setSize(entry.contentRect));
  return size;
}

ui.isIOS = /iphone|ipad|ipod/i.test(navigator.userAgent);

/** @deprecated */
ui.isWagonsclub = () => {
  return /wagonsclub\.com$/i.test(window.location.hostname);
};

/** @deprecated 域名 */
ui.isGsjc = () => {
  return /guangsu\.club$/i.test(window.location.hostname);
};

let isRefreshingByWebview = false;
window.wagons_ignoreErrorsWhenRefreshing = () => {
  isRefreshingByWebview = true;
  setTimeout(() => {
    isRefreshingByWebview = false;
  }, 800);
};

window.wagons_reloadQueries = () => {
  window.wagons_ignoreErrorsWhenRefreshing();
  theQueryClient.invalidateQueries([]);
};

ui.toastInfo = (message) => {
  console.warn('ui.toastInfo:', { message });
  toast(message, {
    // autoClose: 8888888,
    className: 'toastify-info',
  });
};

ui.toastError = (message, error) => {
  console.warn('ui.toastError:', { message, error });
  if (isRefreshingByWebview) return;
  const originalParams = { message, error };

  if (error) message = error;
  if (message?.response?.data?.message) message = message.response.data.message;
  if (message?.isAxiosError || axios.isAxiosError?.(message)) {
    message = '服务端开小差了，请稍后重试';
  }
  if (message instanceof Error) message = message.message;
  message = (message?.toString() || '').trim() || '服务端开小差了，请稍后重试';

  switch (ui.env()) {
    case 'frontapp':
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ action: 'toastError', message, originalParams }),
      );
      break;
    default:
      toast(message, {
        // autoClose: 8888888,
        className: 'toastify-error',
      });
      break;
  }
};

ui.useToast = function useToast(error) {
  const prevError = usePrevious(error);
  useEffect(() => {
    if (error && error !== prevError) {
      ui.toastError(error);
    }
  }, [error, prevError]);
};

function useNow(interval) {
  interval = interval || 1000;
  const [now, setNow] = useState(dayjs());
  useEffect(() => {
    const timer = setInterval(() => setNow(dayjs()), interval);
    return () => clearInterval(timer);
  }, [interval]);
  return now;
}
ui.useNow = useNow;

ui.postWebviewMessage = (data) => {
  switch (ui.env()) {
    case 'frontapp':
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
      break;

    case 'weapp':
      window.wx.miniProgram.postMessage({ data });
      break;

    case 'aliapp':
      window.my.postMessage(data);
      break;

    case 'douyinMini':
      window.tt.miniProgram.postMessage({ data });
      break;
  }
};

ui.goBackWithinApp = (delta) => {
  delta = delta || 1;
  switch (ui.env()) {
    case 'frontapp':
      window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'goBack', delta }));
      break;

    case 'weapp':
      window.wx.miniProgram.navigateBack({ delta: delta });
      break;

    case 'aliapp':
      window.my.navigateBack({ delta: delta });
      break;

    case 'douyinMini':
      window.tt.miniProgram.navigateBack({ delta: delta });
      break;

    default:
      window.history.go(-delta);
      break;
  }
};

ui.setLogin = (token) => {
  store.dispatch(storeOps.login(token));

  switch (ui.env()) {
    case 'frontapp':
      ui.postWebviewMessage({ action: 'setLogin', token });
      break;
    case 'weapp':
    case 'aliapp':
    case 'douyinMini':
      ui.postWebviewMessage({ action: 'login', token });
      break;
    default:
      break;
  }
};

ui.openWeapp = (path) => {
  switch (ui.env()) {
    case 'frontapp':
      window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'openWeapp', path }));
      break;
    case 'weapp':
      window.wx.miniProgram.navigateTo({ url: path });
      break;
  }
};

ui.gotoIndexTab = (tab) => {
  switch (ui.env()) {
    case 'frontapp':
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ action: 'events', event: 'switchTourTab', params: [tab] }),
      );
      window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'navigate', page: 'Tour' }));
      break;

    case 'weapp':
      window.wx.miniProgram.postMessage({
        data: { action: 'backResult', backResult: { tab } },
      });
      window.wx.miniProgram.switchTab({ url: '/pages/index/IndexPage' });
      break;
  }
};

ui.gotoWebviewPage = (title, url, options) => {
  const fullUrl = /^https?:\/\//.test(url)
    ? url
    : `${window.location.origin}${/^\//.test(url) ? url : `/m/${url}`}`;

  switch (ui.env()) {
    case 'frontapp':
      if (/^https?:\/\//.test(url)) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            action: 'navigate',
            page: 'Webview',
            params: { title, url, ...options },
          }),
        );
      } else {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            action: 'push',
            title,
            page: url,
            options,
          }),
        );
      }
      break;

    case 'weapp':
      window.wx.miniProgram.navigateTo({
        url:
          '/pages/webview/WebviewPage?' +
          format.query({
            title,
            url: fullUrl,
            ...options,
          }),
      });
      break;

    default:
      window.location.href = fullUrl;
      break;
  }
};

ui.gotoMallPage = (setCommonModal) => {
  switch (ui.env()) {
    case 'frontapp': {
      const matches = /wagons\/(\d+\.\d+\.\d+)/.exec(navigator.userAgent);
      if (!matches?.[1] || utils.compareVersions(matches[1], '3.18.0') < 0) {
        setCommonModal?.({
          isVisible: true,
          title: '跳转失败',
          content: '请更新App，即可参与活动',
        });
        return;
      }

      window.ReactNativeWebView.postMessage(
        JSON.stringify({ action: 'navigate', page: 'PointMall' }),
      );
      break;
    }

    case 'weapp':
      window.wx.miniProgram.switchTab({ url: '/pages/mall/PointMallPage' });
      break;

    default:
      window.location.href =
        '/m/CommonUlink?' +
        format.query({
          navigate: 'PointMall',
          channelCode: '202306六周年庆',
        });
      break;
  }
};

ui.switchMiniProgramTab = (tab) => {
  switch (ui.env()) {
    case 'weapp':
      window.wx.miniProgram.switchTab({ url: tab });
      break;
    case 'aliapp':
      window.my.switchTab({ url: tab });
      break;
    case 'douyinMini':
      window.tt.miniProgram.switchTab({ url: tab });
      break;
  }
};

ui.gotoMiniProgramPage = (url) => {
  switch (ui.env()) {
    case 'weapp':
      window.wx.miniProgram.navigateTo({ url });
      break;
    case 'aliapp':
      window.my.navigateTo({ url });
      break;
    case 'douyinMini':
      window.tt.miniProgram.navigateTo({ url });
      break;
  }
};

ui.openWeixinConsult = () => {
  try {
    switch (ui.env()) {
      case 'frontapp':
        window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'weixinConsult' }));
        break;

      case 'weapp':
        window.wx.miniProgram.navigateTo({ url: '/pages/mine/ConsultPage' });
        break;

      case 'aliapp':
        window.location.href =
          'https://10204649.saas.53kf.com/code/xcx/a8be18a6128baf5af55df2701c40f44d/1?custom_channel=支付宝小程序';
        break;

      case 'douyinMini':
        window.location.href =
          'https://10204649.saas.53kf.com/code/xcx/a8be18a6128baf5af55df2701c40f44d/2?custom_channel=抖音小程序';
        break;

      default:
        window.location.href =
          'https://work.weixin.qq.com/kfid/kfc1325aee324b655b7?enc_scene=ENC3XaXwPL8JYatej59gxTHqAYMcVuDKfNjfA7y5nvLo4gPtg1c8mNuGSa1NXzFJzzD37';
        break;
    }
  } catch (ex) {
    console.warn('ui.openWeixinConsult error:', ex);
  }
};

ui.open53kfChatWindow = (pcOrPhone) => {
  if (pcOrPhone === 'pc') {
    window.open(
      'https://tb.53kf.com/code/client/a8be18a6128baf5af55df2701c40f44d/1',
      '_blank',
      'width=800,height=600,top=200,left=200,menubar=no,toolbar=no,status=no,scrollbars=yes',
    );
  } else {
    window.location.href = 'https://tb.53kf.com/code/client/a8be18a6128baf5af55df2701c40f44d/1';
  }
};

/** https://lbs.amap.com/api/javascript-api/guide/abc/load */
ui.initAmap = async () => {
  if (window.AMap) return;

  await new Promise((resolve) => {
    const originalOnLoadAmap = window.onLoadAmap;
    window.onLoadAmap = function () {
      window.onLoadAmap = originalOnLoadAmap;
      resolve();
    };

    const url =
      'https://webapi.amap.com/maps?v=1.4.15&key=37d7fc78e4ed58125247ccd37ab3b170&callback=onLoadAmap';
    const script = document.createElement('script');
    script.charset = 'utf-8';
    script.src = url;
    document.head.appendChild(script);
  });
};

ui.carouselUrl = (carousel) => {
  try {
    const linkInfo =
      (JSON.parse(carousel.linkInfo) || {})[ui.inMobile() ? 'mobile' : 'desktop'] || {};
    const prefix = ui.inMobile() ? '/m/' : '/pc/';
    switch (linkInfo.type) {
      case 'H5_IMAGE':
        return `${prefix}Carousel?${qs.stringify({
          title: linkInfo.title,
          image: linkInfo.url,
          bgColor: linkInfo.bgColor,
        })}`;

      case 'H5_WEB':
        return '/m/' + linkInfo.url;

      case 'H5_OTHER':
        return linkInfo.url;

      case 'FUNCTION': {
        const target = JSON.parse(linkInfo.url);
        switch ((target || {}).action) {
          case 'PUSH':
            return target.path;
          default:
            return null;
        }
      }

      default:
        return null;
    }
  } catch (error) {
    console.warn('open carousel error:', error);
  }
};

ui.labelColor = (label) => {
  if (/\*{3}/.test(label)) {
    return '#00aeef';
  } else if (/上牌$/.test(label)) {
    return '#00a99d';
  } else if (/限行$/.test(label)) {
    return '#f16270';
  } else if (/特价/.test(label)) {
    return '#f68e56';
  } else if (/第三方/.test(label)) {
    return colors.primary;
  }
  return 'gray';
};

ui.isRechargeActivityOngoing = async () => {
  try {
    const results = await Promise.all([
      api2.get('/appParams/findAppParamsByName', { name: 'recharge_order_give_balance_start' }),
      api2.get('/appParams/findAppParamsByName', { name: 'recharge_order_give_balance_end' }),
      api2.get('/appParams/findAppParamsByName', { name: 'recharge_order_give_point_start' }),
      api2.get('/appParams/findAppParamsByName', { name: 'recharge_order_give_point_end' }),
    ]);
    const balanceBeginTime = results[0].data.appParams.value;
    const balanceEndTime = results[1].data.appParams.value;
    const pointBeginTime = results[2].data.appParams.value;
    const pointEndTime = results[3].data.appParams.value;
    return {
      balance:
        !!balanceBeginTime &&
        dayjs().isAfter(balanceBeginTime) &&
        !!balanceEndTime &&
        dayjs().isBefore(balanceEndTime),
      point:
        !!pointBeginTime &&
        dayjs().isAfter(pointBeginTime) &&
        !!pointEndTime &&
        dayjs().isBefore(pointEndTime),
    };
  } catch (ex) {
    console.warn('ui.isRechargeActivityOngoing error:', ex);
    ui.toastError(ex);
    return {};
  }
};

export default ui;
