import React from 'react';
import Transition from 'react-transition-group/Transition';
import colors from 'src/utils/colors';

const HEADER_HEIGHT = 44;
const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
};

export default function MyHeader(props) {
  return (
    <div
      style={{
        width: '100vw',
        height: HEADER_HEIGHT,
      }}
    >
      <div
        style={{
          ...{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            height: HEADER_HEIGHT,
            backgroundColor: props.color || 'white',
            padding: '0 64px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          ...props.style,
        }}
      >
        {!!props.title && (
          <Transition in={props.showTitle} timeout={duration}>
            {(status) => (
              <div
                style={{
                  ...{
                    color: colors.textDarkgray,
                    fontSize: 18,
                    textAlign: 'center',
                  },
                  ...defaultStyle,
                  ...transitionStyles[status],
                }}
              >
                {props.title}
              </div>
            )}
          </Transition>
        )}

        {props.children}

        <div
          style={{
            display: 'flex',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            onClick={() => {
              if (props.onGoBack) {
                props.onGoBack();
                return;
              }
              window.history.back();
            }}
            className="flex-row justify-center align-center"
          >
            <img
              alt=""
              src={require('@/images/icon_chevron_left.svg')}
              style={{ marginLeft: 20, height: 15 }}
            />
          </div>
        </div>

        <div
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {props.right ? (
            props.right
          ) : (
            <div
              onClick={props.onRightClick}
              style={{
                height: HEADER_HEIGHT,
                padding: '0 10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  color: props.rightColor || colors.textGray,
                }}
              >
                {props.rightTitle}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

MyHeader.defaultProps = {
  hasBackButton: true,
  showTitle: true,
};
