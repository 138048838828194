import colors from './colors';

const theme = {};

theme.colors = {
  ...colors,

  cloudPrimary: '#4377F5',
};

function fontFactory(fontFamily, fontWeight) {
  return (fontSize, lineHeight) => ({
    font: `${fontWeight} ${fontSize}px/${lineHeight ?? fontSize + 2}px "${fontFamily}"`,
    fontStyle: 'normal',
  });
}

theme.fonts = {
  regular: fontFactory('PingFangSC-Regular', '400'),
  semibold: fontFactory('PingFangSC-Semibold', '500'),
  medium: fontFactory('PingFangSC-Medium', '600'),
  adobeHeitiStd: {
    regular: fontFactory('AdobeHeitiStd-Regular', 'normal'),
  },
};

theme.styles = {
  textOneline: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  inputPlaceholder: (color) => `
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      font: 400 14px/16px 'PingFangSC-Regular';
      color: ${color};
    }
    input:-moz-placeholder,
    textarea:-moz-placeholder {
      font: 400 14px/16px 'PingFangSC-Regular';
      color: ${color};
    }
    input::-moz-placeholder,
    textarea::-moz-placeholder {
      font: 400 14px/16px 'PingFangSC-Regular';
      color: ${color};
    }
    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
      font: 400 14px/16px 'PingFangSC-Regular';
      color: ${color};
    }
  `,

  overflowHidden: {
    overflow: 'hidden',
    // 下边三行是为了修复 ios16 及以下的 bug
    '-webkit-backface-visibility': 'hidden',
    '-webkit-transform': 'translate3d(0, 0, 0)',
    '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
  },
};

export default theme;
