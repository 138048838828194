import React from 'react';
import autoBind from 'react-autobind';
import { Link } from 'react-router-dom';
import { Modal } from 'semantic-ui-react';
import ui from 'src/utils/ui';

import api2 from '@/utils/api2';
import theme from '@/utils/theme';
import Card from '@/widgets/Card';

class RiskQueryPage extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.navprops = this.props.match.params || {};

    this.state = {
      codeModalVisible: false,
      name: '',
      cell: '',
      idCard: '',
      isChecked: false,
      submitting: false,
      totalCount: 0,
      useCount: 0,
      qrcode: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    try {
      const result = await api2.get('cloud-svr/back/riskQuery/queryRiskPlanDetail');
      const { res = {} } = result?.data;
      this.setState({ totalCount: res?.totalCount, useCount: res?.useCount });
    } catch (ex) {
      console.warn('DrivingSelfModel.loadVehicleSeriesData:', ex);
      ui.toastError(ex);
      if (ex.message === '未登录') {
        this.props.history.replace('/m/Login');
      }
    }
  }
  async loadCode() {
    this.setState({ loading: true });
    try {
      const response = await api2.post('cloud-svr/back/riskQuery/generateBrQueryQrcode');
      this.setState({ qrcode: response?.data?.qrcode });
    } catch (ex) {
      console.warn('DrivingSelfModel.loadVehicleSeriesData:', ex);
      ui.toastError(ex);
      if (ex.message === '未登录') {
        this.props.history.replace('/m/Login');
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const isCanSubmit = this.state.name && this.state.cell && this.state.idCard;
    const u = navigator.userAgent;
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    return (
      <div
        style={{
          margin: '0 auto',
          maxWidth: 600,
          minHeight: isIOS ? document.body.clientHeight : '100vh',
          background: 'rgba(68,120,245,0.05)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {this.renderHeader()}
        <div
          style={{
            position: 'relative',
            margin: '0 auto',
            maxWidth: 600,
          }}
        >
          <div>
            <img
              alt=""
              src={require('src/images/banner.jpg')}
              style={{ width: '100vw', maxWidth: 600, display: 'block' }}
            />
          </div>

          <div
            style={{
              position: 'absolute',
              top: ui.inMobile() ? 'calc((100vw * 153) / 414)' : 'calc((600px * 153) / 414)',
              left: 15,
              right: 15,
            }}
          >
            <Card
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: ui.inMobile() ? 0 : '0 auto',
                padding: '21px 15px 22px 15px',
                borderRadius: 8,
                width: ui.inMobile() ? 'auto' : 400,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{ ...theme.fonts.regular(13) }}
                >{`套餐已用: ${this.state.useCount}/${this.state.totalCount}`}</div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  onClick={() => this.toRiskQueryRecord()}
                >
                  <div style={{ ...theme.fonts.medium(13), color: '#4377F5' }}>风控查询记录</div>
                  <div
                    style={{
                      width: 6,
                      height: 10,
                      marginLeft: 6,
                      backgroundColor: '#4377F5',
                      '-webkit-mask': `url(${require('src/images/icon_chevron_right.svg')}) no-repeat center`,
                      mask: `url(${require('src/images/icon_chevron_right.svg')}) no-repeat center`,
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  background: 'rgba(68,120,245,0.05)',
                  borderRadius: 8,
                  height: 53,
                  marginTop: 12,
                }}
              >
                <div
                  style={{
                    ...theme.fonts.medium(14, 53),
                    width: 86,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  客户姓名
                </div>
                <input
                  placeholder="请输入真实姓名"
                  value={this.state.name}
                  onChange={(event) => this.setState({ name: event.target.value })}
                  style={{
                    marginRight: 10,
                    fontSize: this.state.name ? 15 : 14,
                    flex: 1,
                    outline: 'none',
                    height: 40,
                    border: 'none',
                    background: 'transparent',
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',

                  flexDirection: 'row',
                  alignItems: 'center',
                  background: 'rgba(68,120,245,0.05)',
                  borderRadius: 8,
                  height: 53,
                  marginTop: 13,
                }}
              >
                <div
                  style={{
                    ...theme.fonts.medium(14, 53),
                    width: 86,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  手机号码
                </div>
                <input
                  placeholder="请输入手机号码"
                  type="number"
                  value={this.state.cell}
                  onChange={(event) => this.setState({ cell: event.target.value })}
                  style={{
                    fontSize: this.state.cell ? 15 : 14,
                    marginRight: 10,
                    flex: 1,
                    outline: 'none',
                    height: 40,
                    border: 'none',
                    background: 'transparent',
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  background: 'rgba(68,120,245,0.05)',
                  borderRadius: 8,
                  height: 53,
                  marginTop: 13,
                }}
              >
                <div
                  style={{
                    ...theme.fonts.medium(14, 53),
                    width: 86,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  身份证号
                </div>
                <input
                  placeholder="请输入身份证号"
                  value={this.state.idCard}
                  onChange={(event) => this.setState({ idCard: event.target.value })}
                  style={{
                    fontSize: this.state.idCard ? 15 : 14,
                    marginRight: 10,
                    flex: 1,
                    outline: 'none',
                    height: 40,
                    border: 'none',
                    background: 'transparent',
                  }}
                />
              </div>
            </Card>
            <div
              style={{
                marginTop: 14,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div onClick={() => this.setState({ isChecked: !this.state.isChecked })}>
                <img
                  style={{ marginRight: 7, width: 11, height: 11 }}
                  alt=""
                  src={
                    this.state.isChecked
                      ? require('src/images/icon_checkbox_checked.svg')
                      : require('src/images/icon_checkbox.svg')
                  }
                />
              </div>

              <div style={{ ...theme.fonts.regular(11) }}>
                使用本服务意味您已阅读并同意签署《
                <Link to="/m/BrAuthorization" style={{ color: '#4377F5' }}>
                  信息查询授权书
                </Link>
                》
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: 180 }} />
        <div style={{ flex: 1 }} />

        <div
          style={{
            ...theme.fonts.regular(15),
            color: '#FFFFFF',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: 44,
            width: ui.inMobile() ? 'auto' : 400,
            borderRadius: 22,
            backgroundColor:
              !isCanSubmit || this.state.submitting ? 'rgba(68,120,245,0.2)' : '#4478F5',
            margin: ui.inMobile() ? '0px 42px 40px 42px' : '0 auto 40px auto',
          }}
          onClick={() => {
            if (!isCanSubmit || this.state.submitting) {
              return;
            }

            this.submit();
          }}
        >
          发起风控查询
        </div>

        <Modal
          open={this.state.codeModalVisible}
          onClose={() => {
            this.setState({ codeModalVisible: false });
          }}
          style={{
            borderRadius: 8,
            width: ui.inMobile() ? '70vw' : 400,
            height: ui.inMobile() ? '70vw' : 400,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <div style={{ ...theme.fonts.regular(16), marginLeft: 20 }}>长按保存二维码发给客户</div>
            <div
              style={{
                width: 40,
                height: 40,
                display: 'flex',
                justifyContent: 'center',

                alignItems: 'center',
              }}
              onClick={() => {
                this.setState({ codeModalVisible: false });
              }}
            >
              <img
                src={require('src/images/icon_close_darkgray@2x.png')}
                style={{ width: 10, height: 10 }}
              />
            </div>
          </div>
          <div
            className="flex-column"
            style={{
              borderRadius: 8,
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 20,
            }}
          >
            <img
              src={this.state.qrcode}
              style={{
                width: ui.inMobile() ? '42vw' : 200,
                height: ui.inMobile() ? '42vw' : 200,
                marginBottom: 20,
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }

  toRiskQueryRecord() {
    this.props.history.push('/m/RiskQueryRecord');
  }

  async submit() {
    try {
      if (this.state.submitting) {
        return;
      }

      if (!this.state.isChecked) {
        ui.toastError('请勾选信息查询授权书');
        return;
      }

      this.setState({ submitting: true });
      const result = await api2.post('cloud-svr/back/riskQuery/riskQuery', {
        name: this.state.name,
        cell: this.state.cell,
        idCard: this.state.idCard,
      });
      const riskQueryRecordId = result.data?.riskQueryRecordId;
      if (riskQueryRecordId) {
        this.props.history.push(`/m/RiskQueryReport/${riskQueryRecordId}`);
      }
    } catch (error) {
      console.log('apply vehicle riskQuery error:', error);
      ui.toastError(error);
      if (error.message === '未登录') {
        this.props.history.replace('/m/Login');
      }
    } finally {
      this.setState({ submitting: false });
    }
  }

  renderHeader() {
    const HEADER_HEIGHT = 44;

    return (
      <div
        style={{
          width: '100vw',
          height: HEADER_HEIGHT,
        }}
      >
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            height: HEADER_HEIGHT,
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div
              onClick={() => {
                this.props.history.goBack();
              }}
              className="flex-row justify-center align-center"
            >
              <img
                style={{ marginLeft: 20, height: 15 }}
                alt=""
                src={require('src/images/icon_chevron_left.svg')}
              />
            </div>
          </div>
          <div
            style={{
              ...theme.fonts.medium(15),
              textAlign: 'center',
            }}
          >
            风控查询
          </div>
          <div
            style={{
              position: 'absolute',
              right: 16,
              top: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div
              onClick={() => {
                this.setState({ codeModalVisible: true });
                this.loadCode();
              }}
              className="flex-row justify-center align-center"
              style={{ ...theme.fonts.medium(13), color: '#4377F5' }}
            >
              发给客户填写
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RiskQueryPage;
