/** @deprecated by theme.colors */
export default {
  primary: '#f36728',

  bgLightgray: '#f5f5f5',
  splitter: '#e9e9e9',

  textBlack: '#1a1a1a',
  textDarkgray: '#333333',
  textGray: '#999999',
  textLightgray: '#dddddd',
  textYellow: '#e5b226',

  couponRed: '#f16270',
  shortRenting: '#d85c5f',
  businessYellow: '#f3cb4e',
  businessBrown: '#6F5D69',

  newPurple: '#6E5C68',
  newBlack: '#2C2C2C',
};
